import { useField } from 'formik';
import { useRef } from 'react';
import { fieldName } from '../../../../../../utils';
import Button from '../../../../../common/base/Button';
import { IconName } from '../../../../../common/base/Icon';
import Text from '../../../../../common/base/Text';
import { Div } from '../../../../../common/helpers/StyledUtils';
import TextInput from '../../../../../common/Form/Fields/TextInput';
import { StyledCardSection } from '../../../../../common/base/Card';

const Variables: React.FC<{ name: string }> = ({ name }) => {
  const [{ value: env }, , { setValue: setEnv }] = useField(name);
  const renderKeyRef = useRef(new Date().getTime());
  const curriedRemoveEntry = (index: number) => () => {
    const new_env = [...env];
    new_env.splice(index, 1);
    setEnv(new_env);
    renderKeyRef.current = new Date().getTime();
  };

  const entries = [...(env || []), ['', '']];

  return (
    <>
      <StyledCardSection p={{ x: 4, y: 1 }} muted>
        <Text semi size="xs" muted>
          Environment Variables
        </Text>
      </StyledCardSection>
      <StyledCardSection p={4}>
        <Text as="p" muted m={{ b: 3 }}>
          All variables are AES encrypted. Refer to the value using{' '}
          <code>process.env.VARIABLE_KEY</code>.
        </Text>
        {entries.map((_, i) => (
          <div key={`${renderKeyRef.current}-${i}`}>
            <Div flex={{ align: 'center', gap: 4 }} m={{ t: i === 0 ? 0 : 2 }}>
              <TextInput w={100} m={0} name={fieldName(`[${i}][0]`, name)} placeholder={'Key'} />
              <TextInput w={100} m={0} name={fieldName(`[${i}][1]`, name)} placeholder={'Value'} />
              <Button
                neutral
                invisible
                icon={'delete' as IconName}
                disabled={i === entries.length - 1}
                onClick={curriedRemoveEntry(i)}
              />
            </Div>
          </div>
        ))}
      </StyledCardSection>
    </>
  );
};
export default Variables;
