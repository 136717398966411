import { FormEvent } from 'react';

import MultiSelectInput from '../../Form/Fields/MultiSelectInput';
import { Div } from '../../helpers/StyledUtils';

const MultiSelectFilter: React.FC<{
  label: string;
  hide_label?: boolean;
  name: string;
  options?: { value: string; label: string }[];
  delete_button?: JSX.Element;
  onSubmit: (e?: FormEvent<HTMLFormElement>) => void;
}> = ({ delete_button, options, onSubmit, label, name, hide_label }) => {
  return (
    <Div flex={{ align: 'center' }} m={0}>
      <MultiSelectInput
        onClose={onSubmit}
        onRemove={onSubmit}
        initial_options={options || []}
        option_title={!hide_label ? label : undefined}
        name={name}
        m={0}
      />
      {delete_button && delete_button}
    </Div>
  );
};

export default MultiSelectFilter;
