import { useContext } from 'react';
import { useTheme } from 'styled-components';

import { IssueType } from '../../../../../../../typings/Issue.interface';
import { ISSUE_STATUS_CONFIGS, issue_type_configs } from '../../../../utils/issues';
import Container from '../../../common/base/Container';
import Divider from '../../../common/base/Divider';
import Tabs from '../../../common/base/Tabs';
import DropdownMenu from '../../../common/DropdownMenu';
import EmptyState from '../../../common/EmptyState';
import { Div } from '../../../common/helpers/StyledUtils';
import useLocalStorage from '../../../hooks/useLocalStorage';
import useSearchQuery from '../../../hooks/useSearchQuery';
import { DashboardContext } from '../DashboardContext';
import { PageNav, SecondaryPageNav, StyledViewContent, StyledViewWrapper } from '../StyledView';
import IssuesList from './IssueList';
import useSWR from 'swr';
import APIMethodKeys from '../../../../client/APIMethodKeys';
import { GlobalContext } from '../../../contexts/GlobalContext';
import Dropdown from '../../../common/Dropdown';
import { Form, Formik } from 'formik';
import Text from '../../../common/base/Text';
import CheckboxInput from '../../../common/Form/Fields/CheckboxInput';
import { fieldName } from '../../../../utils';
import Button, { ButtonGroup } from '../../../common/base/Button';

const sort_options = {
  first_seen_at: 'First Seen',
  last_seen_at: 'Last Seen',
} as const;

const sort_keys = Object.keys(sort_options) as (keyof typeof sort_options)[];

const IssuesView: React.FC = () => {
  const theme = useTheme();
  const { HookdeckAPI } = useContext(GlobalContext);
  const { has_connection, has_created_connections } = useContext(DashboardContext);

  const [sort_by, setSortBy] = useLocalStorage<keyof typeof sort_options>(
    'pref:issues_sort_by',
    sort_keys[0],
  );
  const { query, updateSearchQuery } = useSearchQuery<{
    type?: IssueType[];
    tab?: keyof typeof ISSUE_STATUS_CONFIGS;
  }>();

  const { data: open_count, mutate: mutateOpenedCount } = useSWR(
    APIMethodKeys.issues.count({ status: 'OPENED', type: query.type || undefined }),
    () => HookdeckAPI.issues.count({ status: 'OPENED', type: query.type || undefined }),
  );
  const { data: ack_count, mutate: mutateAcknowledgedCount } = useSWR(
    APIMethodKeys.issues.count({ status: 'ACKNOWLEDGED', type: query.type || undefined }),
    () => HookdeckAPI.issues.count({ status: 'ACKNOWLEDGED', type: query.type || undefined }),
  );
  const { data: resoled_count, mutate: mutateResolvedCount } = useSWR(
    APIMethodKeys.issues.count({ status: 'RESOLVED', type: query.type || undefined }),
    () => HookdeckAPI.issues.count({ status: 'RESOLVED', type: query.type || undefined }),
  );
  const { data: ignored_count, mutate: mutateIgnoredCount } = useSWR(
    APIMethodKeys.issues.count({ status: 'IGNORED' }),
    () => HookdeckAPI.issues.count({ status: 'IGNORED', type: query.type || undefined }),
  );

  const current_status = query.tab && ISSUE_STATUS_CONFIGS[query.tab] ? query.tab : 'OPENED';
  const onIssueAction = async (action: string) => {
    if (action === 'acknowledge') {
      mutateAcknowledgedCount();
    } else if (action === 'resolve') {
      mutateResolvedCount();
    } else if (action === 'ignore') {
      mutateIgnoredCount();
    } else if (action === 'reopen') {
      mutateOpenedCount();
    }

    if (current_status === 'OPENED') {
      mutateOpenedCount();
    } else if (current_status === 'ACKNOWLEDGED') {
      mutateAcknowledgedCount();
    } else if (current_status === 'RESOLVED') {
      mutateResolvedCount();
    } else if (current_status === 'IGNORED') {
      mutateIgnoredCount();
    }
  };

  const counts = {
    OPENED: open_count?.count,
    ACKNOWLEDGED: ack_count?.count,
    RESOLVED: resoled_count?.count,
    IGNORED: ignored_count?.count,
  };

  return (
    <StyledViewWrapper>
      <StyledViewContent>
        <PageNav breadcrumb={[{ icon: 'issues', title: 'Issues' }]}>
          <ButtonGroup>
            <DropdownMenu
              options={sort_keys.map((key) => ({
                onClick: () => setSortBy(key),
                label: sort_options[key],
              }))}
              p={0}
              parent_width
              placement="bottom-end"
              label={sort_options[sort_by]}
              icon="sort"
              outline
            />
            <Dropdown
              parent_width={{ min: 272 }}
              placement="bottom-end"
              icon="filter"
              label="Filter"
              badge={
                query.type && query.type.length > 0
                  ? { label: String(query.type.length) }
                  : undefined
              }
              outline>
              {(toggle) => (
                <Formik
                  initialValues={{
                    type: query.type
                      ? query.type.reduce((obj, key) => ({ ...obj, [key]: true }), {})
                      : {},
                  }}
                  onSubmit={(values) => {
                    const type = Object.entries(values.type)
                      .filter(([, value]) => value)
                      .map(([key]) => key);
                    updateSearchQuery({
                      type: type.length > 0 ? type : undefined,
                    });
                    toggle(false);
                  }}>
                  <Form>
                    <Div p={{ x: 3, y: 3, b: 0.5 }}>
                      <Text bold size="xs" muted>
                        Issue Type
                      </Text>
                      {Object.entries(issue_type_configs).map(([key, config]) => (
                        <CheckboxInput
                          m={{ y: 2.5 }}
                          key={key}
                          label={config.label}
                          icon={config.icon}
                          name={fieldName(key, 'type')}
                        />
                      ))}
                    </Div>
                    <Divider />
                    <Div p={3} flex={{ justify: 'space-between', gap: 2 }}>
                      <Button submit>Apply</Button>
                    </Div>
                  </Form>
                </Formik>
              )}
            </Dropdown>
            {query.type && query.type.length > 0 && (
              <Button
                neutral
                onClick={() => {
                  updateSearchQuery({
                    type: undefined,
                  });
                }}
                icon="cancel">
                Clear Filters
              </Button>
            )}
          </ButtonGroup>
        </PageNav>
        {has_connection ? (
          <>
            <SecondaryPageNav>
              <Div p={{ t: 3 }}>
                <Tabs
                  active_tab={current_status}
                  onTabSelected={(tab) => updateSearchQuery({ tab })}
                  border={false}
                  tabs={Object.entries(ISSUE_STATUS_CONFIGS).map(([key, status_config]) => ({
                    key,
                    label: status_config.label,
                    icon: status_config.icon,
                    badge_theme: status_config.badge_theme,
                    badge_label: counts[key] > 0 ? counts[key] : undefined,
                  }))}
                />
              </Div>
            </SecondaryPageNav>
            <Container xlarge h={100} flex>
              <IssuesList
                types={query.type && query.type.length > 0 ? query.type : undefined}
                sort_by={sort_by}
                status={current_status}
                onAction={onIssueAction}
              />
            </Container>
          </>
        ) : (
          <EmptyState
            title="Monitor and resolve issues"
            description="Hookdeck’s issue tracking features enable you to monitor and resolve event-related errors in real time. In order to experience the benefits of Hookdeck’s issue features, create a connection."
            asset={`/images/empty/issues-${theme.mode}.svg`}
            cta={{
              label: 'Create connection',
              icon: 'add_circle',
              to: has_created_connections ? '/connections/new' : '/create-first-connection',
            }}
          />
        )}
      </StyledViewContent>
    </StyledViewWrapper>
  );
};

export default IssuesView;
