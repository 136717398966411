import { Form, Formik } from 'formik';
import { useContext } from 'react';
import { useHistory } from 'react-router';
import { Route } from 'react-router-dom';
import useSWR from 'swr';

import { IssueType } from '../../../../../../../../typings/Issue.interface';
import IssueTrigger from '../../../../../../../../typings/IssueTrigger.interface';
import APIMethodKeys from '../../../../../client/APIMethodKeys';
import Button from '../../../../common/base/Button';
import { StyledCard, StyledCardSection } from '../../../../common/base/Card';
import Divider from '../../../../common/base/Divider';
import Loading from '../../../../common/base/Loading';
import Tabs from '../../../../common/base/Tabs';
import Text from '../../../../common/base/Text';
import Dropdown from '../../../../common/Dropdown';
import CheckboxInput from '../../../../common/Form/Fields/CheckboxInput';
import { Div } from '../../../../common/helpers/StyledUtils';
import Modal from '../../../../common/Modal';
import { useToasts } from '../../../../common/Toast';
import { GlobalContext } from '../../../../contexts/GlobalContext';
import { TeamPermission } from '../../../../contexts/TeamPermissionContext';
import { IssueTriggersRenderData } from './';
import issue_trigger_forms from './Forms/issue_triggers_forms';
import IssueTriggerCard from './IssueTriggerCard';

const IssueTriggersGroup: React.FC<{
  type: IssueType;
  status?: ('enabled' | 'disabled')[];
  issue_triggers: IssueTrigger[];
  render_data: IssueTriggersRenderData;
  onIssueTriggerCreated: (issue_trigger: IssueTrigger) => void;
  onStatusChange?: (status: ('enabled' | 'disabled')[] | undefined) => void;
}> = ({ type, status, issue_triggers, render_data, onIssueTriggerCreated, onStatusChange }) => {
  const history = useHistory();
  const { HookdeckAPI } = useContext(GlobalContext);
  const { addToast } = useToasts();
  const Fields = issue_trigger_forms[type].Fields;

  const { data: integrations } = useSWR(APIMethodKeys.team_integrations.list(), () =>
    HookdeckAPI.team_integrations.list(),
  );

  if (integrations === undefined) {
    return (
      <Div flex={{ justify: 'center' }} p={8}>
        <Loading />
      </Div>
    );
  }

  const onClose = () => {
    history.push({
      pathname: '/issue-triggers',
      search: location.search,
      state: { scroll: false },
    });
  };

  return (
    <>
      <StyledCard>
        <StyledCardSection muted p={{ y: 3, x: 4 }}>
          <Dropdown w={{ px: 184 }} icon="filter_list" label="Trigger status" outline>
            {(toggle) => (
              <Formik
                initialValues={{
                  status: status ? status.reduce((obj, key) => ({ ...obj, [key]: true }), {}) : {},
                }}
                onSubmit={(values) => {
                  const status = Object.entries(values.status)
                    .filter(([, value]) => value)
                    .map(([key]) => key) as ('enabled' | 'disabled')[];
                  onStatusChange?.(status.length > 0 ? status : undefined);
                  toggle(false);
                }}>
                <Form>
                  <Div p={{ x: 3 }}>
                    <CheckboxInput
                      m={{ y: 2.5 }}
                      icon="notification"
                      label="Enabled"
                      name="status.enabled"
                    />
                    <CheckboxInput
                      m={{ y: 2.5 }}
                      icon="notification_off"
                      label="Disabled"
                      name="status.disabled"
                    />
                  </Div>
                  <Divider />
                  <Div p={3} flex={{ justify: 'space-between', gap: 2 }}>
                    <Button submit>Apply</Button>
                  </Div>
                </Form>
              </Formik>
            )}
          </Dropdown>
        </StyledCardSection>
        <>
          {issue_triggers.map((issue_trigger) => (
            <IssueTriggerCard
              key={issue_trigger.id}
              issue_trigger={issue_trigger}
              render_data={render_data}
            />
          ))}
          {issue_triggers.length === 0 && (
            <StyledCardSection p={{ y: 4 }}>
              <Text muted center>
                No {type} issue triggers...
              </Text>
            </StyledCardSection>
          )}
        </>
      </StyledCard>
      <Route
        path={`/issue-triggers/create/${type}`}
        render={() => (
          <TeamPermission role="member">
            <Formik
              key={type}
              initialValues={issue_trigger_forms[type].getInitialValues(integrations.models)}
              validate={issue_trigger_forms[type].validate}
              onSubmit={(values) => {
                return HookdeckAPI.issue_triggers
                  .create(issue_trigger_forms[type].formatValuesToAPIInput(values as any))
                  .then((issue_trigger) => {
                    addToast('success', 'Created issue trigger');
                    onIssueTriggerCreated(issue_trigger);
                    history.push({
                      pathname: `/issue-triggers/${type}`,
                      search: location.search,
                      state: { scroll: false },
                    });
                    return issue_trigger;
                  })
                  .catch(() => {
                    addToast('error', 'Error creating issue trigger');
                    return null;
                  });
              }}>
              {({ isSubmitting, isValid }) => (
                <Form>
                  <Modal
                    size="medium"
                    onCancel={onClose}
                    onClose={onClose}
                    cancel_label="Cancel"
                    submit_disabled={isSubmitting || !isValid}
                    is_submitting={isSubmitting}
                    submit_label="Create trigger"
                    p={0}>
                    <Div>
                      <Text bold size="l" p={{ x: 4, y: 4 }}>
                        Create issue trigger
                      </Text>
                      <Tabs
                        tabs={[
                          {
                            label: 'Delivery',
                            key: 'delivery',
                          },
                          {
                            label: 'Transformation',
                            key: 'transformation',
                          },
                          {
                            label: 'Backpressure',
                            key: 'backpressure',
                          },
                        ]}
                        onTabSelected={(tab) => {
                          history.push(`/issue-triggers/create/${tab}`);
                        }}
                        compact
                        tab_radio
                        border={false}
                        active_tab={type}
                        p={{ x: 4 }}
                      />
                      <Divider />
                      <Div p={4}>
                        <Fields integrations={integrations.models} muted={true} />
                      </Div>
                    </Div>
                  </Modal>
                </Form>
              )}
            </Formik>
          </TeamPermission>
        )}
      />
    </>
  );
};

export default IssueTriggersGroup;
