import { MetricName } from '../../../common/metrics/metric-definitions';
import { MetricDisplayType } from '../../../common/metrics/useMetric';

interface Metric {
  type: MetricDisplayType;
  name: MetricName;
  footer_text?: string;
  footer_link?: {
    label: string;
    to: string;
  };
}

export interface Chart {
  key: string;
  label: string;
  metric: Metric;
}

export interface MetricSection {
  tab_label: string;
  icon?: string;
  title: string;
  description?: string;
  metrics?: Metric[];
  chart?: Chart;
}

export interface MetricCategory {
  label: string;

  // Describe the resource use as a dimention
  dimention: {
    label: string;
    key: string;
    api_root: 'sources' | 'webhooks' | 'destinations';
  };

  metrics_preview: Metric[];
  metrics: {
    overview: MetricSection;
    current_data?: MetricSection;
    charts: MetricSection[];
  };
}

export const metric_categories: Record<string, MetricCategory> = {
  requests: {
    label: 'Requests',
    dimention: {
      label: 'Sources',
      key: 'source_id',
      api_root: 'sources',
    },
    metrics_preview: [
      {
        type: 'card',
        name: 'requests_count',
      },
      {
        type: 'card',
        name: 'requests_accepted_count',
      },
      {
        type: 'card',
        name: 'requests_rejected_count',
      },
      {
        type: 'card',
        name: 'requests_rate',
      },
      {
        type: 'chart:bar',
        name: 'requests_count',
      },
    ],
    metrics: {
      overview: {
        tab_label: 'Overview',
        title: 'Requests Overview',
        metrics: [
          {
            type: 'card',
            name: 'requests_count',
          },
          {
            type: 'card',
            name: 'requests_accepted_count',
          },
          {
            type: 'card',
            name: 'requests_rejected_count',
          },
          {
            type: 'card',
            name: 'requests_rate',
          },
          {
            type: 'card',
            name: 'requests_average_event',
          },
          {
            type: 'card',
            name: 'requests_average_ignored',
          },
        ],
      },
      charts: [
        {
          title: 'Total Requests',
          description: 'Count',
          tab_label: 'Total',
          chart: {
            key: 'count',
            label: 'Count',
            metric: {
              type: 'chart:bar',
              name: 'requests_count',
            },
          },
        },
        {
          title: 'Total Requests',
          description: 'Rate',
          tab_label: 'Total',
          chart: {
            key: 'rate',
            label: 'Rate',
            metric: {
              type: 'chart:line',
              name: 'requests_rate',
            },
          },
        },
        {
          title: 'Accepted Requests',
          description: 'Count',
          tab_label: 'Accepted',
          chart: {
            key: 'count',
            label: 'Count',
            metric: {
              type: 'chart:bar',
              name: 'requests_accepted_count',
            },
          },
        },
        {
          title: 'Rejected Requests',
          description: 'Count',
          tab_label: 'Rejected',
          chart: {
            key: 'count',
            label: 'Count',
            metric: {
              type: 'chart:bar',
              name: 'requests_rejected_count',
            },
          },
        },
        {
          title: 'Avg. Events',
          description: 'Count',
          tab_label: 'Events',
          chart: {
            key: 'count',
            label: 'Count',
            metric: {
              type: 'chart:line',
              name: 'requests_average_event',
            },
          },
        },
        {
          title: 'Avg. Ignored Events',
          description: 'Count',
          tab_label: 'Ignored',
          chart: {
            key: 'count',
            label: 'Count',
            metric: {
              type: 'chart:line',
              name: 'requests_average_ignored',
            },
          },
        },
      ],
    },
  },
  events: {
    label: 'Events',
    dimention: {
      label: 'Connections',
      key: 'webhook_id',
      api_root: 'webhooks',
    },
    metrics_preview: [
      {
        type: 'card',
        name: 'events_count',
      },
      {
        type: 'card',
        name: 'events_successful_count',
      },
      {
        type: 'card',
        name: 'events_failed_count',
      },
      {
        type: 'card',
        name: 'events_average_attempts',
      },
      {
        type: 'chart:bar',
        name: 'events_count',
      },
    ],
    metrics: {
      overview: {
        tab_label: 'Overview',
        title: 'Events Overview',
        metrics: [
          {
            type: 'card',
            name: 'events_count',
          },
          {
            type: 'card',
            name: 'events_successful_count',
          },
          {
            type: 'card',
            name: 'events_failed_count',
          },
          {
            type: 'card',
            name: 'events_rate',
          },
          {
            type: 'card',
            name: 'events_average_attempts',
          },
          {
            type: 'card',
            name: 'events_error_rate',
          },
        ],
      },
      current_data: {
        tab_label: 'Current',
        title: 'Current Data',
        description:
          'Metric is not available on a historical basis and instead represents current values.',
        metrics: [
          {
            type: 'table',
            name: 'events_pending_count',
          },
          {
            type: 'table',
            name: 'events_with_scheduled_retry_count',
          },
          {
            type: 'table',
            name: 'events_paused_count',
          },
          {
            type: 'table',
            name: 'events_delayed_count',
          },
        ],
      },
      charts: [
        {
          title: 'Total Events',
          description: 'Count',
          tab_label: 'Total',
          chart: {
            key: 'count',
            label: 'Count',
            metric: {
              type: 'chart:bar',
              name: 'events_count',
            },
          },
        },
        {
          title: 'Total Events',
          description: 'Rate',
          tab_label: 'Total',
          chart: {
            key: 'rate',
            label: 'Rate',
            metric: {
              type: 'chart:line',
              name: 'events_rate',
            },
          },
        },
        {
          title: 'Successful Events',
          description: 'Count',
          tab_label: 'Successful',
          chart: {
            key: 'count',
            label: 'Count',
            metric: {
              type: 'chart:bar',
              name: 'events_successful_count',
            },
          },
        },
        {
          title: 'Failed Events',
          description: 'Count',
          tab_label: 'Failed',
          chart: {
            key: 'count',
            label: 'Count',
            metric: {
              type: 'chart:bar',
              name: 'events_failed_count',
            },
          },
        },
        {
          title: 'Error Rate',
          description: 'Count',
          tab_label: 'Error Rate',
          chart: {
            key: 'count',
            label: 'Count',
            metric: {
              type: 'chart:line',
              name: 'events_error_rate',
            },
          },
        },
        {
          title: 'Average attempts per event',
          description: 'Count',
          tab_label: 'Avg Attempts',
          chart: {
            key: 'count',
            label: 'Count',
            metric: {
              type: 'chart:line',
              name: 'events_average_attempts',
            },
          },
        },
      ],
    },
  },
  attempts: {
    label: 'Attempts',
    dimention: {
      label: 'Destinations',
      key: 'destination_id',
      api_root: 'destinations',
    },
    metrics_preview: [
      {
        type: 'card',
        name: 'attempts_count',
      },
      {
        type: 'card',
        name: 'attempts_delivered_count',
      },
      {
        type: 'card',
        name: 'attempts_failed_count',
      },
      {
        type: 'card',
        name: 'attempts_average_response_time',
      },
      {
        type: 'chart:bar',
        name: 'attempts_count',
      },
    ],
    metrics: {
      overview: {
        tab_label: 'Overview',
        title: 'Attempts Overview',
        metrics: [
          {
            type: 'card',
            name: 'attempts_count',
          },
          {
            type: 'card',
            name: 'attempts_delivered_count',
          },
          {
            type: 'card',
            name: 'attempts_failed_count',
          },
          {
            type: 'card',
            name: 'attempts_average_response_time',
          },
          {
            type: 'card',
            name: 'attempts_rate',
          },
          {
            type: 'card',
            name: 'attempts_error_rate',
          },
        ],
      },
      charts: [
        {
          title: 'Total Attempts',
          description: 'Count',
          tab_label: 'Total',
          chart: {
            key: 'count',
            label: 'Count',
            metric: {
              type: 'chart:bar',
              name: 'attempts_count',
            },
          },
        },
        {
          title: 'Total Attempts',
          description: 'Rate',
          tab_label: 'Total',
          chart: {
            key: 'rate',
            label: 'Rate',
            metric: {
              type: 'chart:line',
              name: 'attempts_rate',
            },
          },
        },
        {
          title: 'Delivered Attempts',
          description: 'Count',
          tab_label: 'Delivered',
          chart: {
            key: 'count',
            label: 'Count',
            metric: {
              type: 'chart:bar',
              name: 'attempts_delivered_count',
            },
          },
        },
        {
          title: 'Delivered Attempts',
          description: 'Rate',
          tab_label: 'Delivered',
          chart: {
            key: 'rate',
            label: 'Rate',
            metric: {
              type: 'chart:line',
              name: 'attempts_delivered_rate',
            },
          },
        },
        {
          title: 'Pending Attempts',
          description: 'Max',
          tab_label: 'Pending',
          chart: {
            key: 'max',
            label: 'Max',
            metric: {
              type: 'chart:line',
              name: 'attempts_pending_count',
            },
          },
        },
        {
          title: 'Oldest Pending Attempts',
          description: 'Max',
          tab_label: 'Oldest',
          chart: {
            key: 'max',
            label: 'Max',
            metric: {
              type: 'chart:line',
              name: 'attempts_oldest_pending',
            },
          },
        },
        {
          title: 'Response Latency',
          description: 'Avg.',
          tab_label: 'Latency',
          chart: {
            key: 'average',
            label: 'Avg.',
            metric: {
              type: 'chart:line',
              name: 'attempts_average_response_time',
            },
          },
        },
        {
          title: 'Response Latency',
          description: '95th',
          tab_label: 'Latency',
          chart: {
            key: '95th',
            label: '95th',
            metric: {
              type: 'chart:line',
              name: 'attempts_95_response_time',
            },
          },
        },
        {
          title: 'Response Latency',
          description: '99th',
          tab_label: 'Latency',
          chart: {
            key: '99th',
            label: '99th',
            metric: {
              type: 'chart:line',
              name: 'attempts_99_response_time',
            },
          },
        },
        {
          title: 'Response Latency',
          description: 'Max',
          tab_label: 'Latency',
          chart: {
            key: 'maximum',
            label: 'Max',
            metric: {
              type: 'chart:line',
              name: 'attempts_max_response_time',
            },
          },
        },
        {
          title: 'Attempt Error Rate',
          description: 'Rate',
          tab_label: 'Error Rate',
          chart: {
            key: 'rate',
            label: 'Rate',
            metric: {
              type: 'chart:line',
              name: 'attempts_error_rate',
            },
          },
        },
      ],
    },
  },
};
