import { format } from 'date-fns';
import { memo, useContext } from 'react';
import NumberFormat from 'react-number-format';
import useSWR from 'swr';

import { Grid, GridUnit } from '@hookdeck/theme';

import APIMethodKeys from '../../../../../../client/APIMethodKeys';
import { OrbPlanPrice, OrbSubscription } from '../../../../../../typings/Orb.interface';
import Button from '../../../../../common/base/Button';
import { StyledCard, StyledCardSection } from '../../../../../common/base/Card';
import Icon from '../../../../../common/base/Icon';
import Loading from '../../../../../common/base/Loading';
import Text from '../../../../../common/base/Text';
import BarChart from '../../../../../common/Chart/BarChart';
import { ChartDatapoint, ChartDataSet } from '../../../../../common/Chart/Chart';
import { generateRandomChartColor } from '../../../../../common/Chart/LegendColor';
import { Div } from '../../../../../common/helpers/StyledUtils';
import { GlobalContext } from '../../../../../contexts/GlobalContext';
import { DashboardContext } from '../../../DashboardContext';

const EventsUsage: React.FC<{ subscription_details: OrbSubscription }> = ({
  subscription_details,
}) => {
  const { HookdeckAPI } = useContext(GlobalContext);
  const { subscription } = useContext(DashboardContext);
  const { data: usage_data } = useSWR(APIMethodKeys.billing.getUsage({ granularity: 'day' }), () =>
    HookdeckAPI.billing.getUsage({ granularity: 'day' }),
  );

  const meters: Record<string, OrbPlanPrice> = subscription_details?.plan.prices.reduce(
    (acc, price) => {
      const is_same_plan_phase =
        !subscription_details?.plan.active_plan_phase_order ||
        subscription_details.plan.active_plan_phase_order === price.plan_phase_order;
      if (price.price_type === 'usage_price' && is_same_plan_phase) {
        return { ...acc, [price.id]: price };
      }
      return acc;
    },
    {},
  );

  type DailyUsage = {
    timeframe_start: string;
    timeframe_end: string;
    quantity: number;
  }[];
  const meters_usage: Array<{
    display_name: string;
    included: number;
    overage: number;
    batch_price: number;
    base_ratio: number;
    overage_ratio: number;
    daily_usage: DailyUsage;
    total_usage: number;
    batch_size: number;
  }> = [];
  if (usage_data && subscription_details) {
    for (const [, price] of Object.entries(meters)) {
      const included = Number(
        price.package_with_allocation_config?.allocation || subscription?.max_requests || 0,
      );

      const package_config = price.package_with_allocation_config || price.package_config;

      const batch_size = Number(package_config?.package_size || 0);
      let batch_price = Number(package_config?.package_amount || 0);

      if (price.discount?.percentage_discount) {
        batch_price = batch_price * price.discount?.percentage_discount;
      }

      const daily_usage: DailyUsage =
        usage_data.data.find((entry) => entry.billable_metric.id === price.billable_metric.id)
          ?.usage || [];
      const total_usage = daily_usage.reduce((acc, curr) => acc + curr.quantity, 0);

      const overrage_batch_count =
        batch_size !== 0 ? Math.ceil((total_usage - included) / batch_size) : 0;
      const overage_ratio = total_usage <= included ? 0 : 1 - included / total_usage;
      const base_ratio = overage_ratio === 0 ? total_usage / included : 1 - overage_ratio;
      const overage = overrage_batch_count > 0 ? (overrage_batch_count * batch_price) / 100 : 0;

      meters_usage.push({
        display_name: price.name,
        included,
        overage,
        batch_price,
        base_ratio,
        overage_ratio,
        daily_usage,
        total_usage,
        batch_size,
      });
    }
  }

  const start_date = meters_usage[0]?.daily_usage[0]?.timeframe_start;

  const chart_props = {
    title: 'Usage',
    subtitle: '/ Periodic',
    legend_placeholder: 'Usage',
    time_label: start_date && (
      <Button neutral disabled>
        {format(new Date(start_date), 'MMM d, yyyy')} <Icon left right icon="arrow_forward" />{' '}
        {format(new Date(), 'MMM d, yyyy')}
      </Button>
    ),
    datasets: meters_usage.map((meter, i) => ({
      key: meter.display_name,
      label: meter.display_name,
      data: meter.daily_usage.map((usage) => ({
        x: format(new Date(usage.timeframe_start), 'MM/dd/yyyy'),
        y: usage.quantity,
      })) as ChartDatapoint[],
      total: meter.total_usage,
      average: meter.total_usage / meter.daily_usage.length,
      metric_type: 'count',
      hex_color: generateRandomChartColor(meter.display_name),
      getDataLabel: (value: number) => (
        <NumberFormat
          renderText={(v) => v}
          displayType="text"
          value={value}
          thousandSeparator={','}
        />
      ),
    })) as ChartDataSet[],
    loading: false,
    height: 316,
  };

  return !meters_usage ? (
    <Loading />
  ) : (
    <Div m={{ b: 14 }}>
      <Text bold size="l" as="h2" m={0}>
        Metered usage
      </Text>
      <Text muted as="p" m={{ b: 4 }}>
        Updated hourly
      </Text>
      <StyledCard>
        <BarChart {...chart_props} />
      </StyledCard>
      <Div m={{ t: 6 }}>
        <StyledCard>
          <StyledCardSection muted>
            <Grid>
              <GridUnit size={1 / 4}>
                <Text muted semi size="xs" p={{ x: 4, y: 1 }} style={{ lineHeight: '20px' }}>
                  Type
                </Text>
              </GridUnit>
              <GridUnit size={1 / 4}>
                <Text muted semi size="xs" p={{ x: 4, y: 1 }} style={{ lineHeight: '20px' }}>
                  Usage
                </Text>
              </GridUnit>
              <GridUnit size={1 / 4}>
                <Text muted semi size="xs" p={{ x: 4, y: 1 }} style={{ lineHeight: '20px' }}>
                  Included with plan
                </Text>
              </GridUnit>
              <GridUnit size={1 / 4}>
                <Div flex={{ justify: 'flex-end' }}>
                  <Text muted semi size="xs" p={{ x: 4, y: 1 }} style={{ lineHeight: '20px' }}>
                    Cost
                  </Text>
                </Div>
              </GridUnit>
            </Grid>
          </StyledCardSection>
          {meters_usage
            .filter((meter) => !!meter)
            .map((meter) => (
              <StyledCardSection key={meter.display_name}>
                <Grid>
                  <GridUnit size={1 / 4}>
                    <Text size="s" p={{ x: 4, y: 3 }}>
                      {meter.display_name}
                    </Text>
                  </GridUnit>
                  <GridUnit size={1 / 4}>
                    <Text size="s" p={{ x: 4, y: 3 }}>
                      <NumberFormat
                        renderText={(v) => v}
                        displayType="text"
                        value={meter.total_usage}
                        thousandSeparator={','}
                      />
                    </Text>
                  </GridUnit>
                  <GridUnit size={1 / 4}>
                    <Text size="s" p={{ x: 4, y: 3 }}>
                      <NumberFormat
                        renderText={(v) => v}
                        displayType="text"
                        value={meter.included}
                        thousandSeparator={','}
                      />
                    </Text>
                  </GridUnit>
                  <GridUnit size={1 / 4}>
                    <Div flex={{ justify: 'flex-end' }}>
                      <Text size="s" p={{ x: 4, y: 3 }}>
                        ${meter.overage.toFixed(2)}
                      </Text>
                    </Div>
                  </GridUnit>
                </Grid>
              </StyledCardSection>
            ))}
          <StyledCardSection flex={{ justify: 'space-between' }} p={{ x: 4, y: 3 }}>
            <Text semi size="s">
              Total metered usage cost
            </Text>
            <Text semi size="s">
              ${meters_usage.reduce((total, { overage }) => total + overage, 0).toFixed(2)}
            </Text>
          </StyledCardSection>
        </StyledCard>
      </Div>
    </Div>
  );
};
const MemoizedEventsUsage = memo(EventsUsage);

export default MemoizedEventsUsage;
