import Text from '../base/Text';

const MetricNoData: React.FC = () => {
  return (
    <Text muted center>
      No data
    </Text>
  );
};

export default MetricNoData;
