import { differenceInDays } from 'date-fns';
import { useContext } from 'react';
import { Route } from 'react-router-dom';
import useSWR from 'swr';

import { Grid, GridUnit } from '@hookdeck/theme';

import APIMethodKeys from '../../../../../../client/APIMethodKeys';
import { capitalizeFirstLetter } from '../../../../../../utils';
import { isFreePlan } from '../../../../../../utils/subscription';
import Alert from '../../../../../common/base/Alert';
import Badge from '../../../../../common/base/Badge';
import Button from '../../../../../common/base/Button';
import { StyledCard, StyledCardSection } from '../../../../../common/base/Card';
import Container from '../../../../../common/base/Container';
import Divider from '../../../../../common/base/Divider';
import Icon from '../../../../../common/base/Icon';
import Loading from '../../../../../common/base/Loading';
import Text from '../../../../../common/base/Text';
import Tooltip from '../../../../../common/base/Tooltip';
import { Div } from '../../../../../common/helpers/StyledUtils';
import { GlobalContext } from '../../../../../contexts/GlobalContext';
import { useAuthorized } from '../../../../../contexts/TeamPermissionContext';
import { DashboardContext } from '../../../DashboardContext';
import EmailModal from './EmailModal';
import { InvoicesTable } from './InvoicesTable';
import MemoizedEventsUsage from './MeteredUsage';
import PaymentMethodModal from './PaymentMethodModal';
import ProjectThroughputs from './ProjectsThroughput';

const OrganizationBilling: React.FC = () => {
  const isAuthorized = useAuthorized('admin');
  const { subscription } = useContext(DashboardContext);
  const { HookdeckAPI } = useContext(GlobalContext);

  const { data: card } = useSWR(APIMethodKeys.billing.getCard(), () =>
    HookdeckAPI.billing.getCard(),
  );

  const { data: subscription_details } = useSWR(
    APIMethodKeys.billing.getSubscriptionDetails(),
    () => HookdeckAPI.billing.getSubscriptionDetails(),
  );

  const { data: invoices } = useSWR(APIMethodKeys.billing.getInvoices(), () =>
    HookdeckAPI.billing.getInvoices(),
  );

  const { data: billing_email, mutate: mutateBillingEmail } = useSWR(
    APIMethodKeys.billing.getBillingEmail(),
    () => HookdeckAPI.billing.getBillingEmail(),
  );

  if (subscription_details === undefined || billing_email === undefined) {
    return (
      <Div p={8} flex={{ justify: 'center' }}>
        <Loading />
      </Div>
    );
  }

  const display_price = subscription_details.plan.prices.reduce((total, price) => {
    if (price.price_type === 'fixed_price') {
      let amount = Number(price.unit_config?.unit_amount) * price.fixed_price_quantity;
      if (subscription_details.plan.discount?.applies_to_price_ids.includes(price.id)) {
        let discount = 0;
        if (subscription_details.plan.discount.discount_type === 'amount') {
          discount = Number(subscription_details.plan.discount.amount_discount);
        } else if (subscription_details.plan.discount.discount_type === 'percentage') {
          discount = amount * subscription_details.plan.discount.percentage_discount;
        }
        amount = amount - discount;
      }
      return total + amount;
    }

    return total;
  }, 0);

  const plan_features =
    (subscription_details.plan.metadata.features?.length > 0 &&
      subscription_details.plan.metadata.features?.split(',')) ||
    [];
  const billing_email_unset =
    !billing_email?.email || billing_email?.email === 'placeholder@hookdeck.com';
  return (
    <>
      <Container xlarge left>
        <Route
          path="/settings/organization/billing/email"
          render={() =>
            isAuthorized && (
              <EmailModal
                email={billing_email.email}
                mutate={(email) => mutateBillingEmail({ email })}
              />
            )
          }
        />
        <Route
          path="/settings/organization/billing/payment-method"
          component={isAuthorized ? PaymentMethodModal : undefined}
        />
        <Div m={{ b: 14 }}>
          <Div flex={{ justify: 'space-between', align: 'center' }} m={{ b: 4 }}>
            <Text bold size="l" as="h2" m={0}>
              Billing
            </Text>
          </Div>
          <Grid gap={6}>
            <GridUnit size={1 / 3}>
              <StyledCard p={4}>
                <Text semi size="xs">
                  Billing period
                </Text>
                <Text base size="m">
                  {new Date(
                    subscription_details.current_billing_period_start_date,
                  ).toLocaleDateString('en-US', {
                    weekday: undefined,
                    year: undefined,
                    month: 'short',
                    day: 'numeric',
                  })}
                  {' -> '}
                  {new Date(
                    subscription_details.current_billing_period_end_date,
                  ).toLocaleDateString('en-US', {
                    weekday: undefined,
                    year: undefined,
                    month: 'short',
                    day: 'numeric',
                  })}
                </Text>
              </StyledCard>
            </GridUnit>
            <GridUnit size={1 / 3}>
              <StyledCard p={4}>
                <Div w={100} flex={{ justify: 'space-between', align: 'center' }}>
                  <Div>
                    <Text semi size="xs">
                      Billing email
                    </Text>
                    <Text muted={billing_email_unset} ellipsis style={{ maxWidth: '200px' }}>
                      {billing_email_unset ? 'Add billing email' : billing_email.email}
                    </Text>
                  </Div>
                  <Button.Permission
                    role="admin"
                    invisible
                    icon={billing_email_unset ? 'add' : 'edit'}
                    to={(location) => ({
                      ...location,
                      pathname: `/settings/organization/billing/email`,
                    })}
                  />
                </Div>
              </StyledCard>
            </GridUnit>
            <GridUnit size={1 / 3}>
              <StyledCard p={4}>
                <Div flex={{ justify: 'space-between', align: 'center' }}>
                  <Div>
                    <Text semi size="xs">
                      Payment method
                    </Text>
                    <Text base size="m">
                      {card ? (
                        <Text size="s">
                          {capitalizeFirstLetter(card.brand)} ****{card.last4}
                        </Text>
                      ) : (
                        <Text muted>Add payment method</Text>
                      )}
                    </Text>
                  </Div>
                  <Button.Permission
                    role="admin"
                    invisible
                    icon={card ? 'edit' : 'add'}
                    to={(location) => ({
                      ...location,
                      pathname: `/settings/organization/billing/payment-method`,
                      state: { scroll: false },
                    })}
                  />
                </Div>
              </StyledCard>
            </GridUnit>
          </Grid>
        </Div>
        <Div m={{ b: 14 }}>
          <Text bold size="l" as="h2" m={{ t: 0, b: 4 }}>
            Invoices
          </Text>
          <InvoicesTable invoices={invoices} />
        </Div>
      </Container>
      <Div p={{ x: 8 }} m={{ b: 11 }}>
        <Divider />
      </Div>
      <Container xlarge left>
        <Div m={{ b: 14 }}>
          <Div flex={{ justify: 'space-between', align: 'center' }} m={{ t: 0, b: 4 }}>
            <Text bold size="l" as="h2" m={0}>
              Plan
            </Text>
            <Button
              neutral
              to={(location) => ({
                ...location,
                pathname: `/settings/organization/plans`,
                state: { scroll: false },
              })}
              icon={subscription && isFreePlan(subscription.plan) ? 'upgrade' : 'plans'}>
              {subscription && isFreePlan(subscription.plan) ? 'Upgrade plan' : 'Change plan'}
            </Button>
          </Div>
          {subscription?.cancel_at && new Date(subscription.cancel_at).getTime() > Date.now() && (
            <Alert warning inline m={{ b: 4 }}>
              You have {differenceInDays(new Date(subscription.cancel_at), new Date())} days left to
              your plan. Afterward your plan will be downgraded to the Developer (free) Plan.
            </Alert>
          )}
          <StyledCard m={{ b: 4 }}>
            <StyledCardSection muted>
              <Grid>
                <GridUnit size={1 / 4}>
                  <Div flex={{ justify: 'space-between' }}>
                    <Text muted semi size="xs" p={{ x: 4, y: 1 }} style={{ lineHeight: '20px' }}>
                      Current Plan
                    </Text>
                  </Div>
                </GridUnit>
                <GridUnit size={1 / 4}>
                  <Div flex={{ justify: 'space-between' }}>
                    <Text muted semi size="xs" p={{ x: 4, y: 1 }} style={{ lineHeight: '20px' }}>
                      Number of seats
                    </Text>
                  </Div>
                </GridUnit>
                <GridUnit size={1 / 4}>
                  <Text muted semi size="xs" p={{ x: 4, y: 1 }} style={{ lineHeight: '20px' }}>
                    Retention length
                  </Text>
                </GridUnit>
                <GridUnit size={1 / 4}>
                  <Text muted semi size="xs" p={{ x: 4, y: 1 }} style={{ lineHeight: '20px' }}>
                    Base plan price
                  </Text>
                </GridUnit>
              </Grid>
            </StyledCardSection>
            <StyledCardSection>
              <Grid>
                <GridUnit size={1 / 4}>
                  <Div flex={{ justify: 'space-between' }}>
                    <Div p={{ x: 4, y: 3 }} flex={{ align: 'center', gap: 2 }}>
                      <Text capitalize semi size="s">
                        {subscription_details.plan.name}
                      </Text>
                      <Tooltip
                        tooltip={
                          plan_features && plan_features.length > 0
                            ? `Includes ${plan_features.join(', ')}`
                            : 'No features'
                        }>
                        <Icon muted small pointer icon="info" />
                      </Tooltip>
                    </Div>
                  </Div>
                </GridUnit>
                <GridUnit size={1 / 4}>
                  <Div p={{ x: 4, y: 3 }}>
                    <Badge neutral>{subscription?.max_users || 'Unlimited'} seats</Badge>
                  </Div>
                </GridUnit>
                <GridUnit size={1 / 4}>
                  <Div p={{ x: 4, y: 3 }}>
                    <Badge neutral>{subscription?.retention_days} days</Badge>
                  </Div>
                </GridUnit>
                <GridUnit size={1 / 4}>
                  <Div p={{ x: 4, y: 3 }} flex={{ justify: 'flex-start', align: 'center', gap: 2 }}>
                    <Text size="s">${display_price}</Text>
                    <Tooltip tooltip="This amount will be charged at the start of your next billing cycle, after pro-rata adjustements, credits, and discounts.">
                      <Icon muted small pointer icon="info" />
                    </Tooltip>
                  </Div>
                </GridUnit>
              </Grid>
            </StyledCardSection>
          </StyledCard>
        </Div>
        <Div m={{ b: 14 }}>
          <ProjectThroughputs subscription_details={subscription_details} />
        </Div>
        <MemoizedEventsUsage subscription_details={subscription_details} />
      </Container>
    </>
  );
};

export default OrganizationBilling;
