import { useEffect, useState } from 'react';
import styled, { css } from 'styled-components';

import { Div, StyledUtilsProps } from '../../helpers/StyledUtils';
import { MetricName } from '../metric-definitions';
import { MetricConfigs } from '../useMetric';
import MetricCard from './MetricCard';

interface Props extends StyledUtilsProps {
  columns?: number;
  metrics: MetricName[];
  refresh_key?: number;
  configs: MetricConfigs;
  action?: React.ReactNode;
}

const StyledCardGroup = styled(Div)<{ columns: number; gap: number }>(
  ({ theme, columns, gap }) => css`
    display: grid;
    grid-template-columns: repeat(${columns}, minmax(0, 1fr));
    grid-gap: ${theme.spacing(gap)};
  `,
);

const MetricCardGroup = ({
  columns = 3,
  metrics,
  refresh_key,
  configs,
  action,
  ...other_props
}: Props) => {
  const [loading, setLoading] = useState({});

  useEffect(() => {
    setLoading(metrics.reduce((prev, name) => ({ ...prev, [name]: true }), {}));
  }, [refresh_key, JSON.stringify(configs)]);

  const handleLoad = (name: MetricName) => {
    setLoading((prev) => ({ ...prev, [name]: false }));
  };

  const _loading = Object.values(loading).some((value) => value);

  return (
    <Div {...other_props}>
      <StyledCardGroup columns={columns} gap={6}>
        {metrics.map((metric, i) => (
          <MetricCard
            key={`${metric}-${i}`}
            metric={metric}
            refresh_key={refresh_key}
            configs={configs}
            loading={_loading}
            onLoad={() => handleLoad(metric)}
            action={action}
          />
        ))}
      </StyledCardGroup>
    </Div>
  );
};

export default MetricCardGroup;
