import { addDays, differenceInHours, format } from 'date-fns';
import { useContext } from 'react';
import { useParams } from 'react-router';
import useSWR from 'swr';

import { APIList } from '../../../../../../../typings/API.interface';
import { Event } from '../../../../../../../typings/Event.interface';
import { EventAttempt } from '../../../../../../../typings/EventAttempt.interface';
import APIMethodKeys from '../../../../client/APIMethodKeys';
import { copyTextToClipboard } from '../../../../utils/copy';
import { getErrorCodeLabels } from '../../../../utils/error-codes';
import Alert from '../../../common/base/Alert';
import Container from '../../../common/base/Container';
import Divider from '../../../common/base/Divider';
import Icon from '../../../common/base/Icon';
import LabelButton from '../../../common/base/LabelButton';
import Link from '../../../common/base/Link';
import Text from '../../../common/base/Text';
import { Div } from '../../../common/helpers/StyledUtils';
import { Status } from '../../../common/Status';
import { useToasts } from '../../../common/Toast';
import { GlobalContext } from '../../../contexts/GlobalContext';
import { DashboardContext } from '../DashboardContext';
import { EventHTTPRequest } from './EventHTTPRequest';

const MetadataGrid = (props: { children: React.ReactNode }) => (
  <Div grid={{ columns: '45% 55%' }} {...props} />
);

export const EventOverview = ({
  event,
  attempts,
  onAttemptSelected,
}: {
  event: Event;
  attempts: APIList<EventAttempt>;
  onAttemptSelected: (attempt_id: string) => void;
}) => {
  const { id: event_id } = useParams<{ id: string }>();
  const { subscription, organization } = useContext(DashboardContext);
  const { addToast } = useToasts();
  const { HookdeckAPI } = useContext(GlobalContext);
  const { data: webhook } = useSWR(event && APIMethodKeys.webhooks.get(event.webhook_id), () =>
    HookdeckAPI.webhooks.get(event!.webhook_id),
  );
  const { data: cli_clients } = useSWR(
    event && event.cli_id && APIMethodKeys.cli_clients.list({ id: event.cli_id }),
    () => HookdeckAPI.cli_clients.list({ id: event!.cli_id }),
  );

  const archived_at = addDays(new Date(event.created_at), subscription!.retention_days);
  const hours_before_archive = differenceInHours(archived_at, new Date());
  return (
    <>
      {' '}
      <Container xlarge left>
        <Text bold size="l" m={{ t: 10, b: 4 }}>
          Metadata
        </Text>
        <Div grid={{ columns: 2, gap: 3 }}>
          <MetadataGrid>
            <Text muted ellipsis>
              Status
            </Text>
            <Status small show_response={false} {...event} />
          </MetadataGrid>

          <MetadataGrid>
            <Text muted ellipsis>
              Latest attempt
            </Text>
            {attempts.count === 0 ? (
              <Text mono muted>
                ---
              </Text>
            ) : (
              <LabelButton
                mono
                label={attempts.models[0].id}
                neutral
                onClick={() => onAttemptSelected(attempts.models[0].id)}
              />
            )}
          </MetadataGrid>

          <MetadataGrid>
            <Text muted ellipsis>
              Event ID
            </Text>
            <Div
              onClick={() =>
                copyTextToClipboard(event_id).then(() => addToast('success', 'Copied to clipboard'))
              }
              style={{ cursor: 'pointer' }}
              flex={{ align: 'center', gap: 1 }}>
              <Text mono ellipsis>
                {event_id}
              </Text>
              <Icon icon="copy" small pointer />
            </Div>
          </MetadataGrid>

          <MetadataGrid>
            <Text muted ellipsis>
              Latest attempt at
            </Text>
            <Text mono ellipsis>
              {format(new Date(event.last_attempt_at), `MMM d HH:mm:ss`).toUpperCase()}
            </Text>
          </MetadataGrid>

          <MetadataGrid>
            <Text muted ellipsis>
              Latest response
            </Text>
            <Status small {...event} />
          </MetadataGrid>

          <MetadataGrid>
            <Text muted ellipsis>
              Next attempt at
            </Text>
            <Text mono ellipsis>
              {event.next_attempt_at
                ? format(new Date(event.next_attempt_at), `MMM d HH:mm:ss`).toUpperCase()
                : '---'}
            </Text>
          </MetadataGrid>

          <MetadataGrid>
            <Text muted ellipsis>
              Created at
            </Text>
            <Text mono ellipsis>
              {format(new Date(event.created_at), `MMM d HH:mm:ss`).toUpperCase()}
            </Text>
          </MetadataGrid>

          <MetadataGrid>
            <Text muted ellipsis>
              Connection
            </Text>
            <LabelButton
              mono
              label={webhook!.full_name}
              neutral
              to={`/connections/${webhook!.id}`}
            />
          </MetadataGrid>

          <MetadataGrid>
            <Text muted ellipsis>
              Successful at
            </Text>
            <Text mono ellipsis>
              {event.successful_at
                ? format(new Date(event.successful_at), `MMM d HH:mm:ss`).toUpperCase()
                : '---'}
            </Text>
          </MetadataGrid>

          <MetadataGrid>
            <Text muted ellipsis>
              Request
            </Text>
            <LabelButton
              mono
              label={event.request_id}
              neutral
              to={`/requests/${event.request_id}`}
            />
          </MetadataGrid>

          <MetadataGrid>
            <Text muted ellipsis>
              Delivery attempts
            </Text>
            <Link neutral icon="link" to={`/events/${event_id}?tab=attempts`}>
              {event.attempts}
            </Link>
          </MetadataGrid>

          <MetadataGrid>
            <Text muted ellipsis>
              CLI Client
            </Text>
            <Text>{cli_clients?.models[0]?.device_name || '---'}</Text>
          </MetadataGrid>
        </Div>

        {event.error_code && (
          <Alert inline m={{ t: 4 }} danger>
            {getErrorCodeLabels(subscription)[event.error_code]}
          </Alert>
        )}
        {hours_before_archive <= 24 && hours_before_archive >= 0 && (
          <Alert info inline m={{ t: 4 }}>
            This event will be archived in{' '}
            {hours_before_archive === 0 ? 'less then an hour' : `${hours_before_archive} hours`}.
            {!organization && (
              <Link to="/settings/organization/plans?highlight=retention_days" m={{ l: 1 }}>
                Upgrade <Icon small icon="upgrade" />
              </Link>
            )}
          </Alert>
        )}
      </Container>
      <Div m={{ x: 8, y: 10 }}>
        <Divider />
      </Div>
      <Container left>
        {event.data ? (
          <EventHTTPRequest event={event} webhook={webhook!} />
        ) : (
          <>
            <Text bold size="l" m={{ t: 0, b: 4 }}>
              HTTP Request
            </Text>
            <Div flex={{ gap: 1 }}>
              <Text as="p">
                You can't access request data, since this event occurred prior to your{' '}
                {subscription!.retention_days}-day archival period.
              </Text>
              <Link
                to="/settings/organization/plans?highlight=retention_days"
                flex={{ align: 'center', gap: 2 }}>
                <Text>Upgrade now to extend.</Text>
              </Link>
            </Div>
          </>
        )}
      </Container>
    </>
  );
};
