import React, { useContext } from 'react';

import { ShortEventData } from '../../../../../../typings/EventData.interface';
import { StyledCard, StyledCardSection } from '../base/Card';
import Text from '../base/Text';
import { Div } from '../helpers/StyledUtils';
import RequestPath from './RequestPath';
import RequestQuery from './RequestQuery';
import RequestBody from './RequestBody';
import { GlobalContext } from '../../contexts/GlobalContext';
import KeyValueTable from '../KeyValueTable';
import filteredHeaders from '../../../utils/headers';

const FullRequestData: React.FC<{
  data: ShortEventData;
  compact: boolean;
  type: 'event' | 'request' | 'bookmark';
  id: string;
}> = ({ data, compact, type, id }) => {
  const { HookdeckAPI } = useContext(GlobalContext);
  return (
    <Div flex={{ direction: 'column', gap: compact ? 4 : 8 }}>
      <KeyValueTable
        compact={compact}
        label="Headers"
        object={filteredHeaders(data.headers)}
        preference_key="requests:headers"
      />
      {data.headers && data.headers['x-hookdeck-original-method'] && (
        <StyledCard>
          <StyledCardSection
            flex={{ justify: 'space-between', align: 'center' }}
            p={{ y: 3, x: 4 }}>
            <Text semi size="s">
              Method
            </Text>
          </StyledCardSection>
          <StyledCardSection muted p={{ x: 4, y: 3 }}>
            <Text mono size="s" word_break="break-all" as="span">
              {data.headers['x-hookdeck-original-method']}
            </Text>
          </StyledCardSection>
        </StyledCard>
      )}
      {data.path && <RequestPath compact={compact} path={data.path} />}
      {data.query && (
        <RequestQuery compact={compact} query={data.query} parsed_query={data.parsed_query} />
      )}
      <RequestBody
        compact={compact}
        label="Body"
        body={data.body}
        HookdeckAPI={HookdeckAPI}
        type={type}
        id={id}
      />
    </Div>
  );
};

export default FullRequestData;
