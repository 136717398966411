import { Issue } from '../../../../../../../typings/Issue.interface';
import {
  ISSUE_STATUS_CONFIGS,
  IssueTitlteParts,
  issue_type_configs,
} from '../../../../utils/issues';
import Badge from '../../../common/base/Badge';
import LabelButton from '../../../common/base/LabelButton';
import Text from '../../../common/base/Text';
import { Div } from '../../../common/helpers/StyledUtils';

const IssueTitle: React.FC<{ issue: Issue; parts: IssueTitlteParts }> = ({ issue, parts }) => {
  return (
    <Div flex={{ gap: 1 }} style={{ overflow: 'hidden' }}>
      {parts.map((p, i) => {
        if (typeof p === 'string') {
          return (
            <Text text_wrap={false} semi key={i} m={{ x: 1 }}>
              {p}
            </Text>
          );
        }

        if ('to' in p) {
          return <LabelButton label={p.label} neutral key={i} to={p.to} mono />;
        }

        return (
          <Badge
            icon={issue_type_configs[issue.type].icon}
            key={i}
            subtle={ISSUE_STATUS_CONFIGS[issue.status].badge_theme === 'muted' ? false : true}
            {...{ [ISSUE_STATUS_CONFIGS[issue.status].badge_theme]: true }}>
            {p.label}
          </Badge>
        );
      })}
    </Div>
  );
};

export default IssueTitle;
