import { useHistory, useLocation } from 'react-router';

import { IssueType } from '../../../../../../../typings/Issue.interface';
import { stringifyQuery } from '../../../../utils';
import { issue_type_configs } from '../../../../utils/issues';
import Button from '../../../common/base/Button';
import Icon, { IconName } from '../../../common/base/Icon';
import Tabs from '../../../common/base/Tabs';
import Text from '../../../common/base/Text';
import Tooltip from '../../../common/base/Tooltip';
import { Div } from '../../../common/helpers/StyledUtils';
import useSearchQuery from '../../../hooks/useSearchQuery';
import {
  StyledViewActions,
  StyledViewContent,
  StyledViewLayout,
  StyledViewNav,
  StyledViewNavHeader,
  StyledViewNavSecondary,
} from '../../../layouts/ViewLayout';
import { issue_trigger_types } from './issue_trigger_types';
import TriggersList from './IssueTriggersList';

const issue_types = Object.keys(issue_type_configs) as IssueType[];

const IssueTriggersListView: React.FC = () => {
  const history = useHistory();
  const location = useLocation();
  const path_issue_type = location.pathname.split('/')[2]?.split('#')[0];
  const fallback_path_issue_type = location.pathname.split('/')[3]?.split('#')[0];
  const issue_type = issue_types.includes(path_issue_type as IssueType)
    ? path_issue_type
    : issue_types.includes(fallback_path_issue_type as IssueType)
      ? fallback_path_issue_type
      : 'delivery';
  const { query, updateSearchQuery } = useSearchQuery<{
    status: ('enabled' | 'disabled')[];
  }>();

  return (
    <StyledViewLayout>
      <StyledViewContent light>
        <StyledViewNav>
          <StyledViewNavHeader>
            <Div flex={{ align: 'center', justify: 'space-between', gap: 2 }}>
              <Text semi size="l" ellipsis>
                Issue Triggers
              </Text>
              <Tooltip
                tooltip="Metrics enable you to track key trends and compare statistics across your sources, destinations, and connections."
                placement="bottom-end">
                <Icon icon="info" />
              </Tooltip>
            </Div>
            <StyledViewActions>
              <Button.Permission
                role="member"
                icon="add_circle"
                to={`/issue-triggers/create/${issue_type}`}>
                New issue trigger
              </Button.Permission>
            </StyledViewActions>
          </StyledViewNavHeader>
          <StyledViewNavSecondary p={{ b: 0 }}>
            <Tabs
              active_tab={issue_type as string}
              onTabSelected={(tab) => {
                const query_params = query?.status
                  ? `?${stringifyQuery({ status: query.status })}`
                  : '';
                history.push(`/issue-triggers/${tab}${query_params}`);
              }}
              compact
              border={false}
              tabs={Object.values(issue_trigger_types)}
            />
          </StyledViewNavSecondary>
        </StyledViewNav>
        <Div p={{ y: 8 }}>
          <TriggersList
            type={issue_type as IssueType}
            status={query.status}
            onStatusChange={(status: ('enabled' | 'disabled')[] | undefined) => {
              updateSearchQuery({ status });
            }}
          />
        </Div>
      </StyledViewContent>
    </StyledViewLayout>
  );
};

export default IssueTriggersListView;
