import { useTheme } from 'styled-components';
import { StyledCard } from '../../common/base/Card';
import Text from '../../common/base/Text';
import { Div } from '../../common/helpers/StyledUtils';
import { default as FullscreenLayout } from '../../layouts/FullscreenLayout';
import OnboardingNav from './Onboarding/OnboardingNav';
import TeamSelection from './Nav/TeamSelection/TeamSelection';

const TeamSelect = () => {
  const theme = useTheme();

  return (
    <FullscreenLayout center>
      <OnboardingNav />
      <Div style={{ zIndex: 1, position: 'relative' }} w={{ px: 560 }}>
        <img
          src={`/images/cube-${theme.mode}.svg`}
          style={{
            zIndex: -1,
            left: '-100px',
            top: '-200px',
            transform: 'scale(0.5)',
            position: 'absolute',
          }}
        />
        <img
          src={`/images/cube-${theme.mode}.svg`}
          style={{
            zIndex: -1,
            right: '-200px',
            top: '100px',
            transform: 'scale(0.8) rotate(30deg)',
            position: 'absolute',
          }}
        />
        <img
          src={`/images/cube-${theme.mode}.svg`}
          style={{
            zIndex: -1,
            left: '-100px',
            bottom: '-200px',
            position: 'absolute',
            transform: 'scale(0.6) rotate(-25deg)',
          }}
        />
        <Text bold size="2xl" m={{ b: 1 }}>
          Select a project
        </Text>
        <Text muted size="l" m={{ b: 8 }}>
          Choose a project to work within, or create a new project or organization.
        </Text>
        <StyledCard h={{ px: 316 }}>
          <TeamSelection />
        </StyledCard>
      </Div>
    </FullscreenLayout>
  );
};

export default TeamSelect;
