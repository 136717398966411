import Icon from '../../../common/base/Icon';
import Text from '../../../common/base/Text';

const ConfirmDeleteDialog = ({ text }: { text: string }) => (
  <>
    <Text>{text}</Text>
    <Text danger semi m={{ t: 2 }}>
      <Icon icon="error" left={1} /> This action cannot be undone!
    </Text>
  </>
);

export default ConfirmDeleteDialog;
