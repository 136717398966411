import { useContext } from 'react';
import { useParams } from 'react-router-dom';
import useSWR from 'swr';

import APIMethodKeys from '../../../../client/APIMethodKeys';
import { extractFromArray } from '../../../../utils';
import Button from '../../../common/base/Button';
import Loading from '../../../common/base/Loading';
import Tabs from '../../../common/base/Tabs';
import Text from '../../../common/base/Text';
import Tooltip from '../../../common/base/Tooltip';
import { Div } from '../../../common/helpers/StyledUtils';
import { GlobalContext } from '../../../contexts/GlobalContext';
import useCopyASCurl from '../../../hooks/useCopyAsCurl';
import useEventAction from '../../../hooks/useEventActions';
import useSearchQuery from '../../../hooks/useSearchQuery';
import {
  StyledViewActions,
  StyledViewLayout,
  StyledViewNav,
  StyledViewNavHeader,
  StyledViewNavSecondary,
} from '../../../layouts/ViewLayout';
import { DashboardContext } from '../DashboardContext';
import EventPreview from '../Events/EventPreview';
import NotFound from '../NotFound';
import { StyledViewContent } from '../StyledView';
import { EventAttempts } from './EventAttempts';
import { EventOverview } from './EventOverview';

interface Query {
  selected_attempt_id?: string | string[];
  tab?: 'overview' | 'attempts';
}

const EventView: React.FC = () => {
  const { id: event_id } = useParams();
  const { query, updateSearchQuery } = useSearchQuery<Query>();
  const selected_attempt_id = extractFromArray(query.selected_attempt_id) as string;
  const onAttemptSelected = (selected_attempt_id: string) =>
    updateSearchQuery({ selected_attempt_id, tab: 'attempts' });

  const { HookdeckAPI } = useContext(GlobalContext);
  const { subscription } = useContext(DashboardContext);
  const {
    data: event,
    mutate,
    error,
  } = useSWR(APIMethodKeys.events.get(event_id), () => HookdeckAPI.events.get(event_id), {
    refreshInterval: 5000,
  });
  const { data: webhook, error: webhook_error } = useSWR(
    event && APIMethodKeys.webhooks.get(event.webhook_id),
    () => HookdeckAPI.webhooks.get(event!.webhook_id),
  );

  const { data: attempts, mutate: mutateAttempts } = useSWR(
    APIMethodKeys.attempts.list({ event_id }),
    () => HookdeckAPI.attempts.list({ event_id }),
    {
      refreshInterval: 5000,
    },
  );
  const { data: bookmarks, mutate: mutateBookmarks } = useSWR(
    event && APIMethodKeys.bookmarks.list({ event_data_id: event.event_data_id }),
    () => HookdeckAPI.bookmarks.list({ event_data_id: event!.event_data_id }),
  );

  const { data: cli_clients } = useSWR(
    event && event.cli_id && APIMethodKeys.cli_clients.list({ id: event.cli_id }),
    () => HookdeckAPI.cli_clients.list({ id: event!.cli_id }),
  );

  const copyAsCurl = useCopyASCurl({
    type: 'event',
    id: event_id,
    event_data: event?.data ?? undefined,
    destination: webhook?.destination,
  });
  const current_status = query.tab ? query.tab : ('overview' as Query['tab'])!;
  const TABS = {
    OVERVIEW: { label: 'Overview', key: 'overview' },
    ATTEMPTS: {
      label: 'Delivery attempts',
      key: 'attempts',
      badge_theme: 'muted',
      badge_label: attempts?.models.length,
    },
  } as const;

  const { retry, bookmark } = useEventAction((action, event, meta) => {
    switch (action) {
      case 'mute':
        mutate(event);
        break;
      case 'retry':
        mutate();
        mutateAttempts();
        break;
      case 'bookmark':
        mutateBookmarks((bookmarks) => ({
          ...bookmarks!,
          models: [...(bookmarks?.models ?? []), ...(meta?.bookmark ? [meta?.bookmark] : [])],
        }));
        break;
    }
  });

  if (error && error.response?.status === 404) {
    return (
      <NotFound
        title="Event is archived or does not exist"
        description={`Events are automatically archived after your plan ${
          subscription!.retention_days
        } days retention period has passed.`}
        id={event_id}
        link={{ to: '/events', text: 'All Events' }}
      />
    );
  }

  if (
    !event ||
    (!webhook && !webhook_error) ||
    !attempts ||
    !bookmarks ||
    (event?.cli_id && !cli_clients)
  ) {
    return (
      <Div flex={{ justify: 'center' }} p={8}>
        <Loading />
      </Div>
    );
  }

  return (
    <StyledViewLayout>
      <StyledViewContent light>
        <StyledViewNav>
          <StyledViewNavHeader>
            <Div flex={{ align: 'center', justify: 'space-between', gap: 2 }}>
              <Text semi size="l" neutral ellipsis>
                Events /{' '}
              </Text>
              <Text semi size="l" ellipsis>
                {event_id}
              </Text>
            </Div>
            <StyledViewActions>
              <Tooltip tooltip="Copy request as cURL" placement="bottom-end">
                <Button neutral icon="copy" onClick={copyAsCurl}>
                  cURL
                </Button>
              </Tooltip>
              <Button.Permission
                role="member"
                neutral
                onClick={() => bookmark(event)}
                disabled={bookmarks.models.length > 0}
                icon={bookmarks.models.length > 0 ? 'bookmark' : 'bookmark_add'}>
                {bookmarks.models.length > 0 ? 'Bookmarked' : 'Bookmark'}
              </Button.Permission>
              <Button.Permission
                role="member"
                onClick={() => retry(event)}
                neutral
                icon="retry"
                disabled={webhook_error?.response?.status === 410}>
                Retry
              </Button.Permission>
            </StyledViewActions>
          </StyledViewNavHeader>
          <StyledViewNavSecondary p={{ b: 0 }}>
            <Tabs
              active_tab={current_status}
              onTabSelected={(tab) => updateSearchQuery({ tab, selected_attempt_id: undefined })}
              compact
              border={false}
              tabs={Object.values(TABS)}
            />
          </StyledViewNavSecondary>
        </StyledViewNav>
        {current_status === TABS.OVERVIEW.key && (
          <EventOverview event={event} attempts={attempts} onAttemptSelected={onAttemptSelected} />
        )}
        {current_status === TABS.ATTEMPTS.key && (
          <EventAttempts
            attempts={attempts}
            selected_attempt_id={selected_attempt_id}
            onAttemptSelected={onAttemptSelected}
          />
        )}
      </StyledViewContent>
      {selected_attempt_id && (
        <EventPreview
          event_id={event_id}
          attempt_id={selected_attempt_id}
          onClose={() => updateSearchQuery({}, { remove_keys: ['selected_attempt_id'] })}
        />
      )}
    </StyledViewLayout>
  );
};

export default EventView;
