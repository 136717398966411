import { useState } from 'react';
import styled from 'styled-components';

import {
  CLIDestinationTypeConfig,
  HTTPDestinationTypeConfig,
} from '../../../../../../../typings/Destination.interface';
import { Event } from '../../../../../../../typings/Event.interface';
import { APIWebhook } from '../../../../../../../typings/Webhook.interface';
import LINKS from '../../../../configs/links';
import { useCopyToClipboard } from '../../../../utils/copy';
import Badge from '../../../common/base/Badge';
import { ClickableArea } from '../../../common/base/Button';
import Icon from '../../../common/base/Icon';
import Text from '../../../common/base/Text';
import { Div } from '../../../common/helpers/StyledUtils';
import { CodeBlock } from './CodeBlock';

const SectionCollapsableHeader = styled(ClickableArea)`
  &:hover {
    background-color: transparent !important;
    color: ${({ theme }) => theme.colors.on.neutral.secondary_neutral};
  }
`;

export const EventHTTPRequest = ({ event, webhook }: { event: Event; webhook: APIWebhook }) => {
  const copyToClipboard = useCopyToClipboard();
  const [collapse_body, setCollapseBody] = useState(false);
  const [collapse_headers, setCollapseHeaders] = useState(false);
  const [collapse_query, setCollapseQuery] = useState(false);

  const method =
    event.data?.headers['x-hookdeck-original-method'] ||
    webhook.destination?.config?.http_method ||
    'POST';
  const url =
    event.data?.path ||
    (webhook.destination?.config as HTTPDestinationTypeConfig)?.url ||
    (webhook.destination?.config as CLIDestinationTypeConfig)?.path ||
    (webhook.destination?.type === 'MOCK_API' ? LINKS.mock_api : '');

  const parsed_headers = Object.entries(event.data?.headers || {})
    .map(([key, value]) => `${key}: ${value}`)
    .join('\n');
  const headers_count = Object.keys(event.data?.headers || {}).length;

  return (
    <Div flex={{ direction: 'column' }}>
      <Div>
        <Text bold size="l" m={{ t: 0, b: 4 }}>
          HTTP Request
        </Text>
      </Div>

      <Div flex={{ align: 'center', gap: 2 }} m={{ b: 8 }}>
        <Badge neutral>{method}</Badge>
        <Div
          style={{ cursor: 'pointer' }}
          flex={{ align: 'center', gap: 2 }}
          onClick={() => copyToClipboard(url)}>
          <Text mono as="span" ellipsis size="s">
            {url}
          </Text>
          <Icon icon="copy" pointer />
        </Div>
      </Div>

      <Div m={{ b: 10 }}>
        <SectionCollapsableHeader
          p={0}
          m={{ b: 2 }}
          w={{ raw: 'auto' }}
          rounded
          flex={{ align: 'center', gap: 2 }}
          onClick={() => setCollapseHeaders(!collapse_headers)}>
          <Text semi>Headers</Text>
          <Badge neutral>{headers_count}</Badge>
          <Icon icon={collapse_headers ? 'chevron_up' : 'chevron_down'} />
        </SectionCollapsableHeader>
        {!collapse_headers && <CodeBlock text={parsed_headers} lang="yaml" />}
      </Div>

      {event.data?.query && (
        <Div m={{ b: 10 }}>
          <SectionCollapsableHeader
            p={0}
            m={{ b: 2 }}
            w={{ raw: 'auto' }}
            flex={{ align: 'center', gap: 2 }}
            onClick={() => setCollapseQuery(!collapse_query)}>
            <Text semi>Query</Text>
            <Icon icon={collapse_query ? 'chevron_up' : 'chevron_down'} />
          </SectionCollapsableHeader>
          {!collapse_query && <CodeBlock text={event.data!.query} />}
        </Div>
      )}

      {event.data?.body && (
        <Div m={{ b: 20 }}>
          <SectionCollapsableHeader
            p={0}
            m={{ b: 2 }}
            w={{ raw: 'auto' }}
            flex={{ align: 'center', gap: 2 }}
            onClick={() => setCollapseBody(!collapse_body)}>
            <Text semi>Body</Text>
            <Icon icon={collapse_body ? 'chevron_up' : 'chevron_down'} />
          </SectionCollapsableHeader>
          {!collapse_body && <CodeBlock text={event.data!.body} />}
        </Div>
      )}
    </Div>
  );
};
