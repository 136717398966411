import styled, { css } from 'styled-components';

import { showChat } from '../../../../utils/liveChat';
import Badge from '../../../common/base/Badge';
import Button, { ClickableArea } from '../../../common/base/Button';
import Text from '../../../common/base/Text';
import { Div } from '../../../common/helpers/StyledUtils';

const StyledOnboardingNav = styled.div(
  ({ theme }) => css`
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    align-items: center;
    padding: 0 ${theme.spacing(4)};
    height: 56px;
    z-index: 2;
    border-bottom: ${theme.border};
    background-color: ${theme.colors.surface.base.surface};
    z-index: ${theme.zindex.sticky};
  `,
);

const GuidedCreateConnectionNav: React.FC<{
  current_step: string;
  steps: { key: string; nav_label: string }[];
}> = ({ current_step, steps }) => {
  const index_of_current = steps.findIndex((step) => step.key === current_step);
  return (
    <StyledOnboardingNav>
      <Text bold>Create Connection</Text>
      <Div flex={{ gap: 6 }}>
        {steps.map((step, i) => (
          <ClickableArea
            disabled={index_of_current < i}
            flex={{ align: 'center', gap: 2 }}
            p={{ x: 2, y: 2 }}
            rounded
            key={step.key}
            onClick={() => {
              const element = document.getElementById(step.key);
              element?.scrollIntoView({ behavior: 'smooth' });
              return null;
            }}>
            <Badge
              primary={current_step === step.key}
              neutral={current_step !== step.key}
              subtle={current_step === step.key}>
              {i + 1}
            </Badge>
            <Text semi muted={current_step !== step.key} primary={current_step === step.key}>
              {step.nav_label}
            </Text>
          </ClickableArea>
        ))}
      </Div>
      <Div flex={{ justify: 'end', gap: 2 }}>
        <Button neutral icon="chat" onClick={showChat}>
          Help
        </Button>
        <Button neutral icon="link" to="/connections">
          Dashboard
        </Button>
      </Div>
    </StyledOnboardingNav>
  );
};

export default GuidedCreateConnectionNav;
