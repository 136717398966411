import { APIDestination } from '../../../../../../../../typings/Destination.interface';
import { IssueType } from '../../../../../../../../typings/Issue.interface';
import IssueTrigger, {
  IssueTriggerConfigs,
  IssueTriggerDeliveryConfigs,
  IssueTriggerTransformationConfigs,
} from '../../../../../../../../typings/IssueTrigger.interface';
import { Transformation } from '../../../../../../../../typings/Transformation.interface';
import { APIWebhook } from '../../../../../../../../typings/Webhook.interface';
import { capitalizeFirstLetter } from '../../../../../utils';
import { getMsIntervalByUnit } from '../../../../../utils/rules';
import Button from '../../../../common/base/Button';
import { StyledCardSection } from '../../../../common/base/Card';
import Icon from '../../../../common/base/Icon';
import LabelButton from '../../../../common/base/LabelButton';
import Link from '../../../../common/base/Link';
import Text from '../../../../common/base/Text';
import Tooltip from '../../../../common/base/Tooltip';
import { Div } from '../../../../common/helpers/StyledUtils';
import { IssueTriggersRenderData } from './';

type GetReferenceName = (reference: APIWebhook | Transformation | APIDestination) => string;

const referenceToText = (
  link_prefix: string,
  reference: '*' | string | string[],
  reference_data: Record<string, APIWebhook | Transformation | APIDestination>,
  label: string,
  getReferenceName: GetReferenceName,
): { pre: string; values: string | { label: string; to: string }[] } => {
  if (reference === '*') {
    return {
      pre: 'for',
      values: `All ${label}`,
    };
  }
  if (Array.isArray(reference)) {
    return {
      pre: `for ${label.toLocaleLowerCase()}`,
      values: reference
        .filter((ref) => !!reference_data[ref])
        .map((ref) => ({
          label: getReferenceName(reference_data[ref]),
          to: `${link_prefix}/${ref}`,
        })),
    };
  }
  return {
    pre: `for ${label.toLocaleLowerCase()} with pattern`,
    values: reference,
  };
};

const content_configs: Record<
  IssueType,
  {
    texts: string[];
    configsToText: Record<keyof IssueTriggerConfigs, (config: IssueTriggerConfigs) => string>;
    render_data_key: keyof IssueTriggersRenderData;
    reference_label: string;
    reference_config_key: string;
    getReferenceName: GetReferenceName;
  }
> = {
  delivery: {
    texts: ['Open issue after the', '$strategy', 'delivery attempt failure'],
    configsToText: {
      strategy: (configs) =>
        capitalizeFirstLetter((configs as IssueTriggerDeliveryConfigs).strategy.split('_')[0]),
    },
    reference_config_key: 'connections',
    render_data_key: 'webhooks_by_id',
    reference_label: 'Connections',
    getReferenceName: (ref: APIWebhook) => ref.full_name,
  },
  transformation: {
    texts: ['Open issue on execution with log level', '$log_level', 'or higher'],
    configsToText: {
      log_level: (configs) =>
        capitalizeFirstLetter((configs as IssueTriggerTransformationConfigs).log_level),
    },
    reference_config_key: 'transformations',
    render_data_key: 'transformations_by_id',
    reference_label: 'Transformations',
    getReferenceName: (ref: Transformation) => ref.name,
  },
  backpressure: {
    texts: ['Open issue when backpressure is', '$delay', 'or higher'],
    configsToText: {
      delay: (configs) => {
        const [value, interval] = getMsIntervalByUnit(configs.delay);
        return `${value} ${interval}`;
      },
    },
    reference_config_key: 'destinations',
    render_data_key: 'destinations_by_id',
    reference_label: 'Destinations',
    getReferenceName: (ref: Transformation) => ref.name,
  },
};

const trigger_type_to_link_prefix: Record<IssueType, string> = {
  delivery: '/connections',
  transformation: '/transformations',
  backpressure: '/destinations',
};

const IssueTriggerCard: React.FC<{
  issue_trigger: IssueTrigger;
  render_data: IssueTriggersRenderData;
}> = ({ issue_trigger, render_data }) => {
  const {
    texts,
    configsToText,
    render_data_key,
    reference_label,
    reference_config_key,
    getReferenceName,
  } = content_configs[issue_trigger.type];
  const { pre, values } = referenceToText(
    trigger_type_to_link_prefix[issue_trigger.type],
    issue_trigger.configs[reference_config_key],
    render_data[render_data_key],
    reference_label,
    getReferenceName,
  );

  return (
    <StyledCardSection p={4}>
      <Div flex={{ wrap: true, gap: 2 }}>
        <Link neutral to={`/issue-triggers/${issue_trigger.id}`}>
          <Text as="span">
            {texts.map((text, i) => {
              if (text.indexOf('$') === 0) {
                const key = text.split('$')[1];
                return (
                  configsToText[key] && (
                    <Text key={key} semi dark as="span">
                      {i !== 0 ? ' ' : ''}
                      {configsToText[key](issue_trigger.configs).toLowerCase()}
                    </Text>
                  )
                );
              }
              return `${i !== 0 ? ' ' : ''}${text}`;
            })}{' '}
            {pre}{' '}
            {typeof values === 'string' && (
              <Text semi dark as="span">
                {values?.toLowerCase()}
              </Text>
            )}
          </Text>
        </Link>
        {Array.isArray(values) && (
          <>
            {values
              .filter((_, i) => i < 3)
              .map(({ label, to }) => (
                <LabelButton neutral label={label} to={to} mono key={to} />
              ))}
            {values.length > 3 && (
              <Text semi muted as="span">
                + {values.length - 3} more
              </Text>
            )}
          </>
        )}
      </Div>
      <Div m={{ t: 1 }} flex={{ align: 'center', gap: 1 }}>
        <Tooltip
          placement="bottom-start"
          tooltip={issue_trigger.disabled_at ? 'Trigger disabled' : 'Trigger enabled'}>
          <Icon muted icon={issue_trigger.disabled_at ? 'notification_off' : 'notification'} />
        </Tooltip>
        <Text size="xs" semi muted>
          {issue_trigger.disabled_at ? 'Disabled' : 'Enabled'}
        </Text>
      </Div>
    </StyledCardSection>
  );
};

export default IssueTriggerCard;
