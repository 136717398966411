import { useContext } from 'react';
import styled from 'styled-components';
import useSWR from 'swr';
import APIMethodKeys from '../../../../client/APIMethodKeys';
import Button from '../../../common/base/Button';
import Loading from '../../../common/base/Loading';
import Text from '../../../common/base/Text';
import { Div } from '../../../common/helpers/StyledUtils';
import { GlobalContext } from '../../../contexts/GlobalContext';

const StyledEmptyView = styled.div`
  flex-grow: 1;
  max-width: 480px;
  margin: 0 auto;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const BookmarksListEmptyView: React.FC = () => {
  const { HookdeckAPI } = useContext(GlobalContext);
  const { data: entries } = useSWR(APIMethodKeys.bookmarks.list({ limit: 1 }), () =>
    HookdeckAPI.bookmarks.list({ limit: 1 }),
  );

  if (!entries) {
    return (
      <StyledEmptyView>
        <Loading />
      </StyledEmptyView>
    );
  }

  return (
    <StyledEmptyView>
      <Div p={{ all: 7 }} flex={{ direction: 'column', align: 'center', justify: 'center' }}>
        <Text bold m={{ bottom: 4 }} size="xl">
          Bookmark your first request
        </Text>
        <Text as="p" center>
          Bookmark any request to make it available for future replay. Bookmarked request are exempt
          from archive.
        </Text>
        <Button to="/events">View Events</Button>
      </Div>
    </StyledEmptyView>
  );
};

export default BookmarksListEmptyView;
