import { Form, Formik } from 'formik';
import { useContext, useEffect, useState } from 'react';

import { APISource } from '../../../../../../../../typings/Source.interface';
import { cleanseFormErrorObject } from '../../../../../utils/form';
import Button from '../../../../common/base/Button';
import { Div } from '../../../../common/helpers/StyledUtils';
import { useToasts } from '../../../../common/Toast';
import { GlobalContext } from '../../../../contexts/GlobalContext';
import resource_details_form_props from '../../Connections/Forms/resource_details';
import configuration_form_props, {
  SourceConfigurationFormValues,
} from '../../Connections/Forms/source_configuration';
import ApiModal from '../ApiModal';
import { DashboardContext } from '../../DashboardContext';

const AddSource: React.FC<{
  context: { source: APISource | null };
  mutateContext: (any) => void;
  nextStep: () => void;
}> = ({ context: { source }, mutateContext, nextStep }) => {
  const { HookdeckAPI } = useContext(GlobalContext);
  const { source_types } = useContext(DashboardContext);
  const { addToast } = useToasts();
  const [render_key, setRenderKey] = useState(1);
  const [show_api_modal, showAPIModal] = useState(false);

  useEffect(() => {
    if (source) {
      setRenderKey((prev) => prev + 1);
    }
  }, [source?.config.allowed_http_methods, source?.config.auth]);

  return (
    <Formik
      key={render_key}
      initialValues={{
        ...resource_details_form_props.getInitialValues(source || undefined),
        ...configuration_form_props.getInitialValues(source || undefined),
      }}
      validateOnMount
      validate={async (values) =>
        cleanseFormErrorObject({
          ...(await resource_details_form_props.validate(
            values,
            (name) =>
              source && name === source.name
                ? Promise.resolve(false)
                : HookdeckAPI.sources.nameIsUsed(name),
            true,
          )),
          ...(await configuration_form_props.validate(
            values as SourceConfigurationFormValues,
            source_types!,
          )),
        })
      }
      onSubmit={(v, { resetForm }) => {
        const values = {
          ...resource_details_form_props.postprocessValues(v),
          ...configuration_form_props.postprocessValues(v as SourceConfigurationFormValues),
        };
        const promise = source
          ? HookdeckAPI.sources.update(source.id, values)
          : HookdeckAPI.sources.create(values);
        return promise
          .then((new_source) => {
            mutateContext({ source: new_source });
            resetForm({
              values: {
                ...v,
                ...resource_details_form_props.getInitialValues(new_source || undefined),
                ...configuration_form_props.getInitialValues(new_source || undefined),
                show_advanced: v.show_advanced,
              },
            });
            if (source) {
              addToast('success', `Source ${new_source.name} configuration updated`);
            }
            nextStep();
          })
          .catch((e) => {
            addToast(
              'error',
              `An error occurred while saving the source${
                e.response?.data[0] ? `: ${e.response.data[0]}` : ''
              }`,
            );
            return;
          });
      }}>
      {({ isValid, isSubmitting, handleSubmit, dirty, values }) => (
        <Form onSubmit={handleSubmit}>
          <Div m={{ t: 0 }}>
            <configuration_form_props.Fields prefix="" />
          </Div>
          <Div flex={{ gap: 3 }} m={{ t: 8 }}>
            <Button
              submit
              disabled={!isValid || isSubmitting || (!!source && !dirty)}
              primary={!source}
              neutral={!!source}
              icon={isSubmitting ? 'loading' : source ? 'save' : 'add_circle'}>
              {source ? 'Update Source' : 'Create Source'}
            </Button>
            <Button invisible icon="code" onClick={() => showAPIModal(true)} disabled={!isValid}>
              Use API
            </Button>
            {show_api_modal && (
              <ApiModal
                action="create-source"
                onClose={() => showAPIModal(false)}
                values={{
                  ...resource_details_form_props.postprocessValues(values),
                  ...configuration_form_props.postprocessValues(
                    values as SourceConfigurationFormValues,
                  ),
                }}
              />
            )}
          </Div>
        </Form>
      )}
    </Formik>
  );
};

export default AddSource;
