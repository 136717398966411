import { Form, Formik, useFormikContext } from 'formik';
import { useContext, useEffect, useState } from 'react';
import { DashboardContext } from '../../DashboardContext';
import SwitchInput from '../../../../common/Form/Fields/SwitchInput';
import { StyledCard, StyledCardSection } from '../../../../common/base/Card';
import { Div } from '../../../../common/helpers/StyledUtils';
import Text from '../../../../common/base/Text';
import { useToasts } from '../../../../common/Toast';
import { GlobalContext } from '../../../../contexts/GlobalContext';
import { UserContext } from '../../../../contexts/UserContext';
import useSWR from 'swr';
import APIMethodKeys from '../../../../../client/APIMethodKeys';
import Button from '../../../../common/base/Button';
import { capitalizeFirstLetter } from '../../../../../utils';
import Link from '../../../../common/base/Link';
import { WithTeamAdminRole } from '../../../../common/WithRole';
import Icon from '../../../../common/base/Icon';
import DropdownMenu from '../../../../common/DropdownMenu';
import { team_levels_by_role } from '../../../../../../../../domain/roles';
import { TeamRole } from '../../../../../../../../typings/TeamMember.interface';
import { useDialog } from '../../../../common/Dialog';
import { Route } from 'react-router';
import AddTeamMemberModal from './AddTeamMemberModal';
import Container from '../../../../common/base/Container';
import Tooltip from '../../../../common/base/Tooltip';

const description_by_role = {
  admin: 'Unrestricted access to this project & can manage members',
  member: 'Restricted access to project members',
  viewer: 'Read-only access to the projects',
};

export const createTeamRoleOptions = (viewer_enabled: boolean) =>
  Object.keys(description_by_role).map((role: TeamRole) => ({
    value: role,
    label: `Project ${capitalizeFirstLetter(role)}`,
    description:
      role === 'viewer' && !viewer_enabled
        ? 'Upgrade plan to enable role'
        : description_by_role[role],
    disabled: role === 'viewer' && !viewer_enabled,
  }));

const SubmitOnchange = () => {
  const formik = useFormikContext();
  useEffect(() => {
    if (formik.dirty) {
      formik.submitForm();
      formik.resetForm({ values: formik.values });
    }
  }, [formik.dirty]);
  return null;
};

const TeamAccessControl: React.FC = () => {
  const { HookdeckAPI } = useContext(GlobalContext);
  const { user } = useContext(UserContext);
  const {
    team,
    mutateTeam,
    organization_role,
    team_role,
    organization,
    resetContext,
    subscription,
  } = useContext(DashboardContext);
  const { addToast } = useToasts();
  const showDialog = useDialog();

  const [show_all_inherited, setShowAllInherited] = useState(false);

  const { data: members, mutate: mutateMembers } = useSWR(
    APIMethodKeys.teams.listMembers(team!.id),
    () => HookdeckAPI.teams.listMembers(),
  );

  const onSubmit = (v) => {
    HookdeckAPI.teams
      .update({
        private: v.private,
      })
      .then((team) => {
        mutateTeam(team);
        mutateMembers(undefined);
        addToast('success', `Project access is now ${v.private ? 'private' : 'public'} `);
      })
      .catch(() => {
        addToast('error', `Failed to update project, please contact us.`);
      });
  };

  const changeRole = async (id: string, role: TeamRole) => {
    try {
      await HookdeckAPI.teams.updateRole(id, role);
      mutateMembers((members) => {
        return members?.map((m) => (m.id === id ? { ...m, role } : m));
      }, false);
      addToast('success', `Role changed to ${capitalizeFirstLetter(role)}`);
    } catch (err) {
      addToast('error', err.message);
    }
  };

  const handleRemove = (id: string, email: string) => {
    showDialog(
      async () => {
        try {
          await HookdeckAPI.teams.removeMember(id);
          mutateMembers((members = []) => {
            members = members?.filter((m) => m.id !== id) ?? [];
            return members;
          }, false);
          addToast('success', 'Member removed from organization');
        } catch (err) {
          addToast('error', err.message);
        }
      },
      () => null,
      {
        title: 'Remove from project',
        message: (
          <Text>
            <Text as="span" bold>
              {email}
            </Text>{' '}
            will lose access to this project and all its data. Are you sure?
          </Text>
        ),
        submit_label: 'Remove from project',
        cancel_label: 'Cancel',
        danger: true,
      },
    );
  };

  const handleLeave = () => {
    showDialog(
      async () => {
        try {
          await HookdeckAPI.teams.leave();
          resetContext();
        } catch (err) {
          addToast('error', err.message);
        }
      },
      () => null,
      {
        title: 'Leave project',
        message: (
          <Text>
            You will lose access to this project and all of it's data. If you’d like to re-join, a
            project admin will need to re-add you. Are you sure?
          </Text>
        ),
        submit_label: 'Leave project',
        cancel_label: 'Stay in project',
        danger: true,
      },
    );
  };

  const inherited_members = members?.filter((m) => m.inherited);

  const managed_members = members?.filter((m) => !m.inherited);

  return (
    <>
      <Route
        path="/settings/project/access/add"
        render={() =>
          members && (
            <AddTeamMemberModal
              members={members}
              onMembersAdded={(new_members) => {
                mutateMembers((members) => {
                  new_members.forEach((member) => {
                    if (!members?.some((m) => m.user_id === member.user_id)) {
                      members = [member, ...(members ?? [])];
                    }
                  });
                  return members;
                }, false);

                addToast('success', 'Member access granted added to project');
              }}
            />
          )
        }
      />
      <Container xlarge left m={{ b: 14 }}>
        <Text bold size="l" as="h2" m={{ t: 0, b: 4 }}>
          Project access
        </Text>
        <Formik
          initialValues={{
            private: team!.private,
          }}
          onSubmit={onSubmit}>
          <Form>
            <StyledCard p={4} flex={{ justify: 'flex-start', align: 'center', gap: 3 }}>
              <SwitchInput
                name="private"
                disabled={organization_role !== 'admin' && organization_role !== 'owner'}
              />
              <Div flex={{ align: 'center', gap: 1 }}>
                <Text semi>Private project</Text>
                <Tooltip tooltip="Private projects are accessible to organization admins and with members with project access.">
                  <Icon muted icon="info" />
                </Tooltip>
              </Div>
            </StyledCard>
            <SubmitOnchange />
          </Form>
        </Formik>
        {team?.private && (
          <>
            <Div m={{ b: 4, t: 14 }}>
              <Text bold size="l" as="h2" m={0}>
                Members with inherited access
              </Text>
              <Text muted>Organization owners and admins inherit access to all projects.</Text>
            </Div>
            <StyledCard>
              {inherited_members
                ?.sort((a, b) => (a.created_at > b.created_at ? -1 : 1))
                .filter((_, index) => (show_all_inherited ? true : index < 3))
                .map((member) => (
                  <StyledCardSection
                    p={{ x: 4, y: 3 }}
                    flex={{ align: 'center', gap: 4 }}
                    key={member.id}>
                    <Div w={50}>
                      <Text semi ellipsis>
                        {member.user_name}
                        {member.user_id === user!.id && ' (You)'}
                      </Text>
                      <Text muted ellipsis>
                        {member.user_email}
                      </Text>
                    </Div>
                    <Div w={50}>
                      <Button neutral disabled>
                        {capitalizeFirstLetter(member.role)}
                      </Button>
                    </Div>
                    <Div w={{ px: 32 }}></Div>
                  </StyledCardSection>
                ))}
              {inherited_members && inherited_members.length > 3 && (
                <StyledCardSection p={{ x: 4, y: 2 }}>
                  <Link
                    icon={show_all_inherited ? 'collapse_all' : 'expand_all'}
                    onClick={() => setShowAllInherited(!show_all_inherited)}>
                    {!show_all_inherited ? `View All ${inherited_members.length}` : 'Collapse'}
                  </Link>
                </StyledCardSection>
              )}
              {inherited_members && inherited_members.length === 0 && (
                <Text muted p={4}>
                  <Icon muted icon="info" left />
                  There are no inherited members with project access
                </Text>
              )}
            </StyledCard>
            <Div m={{ b: 4, t: 14 }} flex={{ justify: 'space-between', align: 'center' }}>
              <Div>
                <Text bold size="l" as="h2" m={0}>
                  Members with project access
                </Text>
                <Text muted>Organization members can create or be added to private projects.</Text>
              </Div>
              {!team?.directory_idp_id && (
                <WithTeamAdminRole>
                  <Button
                    to={(location) => ({
                      ...location,
                      pathname: `/settings/project/access/add`,
                      state: { scroll: false },
                    })}
                    primary
                    icon="add">
                    Add members
                  </Button>
                </WithTeamAdminRole>
              )}
            </Div>
            <StyledCard>
              {managed_members && managed_members.length === 0 && (
                <Text center muted p={6.5}>
                  There are no members with project access.
                </Text>
              )}
              {managed_members
                ?.sort((a, b) => (a.created_at > b.created_at ? -1 : 1))
                .map((member, i) => (
                  <StyledCardSection
                    p={{ x: 4, y: 3 }}
                    flex={{ align: 'center', gap: 4 }}
                    key={member.id}>
                    <Div w={50}>
                      <Text semi ellipsis>
                        {member.user_name}
                        {member.user_id === user!.id && ' (You)'}
                      </Text>
                      <Text muted ellipsis>
                        {member.user_email}
                      </Text>
                    </Div>
                    <Div w={50}>
                      {user!.id !== member.user_id &&
                      team_levels_by_role[team_role!] >= team_levels_by_role.admin ? (
                        <DropdownMenu
                          placement="bottom-start"
                          outline
                          w={{ px: 272 }}
                          label={`Project ${capitalizeFirstLetter(member.role)}`}
                          title="Roles"
                          options={createTeamRoleOptions(
                            !!subscription?.features?.includes('viewer_role'),
                          ).map((option) => ({
                            ...option,
                            onClick: () => changeRole(member.id as string, option.value),
                          }))}
                        />
                      ) : (
                        <Button neutral disabled>
                          {capitalizeFirstLetter(member.role)}
                        </Button>
                      )}
                    </Div>
                    <Div w={{ px: 32 }}>
                      {user!.id === member.user_id && !user!.workos_org_id && (
                        <Div flex={{ justify: 'flex-end' }}>
                          <DropdownMenu
                            placement="bottom-end"
                            minimal
                            icon="horizontal_more"
                            options={[
                              {
                                icon: 'leave',
                                label: 'Leave Project',
                                onClick: handleLeave,
                                danger: true,
                              },
                            ]}
                          />
                        </Div>
                      )}
                      {user!.id !== member.user_id && (
                        <WithTeamAdminRole>
                          <Button
                            invisible
                            icon="delete"
                            onClick={() => handleRemove(member.id as string, member.user_email)}
                          />
                        </WithTeamAdminRole>
                      )}
                    </Div>
                  </StyledCardSection>
                ))}
            </StyledCard>
          </>
        )}
      </Container>
    </>
  );
};

export default TeamAccessControl;
