import { IconName } from '../../../common/base/Icon';

export const issue_trigger_types = {
  delivery: { label: 'Delivery', icon: 'send' as IconName, key: 'delivery' },
  transformation: {
    label: 'Transformation',
    icon: 'transformation' as IconName,
    key: 'transformation',
  },
  backpressure: { label: 'Backpressure', icon: 'backpressure' as IconName, key: 'backpressure' },
};
