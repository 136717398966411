import { useContext, useState } from 'react';
import { useLocation } from 'react-router-dom';
import styled from 'styled-components';
import useSWR from 'swr';

import APIMethodKeys from '../../../../client/APIMethodKeys';
import LINKS from '../../../../configs/links';
import Button from '../../../common/base/Button';
import Icon from '../../../common/base/Icon';
import Loading from '../../../common/base/Loading';
import Text from '../../../common/base/Text';
import CopyableField from '../../../common/CopyableField';
import { Div } from '../../../common/helpers/StyledUtils';
import { useToasts } from '../../../common/Toast';
import { GlobalContext } from '../../../contexts/GlobalContext';

const StyledEmptyView = styled.div`
  flex-grow: 1;
  max-width: 480px;
  margin: 0 auto;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const RequestListEmptyView: React.FC = () => {
  const { HookdeckAPI } = useContext(GlobalContext);
  const [loading, setLoading] = useState(false);
  const location = useLocation();
  const { addToast } = useToasts();
  const { data: webhooks } = useSWR(APIMethodKeys.webhooks.list({ limit: 1 }), () =>
    HookdeckAPI.webhooks.list({ limit: 1 }),
  );
  const default_webhook = webhooks && webhooks.count > 0 ? webhooks?.models[0] : null;
  const { data: requests } = useSWR(APIMethodKeys.requests.list({ limit: 1 }), () =>
    HookdeckAPI.requests.list({ limit: 1 }),
  );

  const onSendTestEvent = () => {
    setLoading(true);

    HookdeckAPI.webhooks
      .test(default_webhook!.id)
      .then(() => {
        addToast('info', "Test event sent, hold on a second while it's processed!");
        setLoading(true);
      })
      .catch(() => setLoading(false));
  };

  if (!requests || !webhooks) {
    return (
      <StyledEmptyView>
        <Loading />
      </StyledEmptyView>
    );
  }

  if (default_webhook && requests.count === 0 && location.pathname.includes('/cli')) {
    return (
      <StyledEmptyView>
        <Div p={{ all: 7 }} flex={{ direction: 'column', align: 'center', justify: 'center' }}>
          <Text bold center as="p" size="xl" m={{ t: 4 }}>
            Work with events on your local server
          </Text>
          <Text as="p" center>
            Install the CLI to receive your events on your local server while developing or
            debugging your integration.{' '}
            {requests.count === 0 && 'Once connected send a request to your Hookdeck URL.'}
          </Text>
          {requests.count === 0 && <CopyableField value={default_webhook.source.url} />}
          <Button as="a" m={{ y: 6 }} target="_blank" href={LINKS.product_docs.cli}>
            Install
          </Button>
        </Div>
      </StyledEmptyView>
    );
  }

  if (default_webhook && requests.count === 0) {
    return (
      <StyledEmptyView>
        <Div p={{ all: 7 }} flex={{ direction: 'column', align: 'center', justify: 'center' }}>
          <Icon icon="loading" muted />
          <Text bold as="p" size="xl" m={{ t: 4 }}>
            Waiting for a request
          </Text>
          <Text center as="p">
            Update your source <strong>{default_webhook.source.name}</strong> with the provided
            Hookdeck url.
          </Text>
          <CopyableField mono value={default_webhook.source.url} />
          <Text size="s" muted m={{ y: 4 }}>
            – or –
          </Text>
          <Button invisible primary disabled={loading} onClick={onSendTestEvent}>
            Send a test request
          </Button>
        </Div>
      </StyledEmptyView>
    );
  }

  return (
    <StyledEmptyView>
      <Div p={{ y: 24 }} flex={{ direction: 'column', align: 'center', justify: 'center' }}>
        <Text as="p" semi muted m={0}>
          No requests match those filters...
        </Text>
      </Div>
    </StyledEmptyView>
  );
};

export default RequestListEmptyView;
