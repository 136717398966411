import { useMemo } from 'react';

import { APIList } from '../../../../../../../typings/API.interface';
import { EventAttempt } from '../../../../../../../typings/EventAttempt.interface';
import { numberWithCommas } from '../../../../utils';
import { ATTEMPT_TRIGGER_LABELS } from '../../../../utils/attempt';
import Text from '../../../common/base/Text';
import DisplayDate from '../../../common/DisplayDate';
import { Status } from '../../../common/Status';
import Table from '../../../common/Table';

export const EventAttempts = ({
  attempts,
  selected_attempt_id,
  onAttemptSelected,
}: {
  attempts: APIList<EventAttempt>;
  selected_attempt_id: string;
  onAttemptSelected: (attempt_id: string) => void;
}) => {
  const rows = useMemo(
    () =>
      attempts.models.map((attempt, i) => ({
        id: attempt.id,
        selected: attempt.id === selected_attempt_id,
        fields: [
          <Text key={`${attempt.id}-attempt`} size="s" muted mono>
            Attempt {attempts.models.length - i}
          </Text>,
          <DisplayDate key={`${attempt.id}-date`} date={attempt.created_at} />,
          <Status small key={`${attempt.id}-status`} {...attempt} />,
          <Text key={`${attempt.id}-trigger`} size="s" muted mono>
            {ATTEMPT_TRIGGER_LABELS[attempt.trigger]}
          </Text>,
          <Text key={`${attempt.id}-delivery`} size="s" muted mono>
            {!attempt.delivery_latency ? '-' : `${numberWithCommas(attempt.delivery_latency)}ms`}
          </Text>,
          <Text key={`${attempt.id}-response`} size="s" muted mono>
            {!attempt.response_latency ? '-' : `${numberWithCommas(attempt.response_latency)}ms`}
          </Text>,
        ],
      })),
    [attempts, selected_attempt_id],
  );
  return (
    attempts && (
      <Table
        headers={[
          `Attempt`,
          `Timestamp`,
          'Status',
          'Attempt trigger',
          'Delivery latency',
          'Response latency',
        ]}
        widths={[
          { min: 140, max: 140 },
          { min: 200, max: 200 },
          { min: 140, max: 140 },
          { min: 100 },
          { min: 128, max: 128 },
          { min: 172, max: 172 },
        ]}
        onRowSelected={onAttemptSelected}
        rows={rows}
      />
    )
  );
};
