import { sub } from 'date-fns';
import { useContext } from 'react';
import useSWR from 'swr';

import { Grid, GridUnit } from '@hookdeck/theme';

import APIMethodKeys from '../../../../../client/APIMethodKeys';
import { numberWithCommas } from '../../../../../utils';
import { showChat } from '../../../../../utils/liveChat';
import { isFreePlan } from '../../../../../utils/subscription';
import Badge from '../../../../common/base/Badge';
import Button from '../../../../common/base/Button';
import { StyledCard, StyledCardSection } from '../../../../common/base/Card';
import Container from '../../../../common/base/Container';
import Divider from '../../../../common/base/Divider';
import Icon from '../../../../common/base/Icon';
import Link from '../../../../common/base/Link';
import Text from '../../../../common/base/Text';
import LineChart from '../../../../common/Chart/LineChart';
import { Div } from '../../../../common/helpers/StyledUtils';
import { ChartData, useMetric } from '../../../../common/metrics/useMetric';
import { GlobalContext } from '../../../../contexts/GlobalContext';
import useLocalStorage from '../../../../hooks/useLocalStorage';
import useSearchQuery from '../../../../hooks/useSearchQuery';
import { DashboardContext } from '../../DashboardContext';
import ChangeThrouhgputModal from './ChangeThroughputModal';

const TeamQuotas: React.FC = () => {
  const { HookdeckAPI } = useContext(GlobalContext);
  const { subscription, team, organization } = useContext(DashboardContext);
  const { query } = useSearchQuery<{ view: 'quota.request' | 'throughput' }>();
  const [time_unit, setTimeUnit] = useLocalStorage('pref:quota:time_unit', '30d');

  const { data: subscription_details } = useSWR(
    team!.billing_meta.price_interval_id && APIMethodKeys.billing.getSubscriptionDetails(),

    () => HookdeckAPI.billing.getSubscriptionDetails(),
  );

  const throughput_price_interval = team!.billing_meta.price_interval_id
    ? subscription_details?.price_intervals.find(
        (pi) => pi.id === team?.billing_meta.price_interval_id,
      )
    : null;

  const throughput_price = team!.billing_meta.price_interval_id
    ? throughput_price_interval && throughput_price_interval!.price!.unit_config!.unit_amount
    : 0;

  const quota_sections = [
    {
      title: `Plan limits`,
      quotas: [
        {
          title: 'Events limit',
          desc: 'Maximum number of events across all projects that Hookdeck will process each month.',
          value: subscription!.max_requests
            ? numberWithCommas(subscription!.max_requests)
            : 'Unlimited',
        },
      ],
      desc: 'Upgrade to increase your plan limits.',
      action: (
        <Button neutral icon="upgrade" to="/settings/organization/plans?highlight=events">
          Upgrade plan
        </Button>
      ),
    },
    {
      title: 'Platform limits',
      quotas: [
        {
          title: 'API rate limit',
          desc: 'Requests to the admin API in excess of this rate will receive an HTTP 429 error response.',
          value: `${subscription!.api_rate_limit} req/m`,
        },
        {
          title: 'Webhook request payload size',
          desc: 'Maximum payload size processed by Hookdeck.',
          value: `${subscription!.max_payload_size_mb} MiB`,
        },
        {
          title: 'Delivery timeout',
          desc: 'Maximum amount of time before a attempt request times out.',
          value: `${subscription!.attempt_timeout} seconds`,
        },
      ],
      desc: 'Contact us to increase your platform limits.',
      action: (
        <Button neutral icon="chat" onClick={showChat}>
          Contact us
        </Button>
      ),
    },
  ];

  const chart_props = {
    time_options: {
      time_unit,
      time_units: [
        { key: '1h', label: 'Past 1 hour' },
        { key: '6h', label: 'Past 6 hours' },
        { key: '1d', label: 'Past 24 hours' },
        { key: '7d', label: 'Past 7 days' },
        { key: '14d', label: 'Past 14 days' },
        { key: '30d', label: 'Past 30 days' },
      ],
      onTimeUnitSelected: setTimeUnit,
    },
  };

  const max_events = useMetric<ChartData>('chart:line', 'events_max_rate', 0, {
    date_range: {
      min: sub(
        new Date(),
        time_unit.indexOf('h') > 0
          ? { hours: Number(time_unit.replace('h', '')) }
          : { days: Number(time_unit.replace('d', '')) },
      ).toISOString(),
      max: new Date().toISOString(),
    },
  });

  let events_above_threshold = false;
  if (max_events.data && max_events.data.data) {
    const max_event = Math.max(...max_events.data.data.map((d) => d.y as number));
    if (max_event > team!.max_events_per_second) {
      events_above_threshold = true;
    }
  }

  return (
    <Container xlarge left m={{ b: 14 }}>
      <Div m={{ b: 14 }}>
        <Text bold size="l" as="h2" m={{ t: 0, b: 4 }}>
          Project throughput
        </Text>
        <StyledCard danger={events_above_threshold}>
          <StyledCardSection
            p={{ y: 3, x: 4 }}
            flex={{ justify: 'space-between', align: 'center' }}>
            <Div>
              <Text size="s" semi muted>
                Current throughput rate
              </Text>
              <Text bold flex>
                {team!.max_events_per_second} events per second
                {throughput_price !== undefined && (
                  <Text muted m={{ l: 1 }}>
                    / ${Number(throughput_price)} per month
                  </Text>
                )}
              </Text>
            </Div>
            <Button
              disabled={
                isFreePlan(subscription!.plan) ||
                !!subscription?.cancel_at ||
                organization?.feature_flags.disable_throughput_change
              }
              neutral
              icon="sort"
              to="/settings/project/quotas?view=throughput">
              Change throughput
            </Button>
          </StyledCardSection>
          {(isFreePlan(subscription!.plan) || subscription?.cancel_at) && (
            <StyledCardSection p={{ y: 3, x: 4 }} flex={{ justify: 'space-between' }}>
              <Div flex={{ align: 'center' }}>
                <Icon muted icon="info" left />
                <Text>Upgrade your Organization’s plan to enable throughput modification.</Text>
              </Div>
              <Link to="/settings/organization/plans?highlight=starter" icon="arrow_forward">
                Upgrade plan
              </Link>
            </StyledCardSection>
          )}
          {organization!.feature_flags?.disable_throughput_change && (
            <StyledCardSection p={{ y: 3, x: 4 }} flex={{ justify: 'space-between' }}>
              <Div flex={{ align: 'center' }}>
                <Icon muted icon="info" left />
                <Text>Contact us to change your project throughput.</Text>
              </Div>
              <Link onClick={showChat} icon="arrow_forward">
                Contact us
              </Link>
            </StyledCardSection>
          )}
          {events_above_threshold && (
            <StyledCardSection p={{ y: 3, x: 4 }} danger flex={{ align: 'center' }}>
              <Icon icon="error" danger left />
              <Text>
                Your project is processing events above your throughput threshold, increasing
                delivery latency.
              </Text>
            </StyledCardSection>
          )}
        </StyledCard>
        <StyledCard m={{ t: 6 }}>
          <LineChart
            title="Throughput over time"
            legend_placeholder={'Max new events per second'}
            height={240}
            {...chart_props}
            loading={!max_events}
            datasets={
              max_events && max_events.data
                ? [
                    {
                      key: 'delivered',
                      label: 'Max new events per second',
                      theme_color: 'primary' as const,
                      mono: false,
                      data: max_events.data.data,
                      total: max_events.data.total,
                      highest: max_events.data.highest,
                      average: max_events.data.average,
                      getDataLabel: (v: number) => `${v} / second`,
                      metric_type: 'max' as const,
                    },
                    {
                      key: 'limit',
                      label: 'Throughput limit',
                      theme_color: 'danger' as const,
                      mono: false,
                      dashed: true,
                      data: max_events.data.data.map((d) => ({
                        ...d,
                        y: team!.max_events_per_second,
                      })),
                      // Placeholder values not actually used for anything
                      total: 0,
                      highest: 0,
                      average: 0,
                      getDataLabel: (v: number) => `${v} / second`,
                      metric_type: 'rate' as const,
                    },
                  ]
                : []
            }
          />
        </StyledCard>
      </Div>
      {query.view === 'throughput' && <ChangeThrouhgputModal />}
      {quota_sections.map((section) => (
        <Div m={{ b: 14 }} key={section.title}>
          <Div m={{ b: 4 }} flex={{ justify: 'space-between', align: 'center' }}>
            <Div>
              <Text bold size="l">
                {section.title}
              </Text>
              <Text muted>{section.desc}</Text>
            </Div>
            {section.action}
          </Div>
          <StyledCard m={{ b: 4 }}>
            <StyledCardSection muted>
              <Grid>
                <GridUnit size={3 / 4}>
                  <Div flex={{ justify: 'space-between' }}>
                    <Text muted semi size="s" p={{ x: 2, y: 1 }}>
                      Type
                    </Text>
                    <Divider vertical />
                  </Div>
                </GridUnit>
                <GridUnit size={1 / 4}>
                  <Text muted semi size="s" p={{ x: 2, y: 1 }}>
                    Rate
                  </Text>
                </GridUnit>
              </Grid>
            </StyledCardSection>
            {section.quotas.map((quota, i) => (
              <StyledCardSection key={i}>
                <Grid>
                  <GridUnit size={3 / 4}>
                    <Div flex={{ justify: 'space-between' }}>
                      <Div p={{ x: 2, y: 2 }}>
                        <Text semi size="s">
                          {quota.title}
                        </Text>
                        <Text muted size="s">
                          {quota.desc}
                        </Text>
                      </Div>
                      <Divider vertical />
                    </Div>
                  </GridUnit>
                  <GridUnit size={1 / 4}>
                    <Div p={{ x: 2, y: 2 }}>
                      <Badge neutral>{quota.value}</Badge>
                    </Div>
                  </GridUnit>
                </Grid>
              </StyledCardSection>
            ))}
          </StyledCard>
        </Div>
      ))}
    </Container>
  );
};

export default TeamQuotas;
