import { Form, Formik } from 'formik';
import { useContext } from 'react';
import { useHistory, useRouteMatch } from 'react-router';
import slugify from 'slugify';
import styled from 'styled-components';
import useSWR from 'swr';

import { ManagedTokenIntegrationConfigs } from '../../../../../../../typings/Integration.interface';
import APIMethodKeys from '../../../../client/APIMethodKeys';
import field_formats from '../../../../utils/field-formatters';
import Button from '../../../common/base/Button';
import { StyledCard, StyledCardSection } from '../../../common/base/Card';
import Container from '../../../common/base/Container';
import Icon from '../../../common/base/Icon';
import Text from '../../../common/base/Text';
import CopyableField from '../../../common/CopyableField';
import TextInput from '../../../common/Form/Fields/TextInput';
import { Div } from '../../../common/helpers/StyledUtils';
import { GlobalContext } from '../../../contexts/GlobalContext';
import useSearchQuery from '../../../hooks/useSearchQuery';
import FullscreenLayout from '../../../layouts/FullscreenLayout';
import resource_details_form_props from '../Connections/Forms/resource_details';
import OnboardingNav from '../Onboarding/OnboardingNav';

const StyledCircleIcon = styled(Div)`
  width: 44px;
  height: 44px;
  border-radius: 50%;
  border: 1px solid ${({ theme }) => theme.colors.outline.neutral};
  box-shadow: ${({ theme }) => theme.elevation[1]};
  display: flex;
  align-items: center;
  justify-content: center;
`;

const ConnectView: React.FC = () => {
  const { HookdeckAPI } = useContext(GlobalContext);

  const { query } = useSearchQuery<{ provider?: string }>();
  const match = useRouteMatch<{ source_id?: string }>();
  const history = useHistory();

  const { data: source } = useSWR(
    match.params.source_id
      ? APIMethodKeys.sources.get(match.params.source_id, { include: 'config.auth' })
      : null,
    () => HookdeckAPI.sources.get(match.params.source_id!, { include: 'config.auth' }),
  );

  const onSubmit = (values: { name: string }) => {
    HookdeckAPI.sources
      .create({
        name: values.name,
        type: 'MANAGED',
        config: {},
      })
      .then((source) => {
        history.push(`/connect/${source.id}`);
      });
  };

  return (
    <FullscreenLayout center>
      <OnboardingNav />
      <Container flex={{ justify: 'center', align: 'center', direction: 'column' }}>
        <Formik
          initialValues={{ name: query.provider ? slugify(query.provider) : '' }}
          validate={(values) =>
            resource_details_form_props.validate(
              values,
              (name) => HookdeckAPI.sources.nameIsUsed(name),
              false,
            )
          }
          onSubmit={onSubmit}>
          <Form>
            <StyledCard raised overflow_hidden style={{ width: '380px' }}>
              <StyledCardSection p={3}>
                <Div flex={{ align: 'center', justify: 'center', gap: 4 }} m={{ t: 6, b: 4 }}>
                  <StyledCircleIcon>
                    <Icon icon="source" />
                  </StyledCircleIcon>
                  <Icon icon="swap" />
                  <StyledCircleIcon>
                    <svg
                      width="20"
                      height="15"
                      viewBox="0 0 20 15"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg">
                      <path
                        d="M15.1604 5.1431C15.2682 5.1431 15.3761 5.22707 15.4301 5.33903C15.484 5.45099 15.457 5.59094 15.3761 5.67491L8.63376 12.6724C6.77287 14.6037 3.72531 14.6037 1.86442 12.6724C0.00352657 10.7411 0.00352657 7.57821 1.86442 5.64692L5.74802 1.61638C6.85377 0.468799 8.36406 0.020962 9.79344 0.216891C9.90132 0.244881 9.98223 0.300859 10.0362 0.412819C10.0631 0.524778 10.0362 0.636739 9.95526 0.720709L9.09224 1.61638C8.95739 1.75633 8.79558 1.8403 8.63376 1.86829C7.98649 1.98025 7.3662 2.28814 6.88074 2.79195L2.99714 6.82249C1.75654 8.11002 1.75654 10.2093 2.99714 11.4968C4.23773 12.7843 6.26044 12.7843 7.50104 11.4968L12.3286 6.48661L11.5465 5.67491C11.4656 5.59094 11.4386 5.45099 11.4925 5.33903C11.5465 5.22707 11.6543 5.1431 11.7622 5.1431H15.1604ZM8.20225 9.20163C8.31013 9.20163 8.418 9.11766 8.47194 9.0057C8.52588 8.89374 8.49891 8.75379 8.418 8.66982L7.63589 7.85812L12.4634 2.84793C13.704 1.5604 15.7267 1.5604 16.9673 2.84793C18.2079 4.13547 18.2079 6.23471 16.9673 7.52224L13.0568 11.5528C12.5713 12.0566 11.951 12.3645 11.3037 12.4764C11.115 12.5044 10.9531 12.5884 10.8453 12.7283L9.98223 13.624C9.90133 13.708 9.87435 13.8199 9.90132 13.9319C9.92829 14.0439 10.0362 14.1278 10.1441 14.1278C11.5734 14.3238 13.0837 13.8759 14.1895 12.7283L18.0731 8.69781C19.934 6.76651 19.934 3.60366 18.0731 1.67236C16.2122 -0.258937 13.1646 -0.258937 11.3037 1.67236L4.56137 8.66982C4.48046 8.75379 4.45349 8.89374 4.50743 9.0057C4.56137 9.11766 4.66925 9.20163 4.77712 9.20163H8.20225Z"
                        fill="#0044CC"
                      />
                    </svg>
                  </StyledCircleIcon>
                </Div>
                <Text bold size="l" center>
                  Create {query.provider ? query.provider : 'Managed'} Source
                </Text>
                {source ? (
                  <>
                    <Text size="m" muted m={{ b: 6 }} center>
                      Provide this token to {query.provider ? query.provider : 'the event producer'}{' '}
                      to authenticate it
                    </Text>
                    <Text size="s" semi m={{ b: 1 }}>
                      Source Token
                    </Text>
                    {source ? (
                      <CopyableField
                        value={(source.config?.auth as ManagedTokenIntegrationConfigs)?.token}
                        secret
                      />
                    ) : null}
                  </>
                ) : (
                  <>
                    <Text size="m" muted m={{ b: 6 }} center>
                      {query.provider
                        ? `${query.provider.toUpperCase()} will have permission to:`
                        : `The event producer will have permission to:`}
                    </Text>
                    <StyledCard m={{ b: 4 }}>
                      <StyledCardSection flex={{ gap: 2, align: 'center' }} p={3}>
                        <Icon icon="source" />
                        <Text size="m">Manage this source by update or deleting it.</Text>
                      </StyledCardSection>
                      <StyledCardSection flex={{ gap: 2, align: 'center' }} p={3}>
                        <Icon icon="source" />
                        <Text size="m">Publish events to this source.</Text>
                      </StyledCardSection>
                      <StyledCardSection flex={{ gap: 2, align: 'center' }} p={3}>
                        <Icon icon="source" />
                        <Text size="m">Retrieve requests associated to this source.</Text>
                      </StyledCardSection>
                    </StyledCard>
                    <TextInput
                      name="name"
                      label="Source Name"
                      format={field_formats.slugify}
                      required
                      m={{ b: 0 }}
                    />
                  </>
                )}
              </StyledCardSection>
              <StyledCardSection flex={{ justify: 'flex-end', gap: 3 }} p={3}>
                {source ? (
                  <Button to={`/connections/new?source_id=${source.id}`}>Create Connection</Button>
                ) : (
                  <Button primary submit>
                    Allow
                  </Button>
                )}
              </StyledCardSection>
            </StyledCard>
          </Form>
        </Formik>
      </Container>
    </FullscreenLayout>
  );
};

export default ConnectView;
