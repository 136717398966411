import { useField, useFormikContext } from 'formik';
import React, { forwardRef } from 'react';
import styled from 'styled-components';

import useComponentDefaultProps from '../../../hooks/useComponentDefaultProps';
import Link from '../../base/Link';
import Input, { InputBaseProps } from './Input/Input';

export const time_input_steps_by_format = {
  hh: '3600',
  'hh:mm': '60',
  'hh:mm:ss': '1',
};

export interface TimeInputProps
  extends Omit<React.ComponentPropsWithoutRef<'input'>, 'onChange' | 'value' | 'defaultValue'>,
    Partial<InputBaseProps> {
  format?: keyof typeof time_input_steps_by_format;
  reset_value?: string;
  onChange?(value: string): void;
  onReset?: () => void;
  validate?: (string) => Promise<string | void> | string | void;
}

const defaultProps: Partial<TimeInputProps> = {
  format: 'hh:mm:ss',
  reset_value: '',
};

const StyledClearIcon = styled(Link)``;
const StyledInputWrapper = styled.div`
  position: relative;

  input[type='time'] {
    // hack because time input is different from browser to browser
    max-height: 42px;
    padding-right: ${({ theme }) => theme.spacing(7)};
    box-shadow: ${({ theme }) => theme.shadows.input.neutral};
    border: 0;

    &::-webkit-calendar-picker-indicator {
      background: none;
      display: none;
    }

    &::-webkit-clear-button {
      display: none;
    }
  }

  // hack to hide the default clear button on firefox
  &::after {
    content: '';
    width: 16px;
    height: 16px;
    position: absolute;
    right: 0;
    top: 50%;
    transform: translate(0, -50%);
    background-color: ${({ theme }) => theme.colors.surface.base.surface};
    margin-right: ${({ theme }) => theme.spacing(3)};
  }

  ${StyledClearIcon} {
    position: absolute;
    z-index: 1;
    right: 0;
    top: 50%;
    transform: translate(0, -50%);
    margin-right: ${({ theme }) => theme.spacing(3)};
  }
`;

export const TimeInput = forwardRef<HTMLInputElement, TimeInputProps>((props, ref) => {
  const {
    name,
    label,
    label_size,
    required,
    disabled,
    format,
    reset_value,
    onChange,
    onReset,
    validate,
    ...others
  } = useComponentDefaultProps(defaultProps, props);

  const { submitCount } = useFormikContext();
  const [field, { value, error, touched }, { setValue, setTouched }] = useField(
    validate ? { name: name || '', validate } : { name: name || '' },
  );

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    field.onChange(e);
    if (onChange) {
      onChange(e.target.value);
    }
  };

  const handleReset = () => {
    setValue(reset_value);
    if (onReset) {
      onReset();
    }
  };

  const step = format && time_input_steps_by_format[format];

  return (
    <Input
      id={name}
      label={label}
      label_size={label_size}
      required={required}
      error={((touched || submitCount > 0) && error) ?? error}>
      <StyledInputWrapper>
        <input
          {...field}
          required={required}
          disabled={disabled}
          type={'time'}
          step={step}
          id={name}
          value={field.value ?? ''}
          onChange={(e) => handleChange(e)}
          ref={ref}
          onBlur={() => {
            setTouched(true);
          }}
          {...others}
        />
        {value && !required && value !== reset_value && (
          <StyledClearIcon dark icon="cancel" onClick={handleReset} />
        )}
      </StyledInputWrapper>
    </Input>
  );
});

export default TimeInput;
