import Ansi from 'ansi-to-react';
import { useCallback, useState } from 'react';
import styled, { css } from 'styled-components';

import { ConsoleLine } from '../../../../../../../../../typings/TransformationExecution.interface';
import Button from '../../../../../common/base/Button';
import Divider from '../../../../../common/base/Divider';
import Text from '../../../../../common/base/Text';
import Tooltip from '../../../../../common/base/Tooltip';
import { Div } from '../../../../../common/helpers/StyledUtils';

interface ConsoleProps {
  lines: ConsoleLine[];
  collapseable?: boolean;
  border_top?: boolean;
  onClear?: () => void;
  onToggleCollapse?: (collapsed: boolean) => void;
}

const StyledConsole = styled.div<{ collapsed: boolean; border_top: boolean }>(
  ({ theme, collapsed, border_top }) => css`
    width: 100%;
    max-height: ${collapsed ? 'none' : '320px'};
    background-color: ${theme.colors.surface.base.surface};

    ${border_top &&
    css`
      border-top: ${theme.border};
    `}
  `,
);

const StyledConsoleScrollableContainer = styled.div(
  ({ theme }) => css`
    padding: ${theme.spacing(4)};
    flex-grow: 1;
    overflow-y: auto;
    height: 250px;
    background-color: ${theme.colors.surface.base.variant_surface_2};

    p:last-of-type {
      margin-bottom: 0 !important;
    }

    code {
      color: ${theme.colors.on.neutral.primary_neutral};
      font-weight: 400;
      background: transparent;
    }
  `,
);

const Console = ({ lines, collapseable, border_top, onClear, onToggleCollapse }: ConsoleProps) => {
  const [collapsed, setCollapsed] = useState(false);

  const toggleCollapsed = useCallback(() => {
    setCollapsed(!collapsed);
    onToggleCollapse && onToggleCollapse(!collapsed);
  }, [collapsed]);

  return (
    <StyledConsole collapsed={collapsed} border_top={border_top ?? false}>
      <Div flex={{ justify: 'space-between', align: 'center' }} p={{ x: 4, y: 3 }}>
        <Text semi>Console</Text>
        {(collapseable || onClear) && (
          <Div flex={{ gap: 2 }}>
            {onClear && (
              <Tooltip tooltip="Clear Console" align="right">
                <Button small onClick={onClear} invisible icon="clear">
                  Clear Console
                </Button>
              </Tooltip>
            )}
            {collapseable && (
              <Button
                small
                onClick={toggleCollapsed}
                invisible
                icon={collapsed ? 'expand_less' : 'expand_more'}
              />
            )}
          </Div>
        )}
      </Div>
      {!collapsed && (
        <>
          <Divider />
          <StyledConsoleScrollableContainer>
            {lines.length === 0 && (
              <Text mono as="p" m={0} muted>
                No output yet.
              </Text>
            )}
            {lines.map((line, i) => (
              <Div m={{ b: 2 }} key={i}>
                <Text
                  mono
                  as="span"
                  danger={line.type === 'error'}
                  warning={line.type === 'warn'}
                  primary={line.type === 'log'}
                  semi>
                  {line.type.toUpperCase()}
                </Text>
                <Ansi>{line.message}</Ansi>
              </Div>
            ))}
          </StyledConsoleScrollableContainer>
        </>
      )}
    </StyledConsole>
  );
};
export default Console;
