import { useContext, useMemo } from 'react';
import useSWR from 'swr';

import { TransformationExecution } from '../../../../../../../typings/TransformationExecution.interface';
import APIMethodKeys from '../../../../client/APIMethodKeys';
import { TransformationExecutionsFiltersProps } from '../../../../typings/TransformationExecution.interface';
import Badge from '../../../common/base/Badge';
import Button from '../../../common/base/Button';
import LabelButton from '../../../common/base/LabelButton';
import Text from '../../../common/base/Text';
import Tooltip from '../../../common/base/Tooltip';
import DisplayDate from '../../../common/DisplayDate';
import { Div } from '../../../common/helpers/StyledUtils';
import Table from '../../../common/Table';
import { GlobalContext } from '../../../contexts/GlobalContext';
import useTransformationExecutionsList from '../../../hooks/useTransformationExecutionsList';

const TransformationExecutionsList: React.FC<{
  transformation_id: string;
  selected_execution_id?: string;
  filters: TransformationExecutionsFiltersProps;
  onPaginationChanged?(filters: object): void;
  onExecutionSelected(execution_id: string): void;
  onEdit(exeuction: TransformationExecution): void;
  fill?: boolean;
}> = ({
  transformation_id,
  selected_execution_id,
  onEdit,
  onExecutionSelected,
  onPaginationChanged,
  filters,
  fill,
}) => {
  const { HookdeckAPI } = useContext(GlobalContext);

  const { executions, fetched, count, has_next_results, refresh, next, prev } =
    useTransformationExecutionsList(transformation_id, filters);
  const unique_webhook_ids = useMemo(
    () =>
      executions ? Array.from(new Set(executions.map((event) => event.webhook_id))).sort() : [],
    [executions],
  );
  const { data: webhooks } = useSWR(
    APIMethodKeys.webhooks.list(
      unique_webhook_ids.length > 0 ? { id: unique_webhook_ids } : { limit: 1 },
    ),
    () =>
      HookdeckAPI.webhooks.list(
        unique_webhook_ids.length > 0 ? { id: unique_webhook_ids } : { limit: 1 },
      ),
  );

  const loading = !fetched || !webhooks;
  const webhooks_by_id =
    webhooks &&
    webhooks.models.reduce((object, webhook) => ({ ...object, [webhook.id]: webhook }), {});

  const headers = ['Executed At', 'Log Level', 'Connection', 'Action'];
  const widths = [{ min: 152, max: 152 }, { min: 80, max: 80 }, { min: 100 }, { min: 64, max: 64 }];
  const rows = loading
    ? []
    : executions.reduce((rows, log) => {
        return [
          ...rows,
          {
            id: log.id,
            selected: selected_execution_id === log.id,
            highlighted: selected_execution_id === log.id,
            fields: [
              <DisplayDate key={log.id} date={log.created_at} />,
              <Badge
                key={log.id}
                danger={log.log_level === 'fatal' || log.log_level === 'error'}
                warning={log.log_level === 'warn'}
                primary={log.log_level === 'info' || log.log_level === 'debug'}
                success={!log.log_level}
                subtle>
                {log.log_level?.toUpperCase() || 'OK'}
              </Badge>,
              !webhooks_by_id || !webhooks_by_id?.[log.webhook_id] ? (
                <Text size="s" muted>
                  Connection Deleted
                </Text>
              ) : (
                <LabelButton
                  to={`/connections/${log.webhook_id}`}
                  neutral
                  mono
                  label={webhooks_by_id[log.webhook_id].full_name}
                />
              ),
              <Div key={log.id} flex={{ align: 'center', justify: 'flex-end' }} m={{ y: -1.5 }}>
                <Tooltip key={log.id} align="right" tooltip="Edit with this request as input">
                  <Button
                    invisible
                    small
                    icon="edit"
                    onClick={(e) => {
                      e.stopPropagation();
                      onEdit(log);
                    }}>
                    Edit
                  </Button>
                </Tooltip>
              </Div>,
            ],
          },
        ];
      }, []);
  return (
    <Table
      fill={fill}
      headers={headers}
      has_new_results={has_next_results}
      onLoadNewResults={refresh}
      widths={widths}
      rows={rows}
      loading={loading}
      onRowSelected={onExecutionSelected}
      onNextPage={
        onPaginationChanged && next
          ? () => onPaginationChanged({ next, prev: undefined })
          : undefined
      }
      onPreviousPage={
        onPaginationChanged && prev
          ? () => onPaginationChanged({ prev, next: undefined })
          : undefined
      }
      current_count={count}
    />
  );
};

export default TransformationExecutionsList;
