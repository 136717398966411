import { useContext, useState } from 'react';
import useSWR from 'swr';
import { Event } from '../../../../../../../typings/Event.interface';
import { EventAttempt } from '../../../../../../../typings/EventAttempt.interface';

import { DeliveryIssueWithData } from '../../../../../../../typings/Issue.interface';
import APIMethodKeys from '../../../../client/APIMethodKeys';
import { EventListFiltersProps } from '../../../../typings/EventList.interface';
import { stringifyQuery } from '../../../../utils';
import { RelativeDate } from '../../../../utils/date';
import { StyledCard, StyledCardSection } from '../../../common/base/Card';
import Link from '../../../common/base/Link';
import Text from '../../../common/base/Text';
import BulkRetryProgress from '../../../common/BulkRetryDropdown/BulkRetryProgress';
import { Div } from '../../../common/helpers/StyledUtils';
import RequestPayload from '../../../common/Request/RequestBody';
import { GlobalContext } from '../../../contexts/GlobalContext';
import { DashboardContext } from '../DashboardContext';
import Histogram from '../Events/Histogram';
import Switch from '../../../common/Form/Fields/Switch';
import FullRequestData from '../../../common/Request/FullRequestData';

const DeliveryIssue: React.FC<{
  issue: DeliveryIssueWithData;
  setDate: (query_date: object) => void;
  start_date: Date;
  end_date: Date;
  relative_date?: RelativeDate;
  refresh_key: string;
}> = ({ issue, start_date, end_date, setDate, relative_date, refresh_key }) => {
  const { HookdeckAPI } = useContext(GlobalContext);
  const { subscription, organization } = useContext(DashboardContext);

  const [currently_matching_only, setMatchingOnly] = useState(false);

  const trigger_event = issue.data.trigger_event ?? ({} as Event);
  const trigger_attempt = issue.data.trigger_attempt ?? ({} as EventAttempt);

  let filters = {
    issue_id: issue.id,
    date: {
      relative: relative_date,
      min: start_date.toISOString(),
      max: end_date.toISOString(),
    },
  } as EventListFiltersProps;

  if (currently_matching_only) {
    filters = {
      ...issue.aggregation_keys,
      response_status: issue.aggregation_keys.response_status.map((status) => `${status}`),
      ...filters,
    };
  }

  const { data: current_bulk_retries } = useSWR(
    APIMethodKeys.bulk.events.list({
      query: { issue_id: issue.id },
      query_partial_match: true,
      in_progress: true,
    }),
    () =>
      HookdeckAPI.bulk.events.list({
        query: { issue_id: issue.id },
        query_partial_match: true,
        in_progress: true,
      }),
    { refreshInterval: 5000 },
  );

  const current_bulk_retry =
    current_bulk_retries?.models &&
    current_bulk_retries.models.length > 0 &&
    current_bulk_retries.models[0];

  return (
    <>
      {current_bulk_retry && (
        <StyledCard m={{ b: 4 }}>
          <StyledCardSection p={4}>
            <Text>Bulk retry in progress</Text>
          </StyledCardSection>
          <StyledCardSection p={4}>
            <BulkRetryProgress model="events" bulk_retry={current_bulk_retry} />
          </StyledCardSection>
        </StyledCard>
      )}
      <StyledCard>
        <StyledCardSection>
          <Histogram
            model="Events"
            dimention={'status'}
            filters={filters}
            start_date={start_date}
            end_date={end_date}
            setDate={setDate}
            allow_reset={false}
            refresh_key={refresh_key}
          />
        </StyledCardSection>
        <StyledCardSection p={4} flex={{ justify: 'space-between', align: 'center' }}>
          <Div flex={{ gap: 2 }}>
            <Switch
              checked={currently_matching_only}
              onChange={() => setMatchingOnly(!currently_matching_only)}
            />
            <Text>Only display failed events still matching the issue</Text>
          </Div>
          <Link
            to={`/events?${stringifyQuery({
              issue_id: issue.id,
              ...(currently_matching_only
                ? {
                    response: {
                      response_status:
                        issue.aggregation_keys.response_status?.length === 1
                          ? {
                              // Because of min/max we can't pass an array this is a problem once we add merging
                              min: issue.aggregation_keys.response_status[0],
                              max: issue.aggregation_keys.response_status[0],
                            }
                          : undefined,
                      error_code:
                        issue.aggregation_keys.response_status?.length > 0
                          ? issue.aggregation_keys.error_code
                          : undefined,
                    },
                  }
                : {}),
            })}`}>
            {'View Events ->'}
          </Link>
        </StyledCardSection>
      </StyledCard>
      <Text size="l" bold m={{ b: 4, t: 14 }}>
        Sample Attempt Response
      </Text>
      <RequestPayload label="Body" body={trigger_attempt.body ?? {}} HookdeckAPI={HookdeckAPI} />
      <Text size="l" bold m={{ b: 4, t: 14 }}>
        Sample Event Data
      </Text>
      {trigger_event.data ? (
        <FullRequestData
          data={trigger_event.data}
          compact={false}
          type="event"
          id={trigger_event.id}
        />
      ) : (
        <>
          <Text as="p">
            This event is past your archival period of {subscription!.retention_days} days, the
            request data is no longer available.
          </Text>
          {!organization && (
            <Link to="/settings/organization/plans?highlight=retention_days" icon="upgrade">
              Upgrade Plan
            </Link>
          )}
        </>
      )}
    </>
  );
};

export default DeliveryIssue;
