import { FormEvent, useContext } from 'react';

import APIMethodKeys from '../../../../client/APIMethodKeys';
import { GlobalContext } from '../../../contexts/GlobalContext';
import MultiSelectInput from '../../Form/Fields/MultiSelectInput';
import { Div } from '../../helpers/StyledUtils';

const ResourcesFilter: React.FC<{
  api_root: 'sources' | 'webhooks' | 'destinations';
  name: string;
  delete_button?: JSX.Element;
  onSubmit: (e?: FormEvent<HTMLFormElement>) => void;
}> = ({ api_root, name, delete_button, onSubmit }) => {
  const { HookdeckAPI } = useContext(GlobalContext);

  const getLabel = (model) => {
    if (api_root === 'webhooks') {
      return model.full_name;
    }
    return model.name;
  };

  const formatOptions = (data) =>
    data && data.models.map((model) => ({ label: getLabel(model), value: model.id }));

  const formatFilters = (search_term?: string, values?: string[]) => {
    if (api_root === 'webhooks') {
      return { limit: 250, full_name: search_term || undefined, id: values };
    }
    return {
      limit: 250,
      name: search_term ? { contains: search_term } : undefined,
      id: values,
    };
  };

  return (
    <Div flex={{ align: 'center' }}>
      <MultiSelectInput.Permission
        m={0}
        initial_options={[]}
        search_placeholder={`Filter by ${api_root === 'webhooks' ? 'connection' : api_root}`}
        formatFilters={formatFilters}
        fetcher={HookdeckAPI[api_root].list}
        fetcherKey={APIMethodKeys[api_root].list}
        formatOptions={formatOptions}
        name={name}
        onClose={onSubmit}
        onRemove={onSubmit}
      />
      {delete_button && delete_button}
    </Div>
  );
};

export default ResourcesFilter;
