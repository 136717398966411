import { addHours, startOfHour, sub, subDays } from 'date-fns';
import { useContext, useMemo } from 'react';

import { APIWebhook } from '../../../../../../../../typings/Webhook.interface';
import formatCubeQuery from '../../../../../utils/formatCubeQuery';
import Container from '../../../../common/base/Container';
import Icon from '../../../../common/base/Icon';
import Text from '../../../../common/base/Text';
import { Div } from '../../../../common/helpers/StyledUtils';
import { useCubeQueryLocalRawData } from '../../../../hooks/useCubeQueryLocalRawData';
import { ResourcesContext } from '../ResourcesContext';
import GroupedConnections from './GroupedConnections';

const group_metrics_by_2h = {
  samples: 12,
  samples_granularity: 'hours',
  samples_length: 2,
};

const group_metrics_by_4h = {
  samples: 6,
  samples_granularity: 'hours',
  samples_length: 4,
};

const formatRawDataToGranularity = (
  connections: APIWebhook[],
  raw_data: any[],
  sample_config: typeof group_metrics_by_2h,
  now: Date,
) => {
  const data = [
    ...(raw_data
      ? raw_data.map((entry) => ({
          created_at: entry['Events.createdAt'],
          count: entry['Events.count'],
          status: entry['Events.status'],
          connection_id: entry['Events.webhookId'],
        }))
      : []),
  ];
  return connections.reduce((obj, { id }) => {
    obj[id] = Array.from(Array(sample_config.samples).keys())
      .reduce((arr: any, i) => {
        const step_start = sub(now, {
          hours: i * sample_config.samples_length,
        });
        const step_end = sub(step_start, {
          hours: sample_config.samples_length,
        });

        const entries = data.filter(
          (entry) =>
            step_end.getTime() < new Date(entry.created_at).getTime() &&
            step_start.getTime() >= new Date(entry.created_at).getTime() &&
            entry.connection_id === id,
        );

        const entry = entries.reduce(
          (rollup, data) => {
            if (!rollup.values[data.status]) {
              rollup.values[data.status] = 0;
            }
            rollup.values[data.status] += parseInt(data.count);
            return rollup;
          },
          {
            values: {},
          },
        );

        arr.push([[step_start, step_end], entry.values]);

        return arr;
      }, [])
      .reverse();
    return obj;
  }, {});
};

const ConnectionsList = ({ filtering }: { filtering: boolean }) => {
  const { webhooks: connections, search_term, group_by } = useContext(ResourcesContext);

  const now = useMemo(() => addHours(startOfHour(new Date()), 1), []);

  const stats_chart_query = useMemo(() => {
    return (
      connections &&
      formatCubeQuery('Events', {
        filters: {
          date: {
            min: subDays(now, 1).toISOString(),
            max: now.toISOString(),
          },
          webhook_id: connections.models.map(({ id }) => id),
        },
        granularity: 'hour',
        dimentions: ['status', 'webhook_id'],
      })
    );
  }, [now, connections]);

  const { raw_data } = useCubeQueryLocalRawData(stats_chart_query, {
    resetResultSetOnChange: false,
  });

  const timeseries_2h_by_connection_id = useMemo(
    () =>
      raw_data &&
      connections &&
      formatRawDataToGranularity(connections.models, raw_data, group_metrics_by_2h, now),
    [raw_data, connections],
  );

  const timeseries_4h_by_connection_id = useMemo(
    () =>
      raw_data &&
      connections &&
      formatRawDataToGranularity(connections.models, raw_data, group_metrics_by_4h, now),
    [raw_data, connections],
  );

  const grouped_by =
    connections &&
    Object.entries(
      connections.models.reduce(
        (groups, connection) => {
          if (group_by) {
            groups[connection[group_by].id] = [
              ...(groups[connection[group_by].id] || []),
              connection,
            ];
          }

          return groups;
        },
        {} as { [k: string]: APIWebhook[] },
      ),
    );
  return (
    <Div id="connections-area" h={100}>
      {connections?.count === 0 && (filtering || search_term) ? (
        <Div h={100} flex={{ direction: 'column', align: 'center', justify: 'center' }}>
          <Text muted as="p">
            No connections match those filters...
          </Text>
        </Div>
      ) : (
        <Div>
          {grouped_by?.map(([id, connections]) => (
            <GroupedConnections
              key={id}
              connections={connections}
              timeseries_by_connection_id={{
                '2h': timeseries_2h_by_connection_id ?? {},
                '4h': timeseries_4h_by_connection_id ?? {},
              }}
            />
          ))}
          {connections?.count === 200 && (
            <Container small flex={{ justify: 'center', direction: 'column' }} m={{ t: 6 }}>
              <Text bold center as="p">
                <Icon icon="info" left />
                You've reached the maximum connection displayed
              </Text>
              <Text muted center as="p">
                The dashboard displays up to 200 connections at a time. Use filters, search, and
                sort above to narrow down your connections list.
              </Text>
            </Container>
          )}
        </Div>
      )}
    </Div>
  );
};

export default ConnectionsList;
