import { FormEvent } from 'react';
import styled, { css } from 'styled-components';

import Text from '../../base/Text';
import TextInput from '../../Form/Fields/TextInput';
import { Div } from '../../helpers/StyledUtils';

const StyledInput = styled(TextInput)(
  ({ theme }) => css`
    ${Text} {
      font-size: ${theme.pxToRem(theme.font_sizes.xs.font_size)};
      line-height: ${theme.pxToRem(theme.font_sizes.xs.line_height)};
    }
  `,
);

const NumberRangeFilter: React.FC<{
  name: string;
  min_label?: string;
  max_label?: string;
  delete_button?: JSX.Element;
  onSubmit: (e?: FormEvent<HTMLFormElement>) => void;
}> = ({ name, min_label, max_label, delete_button, onSubmit }) => {
  return (
    <Div flex={{ direction: 'row' }}>
      <Div flex>
        <Div>
          <Div flex={{ align: 'center' }}>
            <StyledInput
              m={0}
              min_w={{ px: 140 }}
              label={min_label || 'Min'}
              name={`${name}.min`}
              type={'number'}
              min={0}
              placeholder="-"
              max={10000}
              onBlur={onSubmit}
            />
            <Div
              m={{ t: 5 }}
              w={{ px: 32 }}
              h={{ px: 32 }}
              flex={{ align: 'center', justify: 'center' }}>
              –
            </Div>
          </Div>
        </Div>
        <Div>
          <StyledInput
            m={0}
            min_w={{ px: 140 }}
            label={max_label || 'Max'}
            name={`${name}.max`}
            type={'number'}
            placeholder="-"
            min={0}
            max={10000}
            onBlur={onSubmit}
          />
        </Div>
      </Div>
      <Div m={{ t: 5 }}>{delete_button && delete_button}</Div>
    </Div>
  );
};

export default NumberRangeFilter;
