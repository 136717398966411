import { useContext, useEffect } from 'react';
import styled, { css } from 'styled-components';
import useSWR from 'swr';

import { Event } from '../../../../../../../typings/Event.interface';
import { EventAttempt } from '../../../../../../../typings/EventAttempt.interface';
import APIMethodKeys from '../../../../client/APIMethodKeys';
import Button from '../../../common/base/Button';
import Divider from '../../../common/base/Divider';
import Link from '../../../common/base/Link';
import Loading from '../../../common/base/Loading';
import Skeleton from '../../../common/base/Skeleton';
import Tabs from '../../../common/base/Tabs';
import Text from '../../../common/base/Text';
import DropdownMenu from '../../../common/DropdownMenu';
import { Div } from '../../../common/helpers/StyledUtils';
import FullRequestData from '../../../common/Request/FullRequestData';
import RequestPayload from '../../../common/Request/RequestBody';
import { Status } from '../../../common/Status';
import { GlobalContext } from '../../../contexts/GlobalContext';
import useCopyAsCurl from '../../../hooks/useCopyAsCurl';
import useLocalStorage from '../../../hooks/useLocalStorage';
import {
  StyledViewActions,
  StyledViewNavHeader,
  StyledViewScrollable,
  StyledViewSidebar,
  StyledViewSidebarNav,
  StyledViewSidebarSection,
  ViewSidebarTitle,
} from '../../../layouts/ViewLayout';
import { DashboardContext } from '../DashboardContext';

const StyledStickyTabsWrapper = styled.div(
  ({ theme }) => css`
    position: sticky;
    top: 0;
    padding-top: ${theme.spacing(2)};
    background-color: ${theme.colors.surface.base.surface};
    z-index: 1;
  `,
);

const EventPreview: React.FC<{
  event_id: string;
  attempt_id?: string;
  list_event?: Event;
  list_attempt?: EventAttempt;
  event_actions?: { [k in 'retry' | 'mute' | 'bookmark']: (event: Event) => Promise<void> };
  onClose: () => void;
}> = ({ event_id, attempt_id, onClose, event_actions, list_attempt, list_event }) => {
  const [tab_preferences, setTabPreference] = useLocalStorage<{
    event: 'request' | 'response';
    attempt: 'request' | 'response';
  }>('pref:event-sidebar:active-tab', {
    event: 'request',
    attempt: 'response',
  });

  const active_tab = tab_preferences[attempt_id ? 'attempt' : 'event'] || 'request';
  const { HookdeckAPI } = useContext(GlobalContext);
  const { subscription, organization } = useContext(DashboardContext);
  const { data: fetched_event } = useSWR(APIMethodKeys.events.get(event_id), () =>
    HookdeckAPI.events.get(event_id),
  );

  const event =
    fetched_event &&
    (list_event
      ? {
          ...fetched_event,
          ...list_event,
          data: fetched_event.data,
        }
      : fetched_event);

  const { data: bookmarks, mutate: mutateBookmarks } = useSWR(
    event && APIMethodKeys.bookmarks.list({ event_data_id: event.event_data_id }),
    () => HookdeckAPI.bookmarks.list({ event_data_id: event!.event_data_id }),
  );

  const { data: attempts, mutate: mutateAttempts } = useSWR(
    !attempt_id && event_id && APIMethodKeys.attempts.list({ event_id, limit: 1 }),
    () => HookdeckAPI.attempts.list({ event_id, limit: 1 }),
  );

  const resolved_attempt_id =
    attempt_id ||
    (event?.status === 'FAILED' || event?.status === 'SUCCESSFUL' ? attempts?.models[0]?.id : null);

  const { data: attempt, mutate } = useSWR(
    resolved_attempt_id && APIMethodKeys.attempts.get(resolved_attempt_id),
    () => HookdeckAPI.attempts.get(resolved_attempt_id!),
  );

  useEffect(() => {
    if (list_attempt && attempt && list_attempt.id === attempt.id) {
      mutate();
    }
    if (!attempt_id && list_attempt) {
      mutateAttempts();
    }
  }, [list_attempt]);

  useEffect(() => {
    if (attempt) {
      let interval;
      if (
        (!attempt.error_code && !attempt.response_status) ||
        attempt.body === 'NOT_AVAILABLE_YET'
      ) {
        interval = setInterval(mutate, 3000);
      } else if (interval) {
        clearInterval(interval);
      }
      return () => interval && clearInterval(interval);
    }
  }, [attempt, mutate]);

  const { data: destination } = useSWR(
    event && APIMethodKeys.destinations.get(event.destination_id),
    () => HookdeckAPI.destinations.get(event!.destination_id),
  );

  const { error: webhook_error } = useSWR(
    event && APIMethodKeys.webhooks.get(event.webhook_id),
    () => HookdeckAPI.webhooks.get(event!.webhook_id),
    {
      shouldRetryOnError: (error) => error.status !== 410,
    },
  );

  const webhook_deleted = webhook_error?.status === 410;

  const copyAsCurl = useCopyAsCurl({
    type: 'event',
    id: event_id,
    event_data: event?.data ?? undefined,
    destination,
  });

  const bookmarked = bookmarks && bookmarks?.count > 0;
  return (
    <StyledViewSidebar>
      <StyledViewSidebarNav>
        <StyledViewNavHeader>
          <Div flex={{ align: 'center', gap: 2 }}>
            <ViewSidebarTitle title={attempt_id || event_id} />
            {event && <Status {...event} />}
          </Div>
          <StyledViewActions>
            {event_actions && (
              <>
                <DropdownMenu.Permission
                  role="member"
                  minimal
                  icon="horizontal_more"
                  placement="bottom-end"
                  options={[
                    {
                      label: 'Retry',
                      icon: 'retry',
                      disabled: !event || webhook_deleted,
                      onClick: () => event && event_actions.retry(event),
                    },
                    {
                      label: 'Copy as cURL',
                      icon: 'copy',
                      tooltip: 'Copy event as cURL',
                      onClick: copyAsCurl,
                    },
                    {
                      label: 'Bookmark',
                      icon: bookmarked ? 'bookmark' : 'bookmark_add',
                      disabled: bookmarked,
                      tooltip: bookmarked ? 'Already Bookmarked' : 'Bookmark this event',
                      onClick: () =>
                        event &&
                        event_actions.bookmark(event).then(() => {
                          mutateBookmarks();
                        }),
                    },
                  ]}
                />
                <Divider vertical m={{ y: 1 }} />
              </>
            )}
            <Button invisible icon="link" to={`/events/${event_id}`} />
            <Button invisible icon="close" onClick={onClose} />
          </StyledViewActions>
        </StyledViewNavHeader>
        <StyledStickyTabsWrapper>
          <Div p={{ x: 4 }} flex={{ justify: 'space-between' }}>
            <Tabs
              compact
              active_tab={active_tab}
              border={false}
              onTabSelected={(tab: 'request' | 'response') =>
                setTabPreference({ ...tab_preferences, [attempt_id ? 'attempt' : 'event']: tab })
              }
              tabs={[
                { key: 'request', label: 'Request' },
                { key: 'response', label: 'Response' },
                { key: 'metadata', label: 'Metadata' },
              ]}
            />
          </Div>
          <Divider />
        </StyledStickyTabsWrapper>
      </StyledViewSidebarNav>
      <StyledViewScrollable>
        {active_tab === 'request' && (
          <StyledViewSidebarSection>
            <Loading
              require={[event]}
              wrapper={() => (
                <>
                  <Skeleton h={{ px: 200 }} w={100} loading m={{ b: 4 }} />
                  <Skeleton h={{ px: 200 }} w={100} loading />
                </>
              )}>
              {() =>
                !event!.data ? (
                  <>
                    <Text as="p">
                      This event is past your archival period of {subscription!.retention_days}{' '}
                      days, the request data is no longer available.
                    </Text>
                    {!organization && (
                      <Link
                        to="/settings/organization/plans?highlight=retention_days"
                        icon="upgrade">
                        Upgrade Plan
                      </Link>
                    )}
                  </>
                ) : (
                  <FullRequestData data={event!.data} compact type="event" id={event!.id} />
                )
              }
            </Loading>
          </StyledViewSidebarSection>
        )}
        {active_tab === 'response' &&
          (event?.status === 'FAILED' || event?.status === 'SUCCESSFUL' ? (
            <Div p={4}>
              {!attempt || attempt.body === 'NOT_AVAILABLE_YET' ? (
                <Skeleton h={{ px: 200 }} w={100} loading />
              ) : (
                <RequestPayload compact label="Body" body={attempt.body ?? ''} />
              )}
            </Div>
          ) : (
            <Div p={4}>
              <Text muted as="p">
                Response will be available once the event has been delivered to your destination.
              </Text>
            </Div>
          ))}
      </StyledViewScrollable>
    </StyledViewSidebar>
  );
};

export default EventPreview;
