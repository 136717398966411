import { useContext } from 'react';
import useSWR from 'swr';

import { DiffEditor } from '@monaco-editor/react';

import APIMethodKeys from '../../../../client/APIMethodKeys';
import Button from '../../../common/base/Button';
import { StyledCard } from '../../../common/base/Card';
import Divider from '../../../common/base/Divider';
import Editor from '../../../common/Editor';
import Console from '../Connections/Forms/TransformRuleForm/Console';
import Link from '../../../common/base/Link';
import Loading from '../../../common/base/Loading';
import { Div } from '../../../common/helpers/StyledUtils';
import Tabs from '../../../common/base/Tabs';
import Text from '../../../common/base/Text';
import { GlobalContext } from '../../../contexts/GlobalContext';
import useSearchQuery from '../../../hooks/useSearchQuery';
import { DashboardContext } from '../DashboardContext';
import {
  StyledViewScrollable,
  StyledViewSidebar,
  StyledViewSidebarNav,
  StyledViewSidebarSection,
} from '../StyledView';
import { useTheme } from 'styled-components';

const TransformationExecutionDetails: React.FC<{
  transformation_id: string;
  execution_id: string;
  onClose: () => void;
}> = ({ transformation_id, execution_id, onClose }) => {
  const { query, updateSearchQuery } = useSearchQuery<{
    transform_tab: 'input' | 'output' | 'diff';
  }>();
  const active_tab = query.transform_tab || 'input';
  const theme = useTheme();
  const { HookdeckAPI } = useContext(GlobalContext);
  const { subscription, organization } = useContext(DashboardContext);
  const { data: execution } = useSWR(
    APIMethodKeys.transformations.getExecution(transformation_id, execution_id),
    () => HookdeckAPI.transformations.getExecution(transformation_id, execution_id),
  );
  return (
    <StyledViewSidebar width="large">
      <StyledViewSidebarNav background_color="background">
        <Text semi text_wrap={false}>
          Transformation Execution
        </Text>
        <Button invisible icon="close" onClick={onClose} />
      </StyledViewSidebarNav>
      <StyledViewScrollable>
        <Loading
          require={[execution]}
          wrapper={(loading) => <StyledViewSidebarSection>{loading}</StyledViewSidebarSection>}
        />
        {execution && execution.original_event_data && (
          <>
            <StyledViewSidebarSection p={4}>
              <StyledCard overflow_hidden>
                <div style={{ flexGrow: 1 }}>
                  <Div p={{ x: 4, t: 2 }} flex={{ align: 'center', justify: 'space-between' }}>
                    <Tabs
                      compact
                      tabs={[
                        {
                          key: 'input',
                          label: 'Input',
                        },
                        {
                          key: 'output',
                          label: 'Output',
                        },
                        {
                          key: 'diff',
                          label: 'Diff',
                        },
                      ]}
                      border={false}
                      m={0}
                      active_tab={active_tab}
                      onTabSelected={(key) => updateSearchQuery({ transform_tab: key })}
                    />
                  </Div>
                  <Divider />
                  {active_tab === 'input' && (
                    <Editor
                      height="50vh"
                      prevent_theme_change
                      value={JSON.stringify(execution.original_event_data, null, 2)}
                      disabled
                    />
                  )}
                  {active_tab === 'output' && (
                    <Editor
                      height="50vh"
                      prevent_theme_change
                      value={JSON.stringify(execution.transformed_event_data, null, 2)}
                      disabled
                    />
                  )}
                  {active_tab === 'diff' && (
                    <DiffEditor
                      original={JSON.stringify(execution.original_event_data, null, 2)}
                      modified={JSON.stringify(execution.transformed_event_data, null, 2)}
                      onMount={(editor, monaco) => {
                        if (theme.mode === 'dark') {
                          monaco.editor.defineTheme('dark', {
                            base: 'vs-dark',
                            inherit: true,
                            rules: [],
                            colors: {
                              'editor.background': theme.colors.surface.base.surface,
                            },
                          });
                          monaco.editor.setTheme('dark');
                        }
                      }}
                      options={{
                        enableSplitViewResizing: false,
                        renderSideBySide: false,
                        lineNumbers: 'off',
                        padding: {
                          top: 8,
                          bottom: 8,
                        },
                        contextmenu: false,
                        readOnly: true,
                        domReadOnly: true,
                        renderLineHighlightOnlyWhenFocus: true,
                        hideCursorInOverviewRuler: true,
                        overviewRulerBorder: false,
                        guides: {
                          indentation: false,
                          highlightActiveIndentation: false,
                        },
                        minimap: {
                          enabled: false,
                        },
                        scrollbar: {
                          vertical: 'hidden',
                          horizontal: 'hidden',
                        },
                        overviewRulerLanes: 0,
                        matchBrackets: 'never',
                        scrollBeyondLastColumn: 0,
                        scrollBeyondLastLine: true,
                      }}
                      originalLanguage="json"
                      modifiedLanguage="json"
                      height="50vh"
                    />
                  )}
                </div>
              </StyledCard>
              <StyledCard m={{ t: 4 }} overflow_hidden>
                <Console lines={execution.logs} />
              </StyledCard>
            </StyledViewSidebarSection>
          </>
        )}
        {execution && !execution.original_event_data && (
          <StyledViewSidebarSection>
            <Text as="p">
              This request is past your archival period of {subscription!.retention_days} days, the
              request data is no longer available.
            </Text>
            {!organization && (
              <Link to="/settings/organization/plans?highlight=retention_days" icon="upgrade">
                Upgrade Plan
              </Link>
            )}
          </StyledViewSidebarSection>
        )}
      </StyledViewScrollable>
    </StyledViewSidebar>
  );
};

export default TransformationExecutionDetails;
