import {
  Bar,
  BarChart as RechartBarChart,
  CartesianGrid,
  Rectangle,
  Tooltip,
  XAxis,
  YAxis,
} from 'recharts';
import { useTheme } from 'styled-components';

import Chart, { ChartDataSet, ChartProps, CLASS_PREFIX, formatActiveNameForDom } from './Chart';

const CustomBar =
  ({ id, dataset, theme_colors }: { id: string; dataset: ChartDataSet; theme_colors: any }) =>
  (props) => {
    return (
      <Rectangle
        {...props}
        ref={null}
        className={`${CLASS_PREFIX}-${id} ${CLASS_PREFIX}-${id}-${
          dataset.key
        } ${CLASS_PREFIX}-${id}-${formatActiveNameForDom(props.name)}`}
        strokeOpacity={0}
        style={{ transition: 'opacity 300ms' }}
        fill={
          dataset.theme_color ? theme_colors.surface.chart[dataset.theme_color] : dataset.hex_color
        }
      />
    );
  };

interface BarChartProps extends Omit<ChartProps, 'type' | 'children'> {
  datasets_type?: 'stacked' | 'switch';
}

const BarChart: React.FC<BarChartProps> = (props) => {
  const theme = useTheme();
  return (
    <Chart type={'bar'} {...props}>
      {({ id, data, cached_datasets, datasets, priority_display, setHovered, setActiveName }) => {
        const highestYValue =
          !props.datasets_type || props.datasets_type === 'stacked'
            ? cached_datasets?.map((d) => d.highest).reduce((a, b) => a + b, 0) || 0
            : cached_datasets?.map((d) => d.highest).reduce((a, b) => Math.max(a, b), 0) || 0;
        return (
          <RechartBarChart
            margin={{ top: 0, left: 0, right: 0, bottom: 0 }}
            data={data}
            onMouseMove={(e) => setActiveName(e?.activeLabel || null)}
            barCategoryGap={'4%'}>
            <CartesianGrid
              strokeDasharray="3 3"
              stroke={theme.colors.outline.neutral}
              vertical={false}
            />
            <Tooltip
              cursor={{ stroke: theme.colors.outline.neutral, width: 1 }}
              content={() => null}
            />
            <XAxis
              dataKey={'name'}
              allowDecimals={false}
              stroke={theme.colors.on.neutral.tertiary_neutral}
              tick={{
                fill: theme.colors.on.neutral.tertiary_neutral,
                fontSize: theme.font_sizes.xs.font_size,
              }}
              tickMargin={8}
              minTickGap={16}
              ticks={priority_display}
            />
            <YAxis
              width={highestYValue > 0 ? 38 + Math.floor(Math.log10(highestYValue)) * 9 : 38}
              allowDecimals={false}
              stroke={'transparent'}
              domain={
                cached_datasets &&
                cached_datasets[0] &&
                cached_datasets[0].metric_type === 'percent'
                  ? [0, 'auto']
                  : ['minData', 'auto']
              }
              tickMargin={8}
              minTickGap={24}
              tick={{
                fill: theme.colors.on.neutral.tertiary_neutral,
                fontSize: theme.font_sizes.xs.font_size,
              }}
            />
            {datasets?.map((dataset) => (
              <Bar
                key={dataset.key}
                dataKey={dataset.label}
                isAnimationActive={false}
                stackId={'a'}
                shape={CustomBar({
                  id,
                  dataset,
                  theme_colors: theme.colors,
                })}
                onMouseEnter={(e) => {
                  setHovered({ key: dataset.key, label: dataset.label, name: e.name });
                }}
                onMouseLeave={() => setHovered(null)}
              />
            ))}
          </RechartBarChart>
        );
      }}
    </Chart>
  );
};

export default BarChart;
