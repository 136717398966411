import {
  add,
  endOfDay,
  format,
  getDate,
  isSameDay,
  isWithinInterval,
  parse,
  startOfDay,
  startOfHour,
  sub,
} from 'date-fns';
import { useField } from 'formik';
import { useContext, useEffect, useState } from 'react';
import styled, { css } from 'styled-components';

import { Datepicker } from '@datepicker-react/styled';

import { addTimeToDate, relative_dates } from '../../../../utils/date';
import { DashboardContext } from '../../../scenes/DashboardScene/DashboardContext';
import Button from '../../base/Button';
import { StyledCard } from '../../base/Card';
import Icon from '../../base/Icon';
import Text from '../../base/Text';
import Tooltip from '../../base/Tooltip';
import { Div } from '../../helpers/StyledUtils';
import TimeInput, { time_input_steps_by_format } from './TimeInput';

const SelectableButton = styled(Button)<{ active: boolean }>(
  ({ theme, active }) => css`
    justify-content: flex-start;
    ${active &&
    css`
      background-color: ${theme.colors.surface.container.primary};
      color: ${theme.colors.on.hue_container.primary};
      &:hover {
        background-color: ${theme.colors.surface.container.primary};
      }
      &:after {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        border-radius: 6px;
      }
    `}
  `,
);

const StyledTimeInputWrapper = styled(Div)`
  width: 225px;
`;

const RelativeCellWrapper = styled.div<{ active: boolean }>(
  ({ theme, active }) =>
    active &&
    css`
      display: flex;
      align-items: center;
      justify-content: center;
      margin: 1px;
      height: 31px;
      min-width: 31px;
      background-color: ${theme.colors.surface.container.primary};
      color: ${theme.colors.on.hue_container.primary};
    `,
);

// this needs to happen in the onSubmit to avoid date range errors
// that way the validation will always run first and avoid errors
export const formatDateFilterValues = (values: {
  start_date?: string;
  start_time?: string;
  end_date?: string;
  end_time?: string;
}) => {
  values.start_date =
    values.start_date && values.start_time
      ? addTimeToDate(values.start_date, values.start_time).toISOString()
      : values.start_date;
  values.end_date =
    values.end_date && values.end_time
      ? addTimeToDate(values.end_date, values.end_time).toISOString()
      : values.end_date;

  return {
    start_date: values.start_date,
    end_date: values.end_date,
  };
};

const DatePickerInput: React.FC<{
  name?: string;
  display_reset?: boolean;
  time_format?: keyof typeof time_input_steps_by_format;
}> = ({ name = 'date', display_reset, time_format }) => {
  const { subscription } = useContext(DashboardContext);
  const [focused_input, setFocusedInput] = useState<'startDate' | 'endDate'>('startDate');
  const [{ value }, , { setValue }] = useField(name);
  const [
    { value: start_time_value },
    { initialValue: start_time_initial_value },
    { setValue: setStartTimeValue },
  ] = useField<string>('start_time');
  const [
    { value: end_time_value },
    { initialValue: end_time_initial_value },
    { setValue: setEndTimeValue },
  ] = useField<string>('end_time');
  const [time_reset_value, setTimeResetValue] = useState({
    start: start_time_initial_value,
    end: end_time_initial_value,
  });

  useEffect(() => {
    if (!value?.min) {
      setFocusedInput('startDate');
    }
  }, [value]);

  const relative_sample_date =
    value?.relative && relative_dates[value.relative].convert(new Date());

  const now = new Date();

  return (
    <Div p={4} flex={{ gap: 4 }}>
      <StyledCard p={4} flex={{ direction: 'column', gap: 2 }}>
        <Text semi>Relative Interval</Text>
        {Object.entries(relative_dates).map(([key, { label, days, convert }]) => (
          <Tooltip
            key={key}
            disabled={days <= subscription!.retention_days}
            tooltip="Upgrade to increase retention"
            placement="right"
            offset={[-16, 4]}
            cta={{
              icon: 'upgrade',
              label: 'Upgrade',
              to: '/settings/organization/plans?highlight=retention_days',
            }}>
            <SelectableButton
              neutral
              small
              disabled={days > subscription!.retention_days}
              onClick={() => {
                const { min, max } = convert(now);
                setValue({ relative: key, min: min.toISOString(), max: max.toISOString() });
                min && setStartTimeValue(format(min, 'HH:mm:ss'));
                max && setEndTimeValue(format(max, 'HH:mm:ss'));
              }}
              block
              active={value?.relative === key}
              primary={value?.relative === key}>
              {label}
            </SelectableButton>
          </Tooltip>
        ))}
      </StyledCard>
      <Div flex={{ direction: 'column', gap: 4 }}>
        <StyledCard p={4}>
          <Datepicker
            showClose={false}
            showSelectedDates={false}
            showResetDates={false}
            onDatesChange={(data) => {
              setFocusedInput(data.focusedInput || 'startDate');
              const min = data.startDate ? startOfDay(data.startDate) : undefined;
              const max = data.endDate
                ? isSameDay(data.endDate, new Date())
                  ? add(startOfHour(new Date()), { hours: 1 })
                  : isSameDay(data.endDate, data!.startDate!)
                    ? endOfDay(data.endDate)
                    : startOfDay(data.endDate)
                : endOfDay(data!.startDate!);
              setValue({
                relative: undefined,
                min: min?.toISOString() || undefined,
                max: max?.toISOString() || undefined,
              });
              min && setStartTimeValue(format(min, 'HH:mm:ss'));
              max && setEndTimeValue(format(max, 'HH:mm:ss'));
              setTimeResetValue((prev) => ({
                start: min ? format(min, 'HH:mm:ss') : prev.start,
                end: max ? format(max, 'HH:mm:ss') : prev.end,
              }));
            }}
            minBookingDate={sub(new Date(), { days: subscription!.retention_days })}
            maxBookingDate={new Date()}
            initialVisibleMonth={
              (value?.min && new Date(value?.min)) ||
              sub(new Date(), { days: subscription!.retention_days })
            }
            startDate={!value?.relative && value?.min ? new Date(value.min) : null}
            endDate={!value?.relative && value?.max ? new Date(value.max) : null}
            focusedInput={focused_input}
            onDayRender={(day) => (
              <RelativeCellWrapper
                active={
                  relative_sample_date &&
                  isWithinInterval(day, {
                    start: relative_sample_date.min,
                    end: relative_sample_date.max || new Date(),
                  })
                }>
                {getDate(day)}
              </RelativeCellWrapper>
            )}
          />
        </StyledCard>
        {time_format && (
          <StyledCard p={4} flex={{ grow: true, justify: 'space-between', align: 'center' }}>
            <StyledTimeInputWrapper>
              <TimeInput
                label_size="xs"
                format={time_format}
                reset_value={time_reset_value.start}
                onReset={() => {
                  setStartTimeValue(time_reset_value!.start!);
                }}
                onChange={() => {
                  setValue({
                    ...value,
                    relative: undefined,
                  });
                }}
                validate={(time: string) => {
                  const now = new Date();
                  if (
                    isSameDay(new Date(value.min), new Date(value.max)) &&
                    parse(time, 'HH:mm:ss', now) > parse(end_time_value, 'HH:mm:ss', now)
                  ) {
                    return 'Start time must be before end time';
                  }
                }}
                label="Start time"
                name="start_time"
              />
            </StyledTimeInputWrapper>
            <Div>
              <Icon muted m={{ t: 5 }} icon="arrow_forward" p={2} />
            </Div>
            <StyledTimeInputWrapper>
              <TimeInput
                label_size="xs"
                format={time_format}
                reset_value={time_reset_value.end}
                onReset={() => {
                  setEndTimeValue(time_reset_value!.end!);
                }}
                onChange={() => {
                  setValue({
                    ...value,
                    relative: undefined,
                  });
                }}
                validate={(time: string) => {
                  const now = new Date();
                  if (
                    isSameDay(new Date(value.min), new Date(value.max)) &&
                    parse(time, 'HH:mm:ss', now) < parse(start_time_value, 'HH:mm:ss', now)
                  ) {
                    return 'End time must be after start time';
                  }
                }}
                label="End time"
                name="end_time"
              />
            </StyledTimeInputWrapper>
          </StyledCard>
        )}
      </Div>
    </Div>
  );
};

export default DatePickerInput;
