import { Form, Formik } from 'formik';
import { useContext } from 'react';
import { useHistory, useLocation } from 'react-router';

import APIMethodKeys from '../../../../../client/APIMethodKeys';
import Button from '../../../../common/base/Button';
import { StyledCard } from '../../../../common/base/Card';
import Container from '../../../../common/base/Container';
import Link from '../../../../common/base/Link';
import DropdownMenu from '../../../../common/DropdownMenu';
import MultiSelectInput from '../../../../common/Form/Fields/MultiSelectInput';
import { Div } from '../../../../common/helpers/StyledUtils';
import { metric_definitions } from '../../../../common/metrics/metric-definitions';
import MetricCardGroup from '../../../../common/metrics/MetricCard/MetricCardGroup';
import MetricChart from '../../../../common/metrics/MetricChart';
import MetricTable from '../../../../common/metrics/MetricTable';
import MetricWrapper from '../../../../common/metrics/MetricWrapper';
import { MetricChartType } from '../../../../common/metrics/useMetric';
import { GlobalContext } from '../../../../contexts/GlobalContext';
import useSearchQuery from '../../../../hooks/useSearchQuery';
import { StyledViewContent, StyledViewWrapper } from '../../StyledView';
import { metric_categories, MetricSection } from '../metric-categories';
import { useDateRange } from '../MetricDatePicker';
import { useMetricsEnabled } from '../MetricsPaywall';

export const dimension_by_resource = {
  source: 'source_id',
  webhook: 'webhook_id',
  destination: 'destination_id',
};

export const useMetricsFilters = () => {
  const location = useLocation();
  const metric_category_key = location.pathname.split('/')[2]?.split('#')[0] || 'requests';
  const metric_category = metric_categories[metric_category_key];
  const { HookdeckAPI } = useContext(GlobalContext);
  const { query, updateSearchQuery } = useSearchQuery<{
    [key: string]: string[];
  }>();
  const onDimentionChanged = (values: string[]) => {
    updateSearchQuery({ [metric_category.dimention.key]: values.length > 0 ? values : undefined });
  };
  const getLabel = (model) => {
    if (metric_category.dimention.api_root === 'webhooks') {
      return model.full_name;
    }
    return model.name;
  };
  const formatOptions = (data) =>
    data && data.models.map((model) => ({ label: getLabel(model), value: model.id }));
  const formatFilters = (search_term?: string, values?: string[]) => {
    if (metric_category.dimention.api_root === 'webhooks') {
      return { limit: 250, full_name: search_term || undefined, id: values };
    }
    return { limit: 250, name: search_term ? { contains: search_term } : undefined, id: values };
  };
  const dimension_values = query[metric_category.dimention.key] || [];
  const form = (
    <Formik
      initialValues={{ [metric_category.dimention.key]: dimension_values }}
      onSubmit={(values) => onDimentionChanged(values[metric_category.dimention.key])}>
      {({ handleSubmit }) => (
        <Form>
          <MultiSelectInput
            renderToggle={(opened, toggle, value) => (
              <Button
                neutral
                icon="filter_list"
                onClick={toggle}
                dropdown
                expand_icon="chevron_down"
                badge={value?.length > 0 ? { label: value.length } : undefined}>
                {metric_category.dimention.label}
              </Button>
            )}
            placement="bottom-end"
            onClose={handleSubmit}
            onRemove={handleSubmit}
            initial_options={[]}
            formatOptions={formatOptions}
            formatFilters={formatFilters}
            fetcher={HookdeckAPI[metric_category.dimention.api_root].list}
            fetcherKey={APIMethodKeys[metric_category.dimention.api_root].list}
            name={metric_category.dimention.key}
            m={0}
          />
        </Form>
      )}
    </Formik>
  );
  return {
    dimension_values,
    form,
    metric_category_key,
    metric_category,
    metric_categories,
  };
};

const getExpandedKey = (chart: MetricSection) => {
  return `${chart.title}-${chart.description}`.toLowerCase().replace(/ /g, '-');
};

const MetricCategoryView: React.FC = () => {
  const history = useHistory();
  const { dimension_values, metric_category, metric_category_key } = useMetricsFilters();
  const { query, updateSearchQuery } = useSearchQuery<{ expanded: string }>();
  const metrics_enabled = useMetricsEnabled();
  const [date_range] = useDateRange();

  const expanded_chart =
    query.expanded &&
    metric_category.metrics.charts.find((c) => getExpandedKey(c) === query.expanded);

  if (expanded_chart) {
    return (
      <StyledViewWrapper>
        <StyledViewContent>
          <Container w={100} h={100} p={8}>
            <Div
              key={JSON.stringify(expanded_chart.chart)}
              id={getExpandedKey(expanded_chart)}
              h={100}>
              <MetricWrapper key={expanded_chart.title} title={expanded_chart.title} h={100}>
                <StyledCard h={100}>
                  <MetricChart
                    height={window.innerHeight - 290}
                    type={expanded_chart.chart!.metric.type as MetricChartType}
                    metric={expanded_chart.chart!.metric.name}
                    configs={{
                      date_range,
                      title: expanded_chart.title,
                      description: expanded_chart.description
                        ? `/ ${expanded_chart.description}`
                        : '',
                      options: {
                        dimentions: [
                          dimension_by_resource[
                            metric_definitions[expanded_chart.chart!.metric.name].resource
                          ],
                        ],
                        filters: {
                          [metric_category.dimention.key]: dimension_values,
                        },
                      } as any,
                    }}
                    action={
                      <Div flex={{ gap: 4, align: 'center' }}>
                        <Link icon="link" neutral to={`/${metric_category_key}`}>
                          Explore
                        </Link>
                        <Button
                          neutral
                          icon="close"
                          onClick={() =>
                            updateSearchQuery({
                              expanded: undefined,
                            })
                          }
                        />
                      </Div>
                    }
                  />
                </StyledCard>
              </MetricWrapper>
            </Div>
          </Container>
        </StyledViewContent>
      </StyledViewWrapper>
    );
  }
  return (
    <StyledViewWrapper>
      <StyledViewContent>
        <Container w={100} p={8}>
          <Div m={{ b: 6 }}>
            <MetricWrapper
              id={metric_category.metrics.overview.tab_label.toLowerCase().replace(/ /g, '-')}
              title={metric_category.metrics.overview.title}>
              <MetricCardGroup
                configs={{
                  date_range,
                  options: {
                    filters: {
                      [metric_category.dimention.key]: dimension_values,
                    } as any,
                  },
                }}
                metrics={metric_category.metrics.overview.metrics?.map((m) => m.name) || []}
                columns={3}
                action={
                  <Link neutral to={`/${metric_category_key}`} icon="link">
                    Explore
                  </Link>
                }
              />
            </MetricWrapper>
          </Div>
          {metrics_enabled && metric_category.metrics.current_data && (
            <>
              <Div
                id={metric_category.metrics.current_data.tab_label
                  .toLowerCase()
                  .replace(/ /g, '-')}>
                <Div m={{ y: 6 }} grid={{ columns: 2, gap: 6 }}>
                  {metric_category.metrics.current_data.metrics?.map((metric) => (
                    <MetricTable
                      key={`${metric.name}-${metric.type}-${
                        metric_category.metrics.current_data!.title
                      }`}
                      metric={metric.name}
                      configs={{
                        date_range,
                        options: {
                          dimentions: [
                            dimension_by_resource[metric_definitions[metric.name].resource],
                          ],
                          filters: {
                            [metric_category.dimention.key]: dimension_values,
                          },
                        } as any,
                      }}
                      footer_text={metric.footer_text}
                      footer_link={metric.footer_link}
                      max_h={{ px: 316 }}
                    />
                  ))}
                </Div>
              </Div>
            </>
          )}
          <Div grid={{ columns: 3, gap: 6 }}>
            {metrics_enabled &&
              metric_category.metrics.charts.map((chart) => (
                <Div
                  key={JSON.stringify(chart.chart)}
                  id={chart.tab_label.toLowerCase().replace(/ /g, '-')}>
                  <MetricWrapper key={chart.title} title={chart.title}>
                    <StyledCard max_h={{ px: 316 }} h={{ px: 316 }}>
                      <MetricChart
                        type={chart.chart!.metric.type as MetricChartType}
                        metric={chart.chart!.metric.name}
                        height={232}
                        configs={{
                          date_range,
                          title: chart.title,
                          description: chart.description ? `/ ${chart.description}` : '',
                          options: {
                            dimentions: [
                              dimension_by_resource[
                                metric_definitions[chart.chart!.metric.name].resource
                              ],
                            ],
                            filters: {
                              [metric_category.dimention.key]: dimension_values,
                            },
                          } as any,
                        }}
                        action={
                          <DropdownMenu
                            minimal
                            placement="bottom-end"
                            w={{ px: 192 }}
                            icon="horizontal_more"
                            options={[
                              {
                                label: 'Expand',
                                icon: 'open',
                                onClick: () => {
                                  updateSearchQuery({
                                    expanded: getExpandedKey(chart),
                                  });
                                  window.scrollTo({ top: 0 });
                                },
                              },
                              {
                                label: 'Explore',
                                icon: 'link',
                                onClick: () => history.push(`/${metric_category_key}`),
                              },
                            ]}
                          />
                        }
                      />
                    </StyledCard>
                  </MetricWrapper>
                </Div>
              ))}
          </Div>
        </Container>
      </StyledViewContent>
    </StyledViewWrapper>
  );
};

export default MetricCategoryView;
