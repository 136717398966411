import { add, setMilliseconds, startOfHour, sub } from 'date-fns';
import { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import NumberFormat from 'react-number-format';
import { useTheme } from 'styled-components';

import { Request } from '../../../../../../../typings/Request.interface';
import { RequestView } from '../../../../../../../typings/View.interface';
import LINKS from '../../../../configs/links';
import {
  RequestListFiltersProps,
  RequestListQueryParams,
} from '../../../../typings/RequestList.interface';
import { extractFromArray, toArray } from '../../../../utils';
import { relative_dates, RelativeDate } from '../../../../utils/date';
import formatCubeQuery from '../../../../utils/formatCubeQuery';
import Badge from '../../../common/base/Badge';
import Button, { ButtonGroup } from '../../../common/base/Button';
import Divider from '../../../common/base/Divider';
import Skeleton from '../../../common/base/Skeleton';
import Tooltip from '../../../common/base/Tooltip';
import BulkRetryDropdown from '../../../common/BulkRetryDropdown';
import EmptyState from '../../../common/EmptyState';
import {
  request_list_filters,
  search_filter_component,
} from '../../../common/Filters/FilterComponents';
import Filters from '../../../common/Filters/Filters';
import ErrorBoundary from '../../../common/helpers/ErrorBoundary';
import { Div } from '../../../common/helpers/StyledUtils';
import { useToasts } from '../../../common/Toast';
import { GlobalContext } from '../../../contexts/GlobalContext';
import { useCubeQueryLocalRawData } from '../../../hooks/useCubeQueryLocalRawData';
import useRequestList from '../../../hooks/useRequestList';
import useSearchQuery from '../../../hooks/useSearchQuery';
import {
  StyledViewActions,
  StyledViewContent,
  StyledViewLayout,
  StyledViewNav,
  StyledViewNavHeader,
  StyledViewNavSecondary,
  ViewTitle,
} from '../../../layouts/ViewLayout';
import { DashboardContext } from '../DashboardContext';
import Histogram from '../Events/Histogram';
import useRequestEntriesList from '../Request/useRequestEntriesList';
import { ViewsContext } from '../ViewsContext';
import EntrySidebar from './EntrySidebar';
import RequestSidebar from './RequestSidebar';
import RequestsList from './RequestsList';

export const extractFiltersFromQuery = (
  parsed_query: RequestListQueryParams,
): RequestListFiltersProps => {
  let date = extractFromArray(parsed_query.date);
  if (typeof date === 'string') {
    const { min, max } = relative_dates[date].convert(new Date());
    date = {
      relative: date,
      min: min?.toISOString(),
      max: max?.toISOString(),
    };
  } else {
    date = {
      max: extractFromArray(parsed_query.date?.max) as string,
      min: extractFromArray(parsed_query.date?.min) as string,
    };
  }
  return {
    search_term: extractFromArray(parsed_query.search_term) as string,
    bulk_retry_id: toArray(parsed_query.bulk_retry_id || []) as string[],
    source_id: toArray(parsed_query.source_id || []) as string[],
    date,
    request: {
      headers: extractFromArray(parsed_query.request?.headers) as string,
      body: extractFromArray(parsed_query.request?.body) as string,
      path: extractFromArray(parsed_query.request?.path) as string,
      parsed_query: extractFromArray(parsed_query.request?.parsed_query) as string,
    },
    ignored_count: {
      max: extractFromArray(parsed_query.ignored_count?.max) as string,
      min: extractFromArray(parsed_query.ignored_count?.min) as string,
    },
    events_count: {
      max: extractFromArray(parsed_query.events_count?.max) as string,
      min: extractFromArray(parsed_query.events_count?.min) as string,
    },
    cli_events_count: {
      max: extractFromArray(parsed_query.cli_events_count?.max) as string,
      min: extractFromArray(parsed_query.cli_events_count?.min) as string,
    },
    status: parsed_query.status,
    rejection_cause: toArray(parsed_query.rejection_cause || []) as string[],
    next: extractFromArray(parsed_query.next) as string,
    prev: extractFromArray(parsed_query.prev) as string,
  };
};

const extractFiltersFromView = (request_view: RequestView): RequestListFiltersProps => {
  const filters = request_view.filters;
  return {
    ...filters,
    date: {
      relative: extractFromArray(filters.date?.relative) as RelativeDate,
      max: extractFromArray(filters.date?.max) as string,
      min: extractFromArray(filters.date?.min) as string,
    },
  };
};

const extractFiltersForList = (filters: RequestListFiltersProps): RequestListFiltersProps => {
  if (filters.date?.relative) {
    const { min, max } = relative_dates[filters.date?.relative].convert(new Date());
    return {
      ...filters,
      date: {
        min: min.toISOString(),
        max: max.toISOString(),
      },
    };
  }
  return filters;
};

export const filters_initial_state = extractFiltersFromQuery({});

const order_by = 'ingested_at';
const dir = 'desc';

const RequestsView: React.FC<{ current_view?: RequestView }> = ({ current_view }) => {
  const theme = useTheme();
  const { query, updateSearchQuery } = useSearchQuery<RequestListQueryParams>();
  const { HookdeckAPI } = useContext(GlobalContext);
  const { subscription, organization, has_connection, has_created_connections } =
    useContext(DashboardContext);
  const [list_render_key, setRenderKey] = useState(Date.now());
  const [ignore_current_view, setIgnoreCurrentView] = useState(false);
  const { addToast } = useToasts();

  const { views, createView, updateView, deleteView, renameView, duplicateView } =
    useContext(ViewsContext);

  const selected_request_id = extractFromArray(query.selected_request_id) as string;
  const selected_entry_id = extractFromArray(query.selected_entry_id) as string;

  const enable_payload_search =
    organization!.feature_flags?.clickhouse_payload_search || process.env.CLICKHOUSE_PAYLOAD_SEARCH;

  const filter_components = enable_payload_search
    ? [search_filter_component, ...request_list_filters]
    : request_list_filters;

  const filters = useMemo(() => {
    let new_filters = extractFiltersFromQuery(query);
    if (current_view && !ignore_current_view) {
      const should_use_query = Object.values(filter_components).some(
        (v) => query[v.filter_key] !== undefined,
      );
      if (!should_use_query) {
        const request_view_filters = extractFiltersFromView(current_view);
        new_filters = {
          ...filters_initial_state,
          ...request_view_filters,
          next: new_filters.next,
          prev: new_filters.prev,
        };
      }
    }
    return new_filters;
  }, [query, current_view, ignore_current_view]);

  const filters_active_count = useMemo(
    () =>
      filter_components.reduce((count, f) => {
        if (f.isActive(f.formatForForm(filters[f.filter_key], filters))) {
          count++;
        }
        return count;
      }, 0),
    [filters],
  );

  const list_data = useRequestList(extractFiltersForList(filters), order_by, dir);

  const request = selected_request_id
    ? list_data.requests && list_data.requests.find((request) => request.id === selected_request_id)
    : undefined;
  const entries_list = useRequestEntriesList(request, 10);

  const latest_request = list_data.requests.length > 0 && list_data.requests[0];

  const [stats_start_date, stats_end_date] = useMemo(() => {
    const now = new Date();
    if (filters.date?.relative) {
      const { min, max } = relative_dates[filters.date?.relative].convert(new Date());
      filters.date.min = min.toISOString();
      filters.date.max = max.toISOString();
    }
    const end_date = setMilliseconds(
      filters.date?.max ? new Date(filters.date?.max) : add(startOfHour(now), { hours: 1 }),
      0,
    );
    const start_date = setMilliseconds(
      filters.date?.min
        ? new Date(filters.date?.min)
        : sub(add(startOfHour(now), { hours: 1 }), { days: 1 }),
      0,
    );
    return [start_date, end_date];
  }, [
    JSON.stringify(filters),
    subscription?.retention_days,
    list_render_key,
    latest_request && latest_request.id,
  ]);

  const cube_query = useMemo(
    () =>
      formatCubeQuery('Requests', {
        filters: {
          ...filters,
          date: { min: stats_start_date.toISOString(), max: stats_end_date.toISOString() },
        },
      }),
    [filters, filters.date?.min, filters.date?.max],
  );

  const { raw_data, is_loading, refetch } = useCubeQueryLocalRawData<Record<string, any>[]>(
    cube_query,
    {
      resetResultSetOnChange: false,
    },
  );

  const has_request_filter = Object.values(filters.request || {}).some((v) => !!v);
  const total_count = raw_data && raw_data.length > 0 ? raw_data[0]['Requests.count'] : null;

  const onRequestSelected = useCallback(
    (selected_request_id: string) =>
      updateSearchQuery({ selected_request_id }, { remove_keys: ['selected_entry_id'] }),
    [updateSearchQuery],
  );
  const onEntrySelected = useCallback(
    (selected_entry_id: string) => updateSearchQuery({ selected_entry_id }),
    [updateSearchQuery],
  );

  const onFilterChanged = (new_filters: RequestListFiltersProps) => {
    HookdeckAPI.track.event('Filtered Request List', new_filters);
    if (current_view) {
      setIgnoreCurrentView(true);
    }
    updateSearchQuery(new_filters, {
      remove_keys: ['selected_entry_id', 'selected_request_id', 'next', 'prev'],
      replace: true,
    });
  };

  useEffect(() => {
    setIgnoreCurrentView(false);
  }, [current_view]);

  const onPaginationChanged = useCallback(
    (new_filters) => {
      const pagination = { next: new_filters.next, prev: new_filters.prev };
      updateSearchQuery(
        { ...pagination },
        {
          remove_keys: ['selected_entry_id', 'selected_request_id'],
        },
      );
    },
    [updateSearchQuery],
  );

  const setDate = (date) => {
    if (!date.relative) {
      delete date.relative;
    }
    updateSearchQuery({
      ...filters,
      date: date.relative ? date.relative : date,
    });
  };

  const handleCreateView = (values) => {
    createView(
      filter_components.reduce((object, { form_name, formatForDB, isActive }) => {
        if (!isActive(values[form_name])) return object;
        return {
          ...object,
          ...formatForDB(values[form_name]),
        };
      }, {}),
    );
  };

  const handleUpdateView = (values) => {
    updateView(
      current_view!.id,
      filter_components.reduce((object, { form_name, formatForDB, isActive }) => {
        if (!isActive(values[form_name])) return object;
        return {
          ...object,
          ...formatForDB(values[form_name]),
        };
      }, {}),
    );
  };

  const handleRetry = (request_id: string, webhook_ids?: string[]) => {
    return HookdeckAPI.requests
      .retry(request_id, webhook_ids)
      .then(({ request, events }) => {
        addToast('success', `Request has been retried and ${events.length} events were created.`);
        list_data.refresh(request);
        entries_list.revalidate(events);
        return { request, events };
      })
      .catch((e) => {
        addToast('error', 'Request could not be retried');
        throw e;
      });
  };

  return (
    <StyledViewLayout>
      <Filters.Provider
        route="requests"
        filters={filters}
        unstructured_component={enable_payload_search ? search_filter_component : undefined}
        components={request_list_filters}
        onFilterChanged={onFilterChanged}>
        <StyledViewContent>
          <StyledViewNav>
            <StyledViewNavHeader>
              <Div flex={{ align: 'center', justify: 'space-between', gap: 2 }}>
                <ViewTitle
                  title="Requests"
                  tooltip="Events are outgoing requests to destinations you’ve configured. Each event is associated with a connection."
                  link={LINKS.product_docs.requests}
                />
                {!total_count && !has_request_filter ? (
                  <Skeleton variant={'square'} h={{ px: 20 }} />
                ) : (
                  <Badge neutral>
                    {has_request_filter ? (
                      'Ø'
                    ) : (
                      <NumberFormat
                        renderText={(v) => v}
                        displayType={'text'}
                        value={has_request_filter ? 'Ø' : total_count}
                        thousandSeparator={','}
                      />
                    )}
                  </Badge>
                )}
              </Div>
              <StyledViewActions>
                <Button
                  icon="reload"
                  neutral
                  onClick={() => {
                    updateSearchQuery(
                      {},
                      {
                        remove_keys: ['prev', 'next', 'selected_request_id', 'selected_entry_id'],
                      },
                    );
                    list_data.refresh();
                    setRenderKey(Date.now());
                    if (!is_loading) {
                      refetch();
                    }
                  }}>
                  Refresh
                </Button>
                <BulkRetryDropdown
                  outline
                  model="requests"
                  filters={filters}
                  order_by={order_by}
                  dir={dir}
                />
                <Div>
                  {current_view?.id === 'cli' ? (
                    <Tooltip align="right" tooltip="CLI views are not editable">
                      <Button disabled neutral icon={'save'}>
                        Save View
                      </Button>
                    </Tooltip>
                  ) : !current_view ? (
                    views && views?.count > 249 ? (
                      <Tooltip align="right" tooltip="Views limit reached">
                        <Button disabled neutral icon={'save'}>
                          Save View
                        </Button>
                      </Tooltip>
                    ) : (
                      <Button
                        neutral
                        disabled={filters_active_count === 0}
                        onClick={() => {
                          handleCreateView(filters);
                        }}
                        icon={'save'}>
                        Save View
                      </Button>
                    )
                  ) : (
                    <ButtonGroup
                      more_options={[
                        {
                          label: 'Save as New View',
                          disabled: views && views.count > 249,
                          tooltip: views && views.count > 249 ? 'Views limit reached' : undefined,
                          onClick: () => {
                            handleCreateView(filters);
                          },
                        },
                        {
                          label: 'Rename View',
                          onClick: () => renameView(current_view.id),
                        },
                        {
                          label: 'Duplicate View',
                          onClick: () => duplicateView(current_view.id),
                        },
                        {
                          label: 'Delete View',
                          danger: true,
                          onClick: () => {
                            deleteView(current_view.id);
                          },
                        },
                      ]}>
                      <Button
                        neutral
                        onClick={() => {
                          handleUpdateView(filters);
                        }}
                        disabled={filters_active_count === 0}
                        icon={'save'}>
                        Save View
                      </Button>
                    </ButtonGroup>
                  )}
                </Div>
              </StyledViewActions>
            </StyledViewNavHeader>
            <StyledViewNavSecondary style={{ border: 'none', paddingLeft: 0, paddingRight: 0 }}>
              <Div w={100}>
                <Filters.Buttons />
                <Divider />
              </Div>
            </StyledViewNavSecondary>
          </StyledViewNav>
          <Filters.Form />
          {!has_connection ? (
            <EmptyState
              title="Inspect inbound requests"
              description="Requests are inbound HTTP requests from sources you’ve configured. Hookdeck creates events, outgoing requests queued to a corresponding destination, from your requests. In order to explore and monitor requests, create your first connection."
              asset={`/images/empty/requests-${theme.mode}.svg`}
              cta={{
                label: 'Create connection',
                icon: 'add_circle',
                to: has_created_connections ? '/connections/new' : '/create-first-connection',
              }}
            />
          ) : (
            <>
              <ErrorBoundary>
                <Histogram
                  dimention="rejectionCause"
                  model="Requests"
                  key={list_render_key}
                  start_date={stats_start_date}
                  end_date={stats_end_date}
                  filters={filters}
                  setDate={setDate}
                  refresh_key={latest_request?.[order_by]}
                />
                <Divider />
              </ErrorBoundary>
              <RequestsList
                {...list_data}
                entries_list={entries_list}
                refresh={(request?: Request) => {
                  list_data.refresh(request);
                  if (!is_loading) {
                    refetch();
                  }
                }}
                retry={handleRetry}
                filters={filters}
                order_by={order_by}
                onEntrySelected={onEntrySelected}
                onRequestSelected={onRequestSelected}
                selected_entry_id={selected_entry_id}
                selected_request_id={selected_request_id}
                onPaginationChanged={onPaginationChanged}
                total_count={total_count}
              />
            </>
          )}
        </StyledViewContent>
      </Filters.Provider>
      {selected_request_id &&
        (selected_entry_id ? (
          <EntrySidebar
            request_id={selected_request_id}
            entry_id={selected_entry_id}
            retry={(request_id, webhook_ids) =>
              handleRetry(request_id, webhook_ids).then(({ request, events }) => {
                const event = events.find((e) => e.webhook_id === webhook_ids[0]);
                if (event) {
                  updateSearchQuery({ selected_entry_id: event.id });
                }
              })
            }
            onClose={() => {
              updateSearchQuery({}, { remove_keys: ['selected_request_id', 'selected_entry_id'] });
            }}
          />
        ) : (
          <RequestSidebar
            list_request={request}
            request_id={selected_request_id}
            retry={handleRetry}
            onClose={() => {
              updateSearchQuery({}, { remove_keys: ['selected_request_id'] });
            }}
          />
        ))}
    </StyledViewLayout>
  );
};

export default RequestsView;
