import { useContext } from 'react';
import { useLocation } from 'react-router';
import { useHistory } from 'react-router-dom';
import useSWR from 'swr';

import { TransformationIssueWithData } from '../../../../../../../typings/Issue.interface';
import APIMethodKeys from '../../../../client/APIMethodKeys';
import LINKS from '../../../../configs/links';
import { extractFromArray, stringifyQuery } from '../../../../utils';
import { RelativeDate } from '../../../../utils/date';
import Button from '../../../common/base/Button';
import { StyledCard, StyledCardSection } from '../../../common/base/Card';
import Link from '../../../common/base/Link';
import Loading from '../../../common/base/Loading';
import Tabs from '../../../common/base/Tabs';
import Text from '../../../common/base/Text';
import BulkRetryProgress from '../../../common/BulkRetryDropdown/BulkRetryProgress';
import { Div } from '../../../common/helpers/StyledUtils';
import RequestPayload from '../../../common/Request/RequestBody';
import { GlobalContext } from '../../../contexts/GlobalContext';
import useSearchQuery from '../../../hooks/useSearchQuery';
import Console from '../Connections/Forms/TransformRuleForm/Console';
import Histogram from '../Events/Histogram';
import TransformationExecutionsList from '../Transformation/TransformationExecutionsList';

interface Query {
  tab: string;
  selected_execution_id?: string | string[];
  next?: string;
  prev?: string;
}

const TransformationIssue: React.FC<{
  issue: TransformationIssueWithData;
  setDate: (query_date: object) => void;
  start_date: Date;
  end_date: Date;
  relative_date?: RelativeDate;
  refresh_key: string;
}> = ({ issue, start_date, end_date, setDate, relative_date, refresh_key }) => {
  const { HookdeckAPI } = useContext(GlobalContext);
  const history = useHistory();
  const location = useLocation();

  const { query, updateSearchQuery } = useSearchQuery<Query>();

  const is_fatal = issue.aggregation_keys.log_level.includes('fatal');
  const tab = !is_fatal ? 'executions' : query.tab || 'ignored';

  const { transformation_execution } = issue.data;

  const selected_execution_id =
    (extractFromArray(query.selected_execution_id) as string) || transformation_execution.id;

  const { data: transformation } = useSWR(
    APIMethodKeys.transformations.get(transformation_execution.transformation_id),
    () => HookdeckAPI.transformations.get(transformation_execution.transformation_id),
  );

  const { data: execution } = useSWR(
    selected_execution_id &&
      APIMethodKeys.transformations.getExecution(
        transformation_execution.transformation_id,
        selected_execution_id,
      ),
    () =>
      HookdeckAPI.transformations.getExecution(
        transformation_execution.transformation_id,
        selected_execution_id,
      ),
  );

  const filters = {
    issue_id: issue.id,
    transformation_id: transformation_execution.transformation_id,
    date: {
      relative: relative_date,
      min: start_date.toISOString(),
      max: end_date.toISOString(),
    },
  };

  const { data: current_bulk_retries } = useSWR(
    APIMethodKeys.bulk.ignored_events.list({
      query: { transformation_id: issue.aggregation_keys.transformation_id[0] },
      query_partial_match: true,
      in_progress: true,
    }),
    () =>
      HookdeckAPI.bulk.events.list({
        query: { transformation_id: issue.aggregation_keys.transformation_id[0] },
        query_partial_match: true,
        in_progress: true,
      }),
    { refreshInterval: 5000 },
  );

  const current_bulk_retry =
    current_bulk_retries?.models &&
    current_bulk_retries.models.length > 0 &&
    current_bulk_retries?.models[0];

  if (!transformation) {
    return (
      <Div flex={{ justify: 'center' }} p={8}>
        <Loading />
      </Div>
    );
  }

  return (
    <>
      {current_bulk_retry && (
        <StyledCard m={{ b: 4 }}>
          <StyledCardSection p={4}>
            <Text>Bulk retry in progress</Text>
          </StyledCardSection>
          <StyledCardSection p={4}>
            <BulkRetryProgress model="ignored_events" bulk_retry={current_bulk_retry} />
          </StyledCardSection>
        </StyledCard>
      )}
      <StyledCard>
        {is_fatal && (
          <StyledCardSection p={{ x: 4, t: 2 }}>
            <Tabs
              compact
              border={false}
              active_tab={tab}
              tabs={[
                { label: 'Ignored Events', key: 'ignored' },
                { label: 'Executions', key: 'executions' },
              ]}
              onTabSelected={(tab) => updateSearchQuery({ tab })}
            />
          </StyledCardSection>
        )}
        {tab === 'executions' && (
          <>
            <StyledCardSection>
              <Histogram
                model="EventRequestsTransformations"
                dimention="log_level"
                filters={filters}
                start_date={start_date}
                end_date={end_date}
                setDate={setDate}
                allow_reset={false}
                refresh_key={refresh_key}
              />
            </StyledCardSection>
            <StyledCardSection p={{ x: 4, y: 3 }} flex={{ justify: 'space-between' }}>
              <Text muted>Individual executions of the transformation.</Text>
              <Link
                to={{
                  pathname: `/transformations/${transformation.id}/executions`,
                  search: stringifyQuery({ log_level: issue.aggregation_keys.log_level }),
                  state: {
                    from: location,
                  },
                }}>
                View Executions {'->'}
              </Link>
            </StyledCardSection>
          </>
        )}
        {tab === 'ignored' && (
          <>
            <StyledCardSection>
              <Histogram
                model="IgnoredEvents"
                dimention="cause"
                filters={{ date: filters.date, cause: ['TRANSFORMATION_FAILED'] }}
                start_date={start_date}
                end_date={end_date}
                setDate={setDate}
                allow_reset={false}
                refresh_key={refresh_key}
              />
            </StyledCardSection>
            <StyledCardSection p={{ x: 4, y: 3 }} flex={{ justify: 'space-between' }}>
              <Text muted>Events that were ignored because of a fatal execution errors.</Text>
              <Link href={LINKS.product_docs.ignored_events} target="_blank">
                What are ignored events?
              </Link>
            </StyledCardSection>
          </>
        )}
      </StyledCard>
      <Text bold size="l" m={{ t: 16, b: 4 }} flex={{ align: 'center', justify: 'space-between' }}>
        Execution Details
      </Text>
      <StyledCard overflow_hidden>
        <StyledCardSection p={{ x: 4, y: 2 }} flex={{ justify: 'space-between', align: 'center' }}>
          <Text semi>Latest Executions</Text>
          <Button
            icon="link"
            invisible
            small
            to={`/transformations/${transformation.id}/executions`}>
            View All
          </Button>
        </StyledCardSection>
        <StyledCardSection>
          <TransformationExecutionsList
            transformation_id={transformation.id}
            selected_execution_id={selected_execution_id}
            filters={{
              ...filters,
              next: query.next,
              prev: query.prev,
              limit: 5,
            }}
            onExecutionSelected={(selected_execution_id) =>
              updateSearchQuery({ selected_execution_id })
            }
            onPaginationChanged={(pagination) =>
              updateSearchQuery({ ...pagination, selected_execution_id: undefined })
            }
            onEdit={(execution) =>
              history.push({
                search: execution && `?input_execution_id=${execution.id}`,
                pathname: `/transformations/${transformation.id}/edit`,
                state: { from: location },
              })
            }
          />
        </StyledCardSection>
      </StyledCard>
      {!execution ? (
        <div style={{ minHeight: '380px' }}>
          <Loading />
        </div>
      ) : (
        <>
          <Div m={{ t: 13 }}>
            <RequestPayload label="Body" body={execution.original_event_data || ''} />
          </Div>
          <StyledCard m={{ t: 13 }} overflow_hidden>
            <Console lines={execution.logs} />
          </StyledCard>
        </>
      )}
    </>
  );
};

export default TransformationIssue;
