import { format } from 'date-fns';
import { Form, Formik } from 'formik';
import { useContext } from 'react';
import { Prompt, Route, useHistory } from 'react-router';
import { useParams } from 'react-router-dom';
import useSWR from 'swr';

import IssueTrigger from '../../../../../../../../typings/IssueTrigger.interface';
import APIMethodKeys from '../../../../../client/APIMethodKeys';
import { capitalizeFirstLetter } from '../../../../../utils';
import { getCurrentTimezoneAbreviation } from '../../../../../utils/date';
import field_formats from '../../../../../utils/field-formatters';
import Badge from '../../../../common/base/Badge';
import Button from '../../../../common/base/Button';
import Container from '../../../../common/base/Container';
import Divider from '../../../../common/base/Divider';
import Icon from '../../../../common/base/Icon';
import Loading from '../../../../common/base/Loading';
import Tabs from '../../../../common/base/Tabs';
import Text from '../../../../common/base/Text';
import TextSwitch from '../../../../common/base/TextSwitch';
import { useDialog } from '../../../../common/Dialog';
import TextInput from '../../../../common/Form/Fields/TextInput';
import { Div } from '../../../../common/helpers/StyledUtils';
import { useToasts } from '../../../../common/Toast';
import { GlobalContext } from '../../../../contexts/GlobalContext';
import { TeamPermission } from '../../../../contexts/TeamPermissionContext';
import {
  StyledViewContent,
  StyledViewLayout,
  StyledViewNav,
  StyledViewNavHeader,
  StyledViewNavSecondary,
} from '../../../../layouts/ViewLayout';
import IssuesList from '../../Issues/IssueList';
import NotFound from '../../NotFound';
import { issue_trigger_types } from '../issue_trigger_types';
import issue_trigger_forms from '../IssueTriggersList/Forms/issue_triggers_forms';

const MetadataGrid = (props: { children: React.ReactNode }) => (
  <Div grid={{ columns: '45% 55%' }} {...props} />
);

const IssueTriggerView: React.FC = () => {
  const { id: issue_trigger_id } = useParams<{ id: string }>();
  const { HookdeckAPI } = useContext(GlobalContext);
  const { addToast } = useToasts();
  const showDialog = useDialog();
  const history = useHistory();

  const {
    data: issue_trigger,
    error,
    mutate,
  } = useSWR(APIMethodKeys.issue_triggers.get(issue_trigger_id), () =>
    HookdeckAPI.issue_triggers.get(issue_trigger_id),
  );

  const { data: issue_count, mutate: mutateCount } = useSWR(
    APIMethodKeys.issues.count({ status: 'OPENED', issue_trigger_id }),
    () => HookdeckAPI.issues.count({ status: 'OPENED', issue_trigger_id }),
  );

  const onIssueAction = async (action: string) => {
    mutateCount();
  };

  const { data: integrations } = useSWR(APIMethodKeys.team_integrations.list(), () =>
    HookdeckAPI.team_integrations.list(),
  );

  if (error && error.response?.status === 404) {
    return (
      <NotFound
        title="Issue trigger not found"
        description={`This issue trigger has been deleted or does not exist within this project.`}
        id={issue_trigger_id}
        link={{ to: `/issue-triggers`, text: 'All Triggers' }}
      />
    );
  }

  if (!issue_trigger || integrations === undefined) {
    return (
      <Div flex={{ justify: 'center', align: 'center' }} h={{ vh: 100 }}>
        <Loading />
      </Div>
    );
  }

  const onDelete = () => {
    showDialog(
      () => {
        HookdeckAPI.issue_triggers
          .delete(issue_trigger.id)
          .then(() => {
            addToast('success', 'Issue trigger has been deleted.');
            history.push('/issue-triggers');
          })
          .catch(() => {
            addToast('error', 'Issue trigger could not be deleted.');
          });
      },
      () => null,
      {
        title: 'Are you sure you want to delete this trigger?',
        message: 'All associated issues with this trigger will also be deleted.',
        danger: true,
      },
    );
  };

  const { Fields, getInitialValues, formatValuesToAPIInput, validate } =
    issue_trigger_forms[issue_trigger.type];

  const intial_values = getInitialValues(integrations.models, issue_trigger);

  const isOverviewTab = location.pathname.split('/').length === 3;
  const activeTab = isOverviewTab ? 'overview' : 'issues';

  return (
    <StyledViewLayout>
      <StyledViewContent light>
        <StyledViewNav>
          <StyledViewNavHeader>
            <Div flex={{ align: 'center', gap: 1 }}>
              <Text semi neutral size="l" ellipsis>
                Issue Triggers /
              </Text>
              <Text semi size="l" ellipsis>
                {issue_trigger_id}
              </Text>
            </Div>
          </StyledViewNavHeader>
          <StyledViewNavSecondary>
            <Tabs
              active_tab={activeTab}
              border={false}
              compact
              tabs={[
                {
                  key: 'overview',
                  label: 'Overview',
                  to: `/issue-triggers/${issue_trigger_id}`,
                },
                {
                  key: 'issues',
                  label: 'Triggered issues',
                  to: `/issue-triggers/${issue_trigger_id}/issues`,
                  badge_label: issue_count?.count || 0,
                  badge_theme: 'muted',
                },
              ]}
            />
          </StyledViewNavSecondary>
        </StyledViewNav>
        <Div p={{ y: 8 }}>
          <TeamPermission role="member">
            <Route exact path="/issue-triggers/:id">
              <Container left small>
                <Text bold size="l" m={{ b: 4 }}>
                  Details
                </Text>
                <Div flex={{ gap: 3, direction: 'column' }}>
                  <MetadataGrid>
                    <Text muted ellipsis>
                      Type
                    </Text>
                    <Badge neutral>
                      <Icon icon={issue_trigger_types[issue_trigger.type].icon} m={{ r: 1 }} />
                      {capitalizeFirstLetter(issue_trigger.type)}
                    </Badge>
                  </MetadataGrid>
                  <MetadataGrid>
                    <Text muted ellipsis>
                      ID
                    </Text>
                    <Text>{issue_trigger.id}</Text>
                  </MetadataGrid>
                  <MetadataGrid>
                    <Text muted ellipsis>
                      Name
                    </Text>
                    <Text>{issue_trigger.name || '---'}</Text>
                  </MetadataGrid>
                  <MetadataGrid>
                    <Text muted ellipsis>
                      Status
                    </Text>
                    <Badge
                      subtle
                      neutral={!!issue_trigger.disabled_at}
                      success={!issue_trigger.disabled_at}>
                      <Icon
                        icon={issue_trigger.disabled_at ? 'notification_off' : 'notification'}
                        m={{ r: 1 }}
                      />
                      {issue_trigger.disabled_at ? 'Disabled' : 'Enabled'}
                    </Badge>
                  </MetadataGrid>
                  <MetadataGrid>
                    <Text muted ellipsis>
                      Created at
                    </Text>
                    <Text>
                      {issue_trigger.created_at
                        ? `${format(new Date(issue_trigger.created_at), 'MMMM d, yyyy hh:mm:ss')} ${getCurrentTimezoneAbreviation()}`
                        : '---'}
                    </Text>
                  </MetadataGrid>
                  <MetadataGrid>
                    <Text muted ellipsis>
                      Last updated
                    </Text>
                    <Text>
                      {issue_trigger.updated_at
                        ? `${format(new Date(issue_trigger.updated_at), 'MMMM d, yyyy hh:mm:ss')} ${getCurrentTimezoneAbreviation()}`
                        : '---'}
                    </Text>
                  </MetadataGrid>
                </Div>
              </Container>
              <Div p={{ x: 8 }}>
                <Divider m={{ y: 10 }} />
              </Div>
              <Container left medium>
                <Text bold size="l" m={{ b: 4 }}>
                  Behavior
                </Text>
                <Formik
                  initialValues={intial_values}
                  validate={async (values) => {
                    let errors: { name?: string } = {};
                    if (validate) {
                      errors = validate(values);
                    }
                    return errors;
                  }}
                  onSubmit={(values, { resetForm }) => {
                    return HookdeckAPI.issue_triggers
                      .update(issue_trigger_id, formatValuesToAPIInput(values as any))
                      .then((issue_trigger) => {
                        addToast('success', 'Issue trigger updated');
                        mutate(issue_trigger);
                        resetForm({
                          values: getInitialValues(integrations.models, issue_trigger),
                          touched: {},
                        });
                        return issue_trigger;
                      })
                      .catch(() => {
                        addToast('error', 'Error updating issue trigger');
                        return null;
                      });
                  }}>
                  {({ isSubmitting, isValid, dirty }) => (
                    <Form>
                      <Prompt
                        when={!isSubmitting && dirty}
                        message="Are you sure you want to quit without saving your work?"
                      />
                      <Fields integrations={integrations.models} muted={false} />
                      <Button.Permission
                        m={{ t: 6 }}
                        disabled={isSubmitting || !isValid || !dirty}
                        submit
                        primary
                        icon="save">
                        Save
                      </Button.Permission>
                    </Form>
                  )}
                </Formik>
              </Container>
              <Div p={{ x: 8 }}>
                <Divider m={{ y: 10 }} />
              </Div>
              <Container left medium>
                <Text bold size="l" m={0}>
                  Trigger name
                </Text>
                <Text muted m={{ b: 4 }}>
                  Provide a name to programmatically query for this issue trigger within the API.
                </Text>
                <Formik
                  initialValues={{ name: issue_trigger.name }}
                  validate={async (values) => {
                    const errors: { name?: string } = {};
                    if (values.name && issue_trigger.name !== values.name) {
                      const used = await HookdeckAPI.issue_triggers.nameIsUsed(values.name);
                      if (used) {
                        errors.name = 'Name is already used.';
                      }
                    }
                  }}
                  onSubmit={(values, { resetForm }) => {
                    return HookdeckAPI.issue_triggers
                      .update(issue_trigger_id, { name: values.name || null })
                      .then((issue_trigger) => {
                        addToast('success', 'Issue trigger name updated');
                        mutate(issue_trigger);
                        resetForm({ values: { name: issue_trigger.name }, touched: {} });
                        return issue_trigger;
                      })
                      .catch(() => {
                        addToast('error', 'Error updating issue trigger name');
                        return null;
                      });
                  }}>
                  {({ isSubmitting, isValid, dirty, values }) => (
                    <Form>
                      <Prompt
                        when={!isSubmitting && dirty}
                        message="Are you sure you want to quit without saving your work?"
                      />
                      <TextInput
                        name="name"
                        format={field_formats.slugify}
                        placeholder="trigger-name"
                        m={{ b: 5 }}
                        mono
                      />
                      <Button.Permission
                        disabled={isSubmitting || !isValid || issue_trigger.name === values.name}
                        submit
                        primary
                        icon="save">
                        Save
                      </Button.Permission>
                    </Form>
                  )}
                </Formik>
              </Container>
              <Div p={{ x: 8 }}>
                <Divider m={{ y: 10 }} />
              </Div>
              <Container left medium>
                <Text bold size="l" m={0}>
                  Status
                </Text>
                <Text muted m={{ b: 4 }}>
                  Disabled triggers will not communicate any associated issues and alerts.
                </Text>
                <TextSwitch
                  onSelect={(key: 'true' | 'false') => {
                    let statusPromise: Promise<any> | null = null;
                    if (key === 'true' && issue_trigger.disabled_at) {
                      statusPromise = HookdeckAPI.issue_triggers.enable(issue_trigger_id);
                    } else if (key === 'false' && !issue_trigger.disabled_at) {
                      statusPromise = HookdeckAPI.issue_triggers.disable(issue_trigger_id);
                    }

                    if (!statusPromise) {
                      return null;
                    }

                    return statusPromise
                      .then((issue_trigger: IssueTrigger) => {
                        addToast(
                          'success',
                          `Issue trigger ${!issue_trigger.disabled_at ? 'enabled' : 'disabled'}`,
                        );
                        mutate(issue_trigger);
                        return issue_trigger;
                      })
                      .catch(() => {
                        addToast('error', 'Error updating issue trigger');
                        return null;
                      });
                  }}
                  active={issue_trigger.disabled_at ? 'false' : 'true'}
                  options={[
                    { key: 'true', label: 'Enabled' },
                    { key: 'false', label: 'Disabled' },
                  ]}
                />
              </Container>
              <Div p={{ x: 8 }}>
                <Divider m={{ y: 10 }} />
              </Div>
              <Container left medium>
                <Text bold size="l" m={0}>
                  Delete trigger
                </Text>
                <Text muted m={{ b: 4 }}>
                  Delete this trigger and all associated issues.
                </Text>
                <Button.Permission danger icon="delete" onClick={onDelete}>
                  Delete trigger
                </Button.Permission>
              </Container>
            </Route>
            <Route exact path="/issue-triggers/:id/issues">
              <IssuesList
                issue_trigger_id={issue_trigger_id}
                sort_by="first_seen_at"
                onAction={onIssueAction}
              />
            </Route>
          </TeamPermission>
        </Div>
      </StyledViewContent>
    </StyledViewLayout>
  );
};

export default IssueTriggerView;
