import jsonToTS from 'json-to-ts';
import { useState } from 'react';
import { useTheme } from 'styled-components';

import { EventAttemptResponseBody } from '../../../../../../../typings/EventAttemptResponse.interface';
import { capitalizeFirstLetter } from '../../../../utils';
import { copyTextToClipboard } from '../../../../utils/copy';
import jsonToGo from '../../../../utils/type-extract/go';
import Button from '../../../common/base/Button';
import { StyledCard, StyledCardSection } from '../../../common/base/Card';
import Link from '../../../common/base/Link';
import Loading from '../../../common/base/Loading';
import Text from '../../../common/base/Text';
import DropdownMenu from '../../../common/DropdownMenu';
import Editor, { EditorLanguage } from '../../../common/Editor';
import { Div } from '../../../common/helpers/StyledUtils';
import JSONBrowser from '../../../common/JSONBrowser';
import Search from '../../../common/Search';
import { useToasts } from '../../../common/Toast';
import useLocalStorage from '../../../hooks/useLocalStorage';

type Props = { text: string | object; lang?: EditorLanguage };

const RawBody: React.FC<{
  loading: boolean;
  body: EventAttemptResponseBody;
  compact?: boolean;
}> = ({ loading, body, compact }) => {
  if (loading) {
    return <Loading />;
  }

  if (body === undefined || body === '' || body === null) {
    return (
      <Text muted capitalize mono size={compact ? 's' : 'm'}>
        {body === '' ? 'Empty' : String(body)}
      </Text>
    );
  }

  return (
    <Text
      as="span"
      white_space={'pre-wrap'}
      word_break={'break-all'}
      mono
      p={{ y: 4 }}
      size={compact ? 's' : 'm'}>
      {String(body)}
    </Text>
  );
};

const TypesBody: React.FC<{ types: string | null; compact: boolean }> = ({ types, compact }) => {
  return types ? (
    <Editor
      language="typescript"
      value={types}
      height={`${types.split('\n').length * (compact ? 18 : 20) + 32}px`}
      small_text={compact}
      disabled={true}
      scroll={false}
    />
  ) : (
    <Div flex={{ justify: 'center' }}>
      <Loading />
    </Div>
  );
};

const LangEditor: React.FC<{ lang: EditorLanguage; content: string }> = ({ lang, content }) => {
  return lang ? (
    <Editor
      language={lang}
      value={content}
      height={`${content.split('\n').length * 20 + 32}px`}
      disabled={true}
      scroll={false}
    />
  ) : (
    <Div flex={{ justify: 'center' }}>
      <Loading />
    </Div>
  );
};

const display_options = [
  { label: 'Pretty', key: 'pretty' },
  { label: 'Raw', key: 'raw' },
  { label: 'Go', key: 'golang' },
  { label: 'TypeScript', key: 'typescript' },
];
type DisplayOptionType = 'pretty' | 'raw' | 'golang' | 'typescript' | 'lang';

export const CodeBlock: React.FC<Props> = ({ text, lang }) => {
  const { addToast } = useToasts();
  const [search_value, setSearchValue] = useState('');
  const theme = useTheme();

  const [force_display, setForceDisplay] = useState(false);
  const [display_type, setBodyDisplayType] = useLocalStorage<DisplayOptionType>(
    'pref:code-block-display-type',
    'pretty',
  );
  const is_json = text && typeof text === 'object';
  const body_type = lang ? 'lang' : display_type || 'pretty';

  let data_to_display: EventAttemptResponseBody = text;
  if (body_type === 'pretty') {
    data_to_display = JSON.stringify(text, null, 2);
  } else if (body_type === 'raw') {
    data_to_display = JSON.stringify(text).includes(search_value) ? JSON.stringify(text) : '';
  } else if (body_type === 'typescript') {
    data_to_display = jsonToTS(text, { rootName: 'Body' })
      .filter((ts_interface: string) => {
        return !search_value || ts_interface.includes(search_value);
      })
      .join('\n');
  } else if (body_type === 'golang') {
    data_to_display = jsonToGo(JSON.stringify(text), 'Body', true)
      .go.split('\n')
      .filter((line: string) => {
        return !search_value || line.includes(search_value);
      })
      .join('\n');
  }

  const too_large_to_display =
    data_to_display === 'string'
      ? data_to_display.length > 1_000_000
      : JSON.stringify(text)?.length > 1_000_000;

  return (
    <Div flex={{ direction: 'column', gap: 4 }}>
      <Div flex={{ gap: 3 }}>
        <Search
          w={{ px: 468 }}
          value={search_value}
          onChange={setSearchValue}
          placeholder="Filter"
        />
        {!lang && (
          <DropdownMenu
            w={{ px: 164 }}
            label={capitalizeFirstLetter(body_type)}
            outline
            placement="bottom-end"
            expand_icon="chevron_down"
            options={display_options.map((option) => ({
              ...option,
              selected: option.key === body_type,
              onClick: () => setBodyDisplayType(option.key as DisplayOptionType),
            }))}
          />
        )}
        <Button
          neutral
          icon="copy"
          onClick={() => {
            copyTextToClipboard(String(data_to_display) as string);
            addToast('success', `Copied to clipboard`);
          }}
        />
      </Div>
      <StyledCard overflow_hidden>
        <StyledCardSection
          muted
          style={{ backgroundColor: theme.colors.surface.base.variant_surface_3 }}
          p={
            ['typescript', 'golang'].includes(body_type)
              ? { y: 1, x: 2 }
              : body_type === 'lang'
                ? 2
                : 6
          }>
          {too_large_to_display && !force_display ? (
            <Div flex>
              <Text>Payload is more then 1MB and is too large to display properly.</Text>
              <Link m={{ l: 1 }} primary onClick={() => setForceDisplay(true)}>
                Display anyway
              </Link>
            </Div>
          ) : (
            <>
              {body_type === 'lang' && <LangEditor lang={lang!} content={text as string} />}
              {body_type === 'pretty' && (
                <JSONBrowser search_term={search_value} json={text as any} />
              )}
              {body_type === 'raw' && <RawBody body={data_to_display} loading={false} />}
              {is_json && ['typescript', 'golang'].includes(body_type) && (
                <TypesBody compact={false} types={String(data_to_display)} />
              )}
            </>
          )}
        </StyledCardSection>
      </StyledCard>
    </Div>
  );
};
