import { useContext, useState } from 'react';
import { useHistory } from 'react-router';
import { useTheme } from 'styled-components';

import { stringifyQuery } from '../../../../utils';
import isEmpty from '../../../../utils/isEmpty';
import Button from '../../../common/base/Button';
import Icon from '../../../common/base/Icon';
import Tabs from '../../../common/base/Tabs';
import Text from '../../../common/base/Text';
import Tooltip from '../../../common/base/Tooltip';
import EmptyState from '../../../common/EmptyState';
import { Div } from '../../../common/helpers/StyledUtils';
import useSearchQuery from '../../../hooks/useSearchQuery';
import {
  StyledViewActions,
  StyledViewContent,
  StyledViewLayout,
  StyledViewNav,
  StyledViewNavHeader,
  StyledViewNavSecondary,
} from '../../../layouts/ViewLayout';
import { DashboardContext } from '../DashboardContext';
import ExportDropdown from './ExportDropdown';
import MetricCategoryView, { useMetricsFilters } from './MetricCategory';
import MetricDatePicker, { DateRange, useDateRange } from './MetricDatePicker';
import MetricsPaywall from './MetricsPaywall';

const MetricsView: React.FC = () => {
  const { has_connection, has_created_connections } = useContext(DashboardContext);
  const { query } = useSearchQuery<{
    date?: DateRange;
  }>();
  const [refresh_key, setRefreshKey] = useState(new Date().getTime());
  const [date_range, onDateRangeChanged] = useDateRange();
  const theme = useTheme();
  const { form, metric_category_key, metric_categories } = useMetricsFilters();
  const history = useHistory();
  const TABS = Object.entries(metric_categories).reduce((acc, [key, category]) => {
    acc[key] = { label: category.label, key };
    return acc;
  }, {});

  return (
    <StyledViewLayout>
      <StyledViewContent light>
        <StyledViewNav>
          <StyledViewNavHeader>
            <Div flex={{ align: 'center', justify: 'space-between', gap: 2 }}>
              <Text semi size="l" ellipsis>
                Metrics
              </Text>
              <Tooltip
                tooltip="Metrics enable you to track key trends and compare statistics across your sources, destinations, and connections."
                placement="bottom-end">
                <Icon icon="info" />
              </Tooltip>
            </Div>
            <StyledViewActions>
              <Button neutral icon="reload" onClick={() => setRefreshKey(new Date().getTime())}>
                Refresh
              </Button>
              {form}
              <MetricDatePicker date_range={date_range} onDateRangeChanged={onDateRangeChanged} />
              <ExportDropdown />
            </StyledViewActions>
          </StyledViewNavHeader>
          <StyledViewNavSecondary p={{ b: 0 }}>
            <Tabs
              active_tab={metric_category_key as string}
              onTabSelected={(tab) => {
                const query_params = !isEmpty(query?.date)
                  ? `?${stringifyQuery({ date: query.date })}`
                  : '';
                history.push(`/metrics/${tab}${query_params}`);
              }}
              compact
              border={false}
              tabs={Object.values(TABS)}
            />
          </StyledViewNavSecondary>
        </StyledViewNav>
        {!has_connection ? (
          <EmptyState
            title="Track key metrics"
            description="Metrics enable you to track key trends and compare statistics across your sources, destinations, and connections. In order to experience the benefits of Hookdeck’s metrics features, create a connection."
            asset={`/images/empty/metrics-${theme.mode}.svg`}
            cta={{
              label: 'Create connection',
              icon: 'add_circle',
              to: has_created_connections ? '/connections/new' : '/create-first-connection',
            }}
          />
        ) : (
          <>
            <MetricsPaywall />
            <MetricCategoryView key={refresh_key} />
          </>
        )}
      </StyledViewContent>
    </StyledViewLayout>
  );
};

export default MetricsView;
