import type { font_sizes } from '@hookdeck/theme';

import Text from '../../base/Text';
import { StyledUtilsProps } from '../../helpers/StyledUtils';
import { StyledCheckbox } from './CheckboxInput';

interface Props extends StyledUtilsProps {
  label: string | JSX.Element;
  name: string;
  checked: boolean;
  onChange(e: React.ChangeEvent<HTMLInputElement>): void;
  disabled?: boolean;
  mono?: boolean;
  size?: keyof typeof font_sizes;
}

const Checkbox: React.FC<Props> = ({ label, checked, name, onChange, mono, size, ...props }) => {
  return (
    <StyledCheckbox as="label" has_label={!!label} {...props}>
      <input
        name={name}
        type="checkbox"
        checked={checked}
        disabled={props.disabled}
        onChange={onChange}
      />
      {typeof label === 'string' ? (
        <Text as="span" size={size} mono={mono}>
          {label}
        </Text>
      ) : (
        label
      )}
    </StyledCheckbox>
  );
};

export default Checkbox;
