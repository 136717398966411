import { FC, useCallback, useContext, useState } from 'react';
import useSWR from 'swr';

import { APIBatchOperation } from '../../../../../../typings/BatchOperation.interface';
import APIMethodKeys from '../../../client/APIMethodKeys';
import { GlobalContext } from '../../contexts/GlobalContext';
import Badge from '../base/Badge';
import Button, { ClickableArea } from '../base/Button';
import { StyledCardSection } from '../base/Card';
import Icon from '../base/Icon';
import Loading from '../base/Loading';
import Text from '../base/Text';
import { Div } from '../helpers/StyledUtils';
import { BulkRetryModels } from '.';
import BulkRetryBreakdown from './BulkRetryBreakdown';
import BulkRetryProgress from './BulkRetryProgress';

const BulkRetryHistory: FC<{
  model: BulkRetryModels;
  filters: object;
  revalidateCounts: () => void;
}> = ({ model, filters, revalidateCounts }) => {
  const { HookdeckAPI } = useContext(GlobalContext);
  const [opened_id, setOpenedId] = useState<string>('');
  const [cursor, setCursor] = useState<{ prev?: string; next?: string }>({});
  const { data: bulk_retries, mutate } = useSWR(
    APIMethodKeys.bulk[model].list({ ...filters, ...cursor, limit: 5, dir: 'desc' }),
    () => HookdeckAPI.bulk[model].list({ ...filters, ...cursor, limit: 5, dir: 'desc' }),
    {
      refreshInterval: 5000,
    },
  );

  const onCancel = useCallback(
    (cancelled_bulk_retry: APIBatchOperation) => {
      bulk_retries &&
        mutate({
          ...bulk_retries,
          models: bulk_retries?.models.map((bulk_retry) =>
            cancelled_bulk_retry.id === bulk_retry.id ? cancelled_bulk_retry : bulk_retry,
          ),
        });
      revalidateCounts();
    },
    [bulk_retries, mutate],
  );

  const curriedToggleOpened = useCallback(
    (id: string) => () => setOpenedId(opened_id === id ? '' : id),
    [opened_id],
  );

  if (!bulk_retries) {
    return (
      <StyledCardSection p={5}>
        <Loading />
      </StyledCardSection>
    );
  }

  if (bulk_retries.models.length === 0) {
    return (
      <StyledCardSection p={4}>
        <Text muted>You don’t have any ongoing bulk retries.</Text>
      </StyledCardSection>
    );
  }

  return (
    <>
      {bulk_retries.models.map((bulk_retry) => (
        <StyledCardSection key={bulk_retry.id} muted={opened_id === bulk_retry.id}>
          <ClickableArea
            onClick={curriedToggleOpened(bulk_retry.id)}
            key={bulk_retry.id}
            w={100}
            flex={{ justify: 'space-between', align: 'center' }}
            p={4}>
            <Div flex={{ align: 'center' }}>
              <Icon
                icon={opened_id === bulk_retry.id ? 'expand_more' : 'expand_less'}
                muted
                left={3}
              />
              <Text semi dark>
                #{bulk_retry.number}
              </Text>
              <Text semi dark m={{ x: 4 }}>
                {new Date(bulk_retry.created_at).toLocaleDateString('en-US', {
                  month: 'short',
                  day: 'numeric',
                  hour: 'numeric',
                  minute: '2-digit',
                })}
              </Text>
              <Text muted mono>
                {bulk_retry.id}
              </Text>
            </Div>
            {bulk_retry.cancelled_at ? (
              <Badge subtle danger>
                Cancelled
              </Badge>
            ) : bulk_retry.completed_at ? (
              <Badge subtle success>
                {bulk_retry.completed_count}
              </Badge>
            ) : (
              <Badge subtle primary>
                {bulk_retry.completed_count}/{bulk_retry.estimated_count}
              </Badge>
            )}
          </ClickableArea>
          {opened_id === bulk_retry.id && (
            <Div p={{ x: 5, b: 5 }} m={{ t: 2 }}>
              <BulkRetryBreakdown query={bulk_retry.query} model={model} />
              <Div m={{ t: 3 }}>
                <BulkRetryProgress model={model} bulk_retry={bulk_retry} onCancel={onCancel} />
              </Div>
            </Div>
          )}
        </StyledCardSection>
      ))}
      <StyledCardSection flex={{ justify: 'flex-end', gap: 2 }} p={{ x: 4, y: 3 }}>
        <Button
          disabled={!bulk_retries.pagination.prev}
          onClick={() => setCursor({ prev: bulk_retries.pagination.prev, next: undefined })}
          neutral
          icon="chevron_left"
        />
        <Button
          disabled={!bulk_retries.pagination.next}
          onClick={() => setCursor({ next: bulk_retries.pagination.next, prev: undefined })}
          neutral
          icon="chevron_right"
        />
      </StyledCardSection>
    </>
  );
};

export default BulkRetryHistory;
