import { useEffect, useState } from 'react';

import { relative_dates, RelativeDate } from '../../../../utils/date';
import { StyledCard } from '../../base/Card';
import Skeleton from '../../base/Skeleton';
import Text from '../../base/Text';
import { Div, StyledUtilsProps } from '../../helpers/StyledUtils';
import { default_data_formatter, metric_definitions, MetricName } from '../metric-definitions';
import { MetricConfigs, useMetric } from '../useMetric';

interface Props extends StyledUtilsProps {
  metric: MetricName;
  refresh_key?: number;
  configs: MetricConfigs;
  action?: React.ReactNode;

  // props used by MetricCardGroup
  loading?: boolean;
  onLoad?: () => void;
}

const MetricCard = ({
  metric,
  refresh_key,
  configs,
  action,
  loading = false,
  onLoad,
  ...other_props
}: Props) => {
  const [_refresh, setRefresh] = useState(new Date().getTime());

  refresh_key = refresh_key || _refresh;

  const { data, error, rate_as } = useMetric<number>('card', metric, refresh_key, configs);

  useEffect(() => {
    if (data !== undefined) {
      onLoad && onLoad();
    }
  }, [data]);

  useEffect(() => {
    if (loading) {
      setRefresh(new Date().getTime());
    }
  }, [loading]);

  const { title, description: desc, formatDataForDisplay, type } = metric_definitions[metric];
  const description =
    desc ||
    rate_as ||
    relative_dates[configs?.date_range?.relative as RelativeDate]?.short_label ||
    'custom date range';

  const formated_data =
    data !== undefined
      ? formatDataForDisplay
        ? formatDataForDisplay(data)
        : default_data_formatter[type](data, rate_as || undefined)
      : null;

  loading = (data === undefined || loading) && !error;

  const show_action = !!data && !!action && !metric.includes('rate') && !metric.includes('average');

  return (
    <StyledCard p={{ x: 4, y: 3 }} {...other_props}>
      <Div h={{ px: 20 }} m={{ b: 1 }} flex={{ align: 'center', justify: 'space-between', gap: 2 }}>
        <Div
          flex={{ align: 'center', justify: 'flex-start', gap: 1 }}
          min_w={0}
          style={{ flexShrink: 1, overflow: 'hidden' }}>
          <Text title={title} semi ellipsis min_w={0} style={{ flexShrink: 0 }}>
            {title}
          </Text>
          {description && (
            <Text title={description} muted ellipsis min_w={0} style={{ flexShrink: 1 }}>
              / {description}
            </Text>
          )}
        </Div>
        {show_action && <Div style={{ flexShrink: 0 }}>{action}</Div>}
      </Div>
      {loading ? (
        <Div flex={{ justify: 'center', align: 'center' }} w={100}>
          <Skeleton variant={'rectangle'} h={{ px: 36 }} w={50} loading />
        </Div>
      ) : (
        <Text semi size="3xl" center>
          {
            data !== undefined
              ? formated_data
              : '⃠' /* ENCLOSING CIRCLE BACKSLASH unicode character */
          }
        </Text>
      )}
    </StyledCard>
  );
};

export default MetricCard;
