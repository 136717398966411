import { useField, useFormikContext } from 'formik';
import { useEffect, useState } from 'react';
import styled, { css } from 'styled-components';

import { getMsIntervalByUnit, INTERVAL_TIME_UNITS } from '../../../../utils/rules';
import { useTeamPermission } from '../../../contexts/TeamPermissionContext';
import Text from '../../base/Text';
import { Div, StyledUtilsProps } from '../../helpers/StyledUtils';
import SelectInput from './SelectInput';

interface Props extends StyledUtilsProps {
  label?: string;
  name: string;
  help?: string;
  type?: string;
  placeholder?: string;
  auto_complete?: string;
  required?: boolean;
  min_interval?: keyof typeof INTERVAL_TIME_UNITS;
}

const StyledField = styled(Div)(
  ({ theme }) => css`
    display: flex;
    max-width: 100%;
    input {
      padding: ${theme.spacing(1.25)} ${theme.spacing(2)};
      border-radius: ${theme.radius.normal};
      width: 44px;
      line-height: ${theme.pxToRem(theme.font_sizes.m.line_height)};

      &:focus {
        z-index: 1;
      }
    }
  `,
);

const IntervalInput: React.FC<Props> = ({
  label,
  name,
  placeholder,
  auto_complete,
  required,
  help,
  min_interval,
}) => {
  const { authorized } = useTeamPermission();
  const [, { value, error, touched }, { setValue, setTouched }] = useField(name);
  const { submitCount } = useFormikContext();
  const [time_unit, setTimeUnit] = useState('hours');
  useEffect(() => {
    if (value) {
      const [, time_unit] = getMsIntervalByUnit(value);
      setTimeUnit(time_unit);
    }
  }, [value]);
  return (
    <Div>
      {label && (
        <Text as="label" semi size="s" htmlFor={name}>
          {label}
        </Text>
      )}
      <Div flex={{ align: 'center', gap: 3 }}>
        <StyledField>
          <input
            type="number"
            placeholder={placeholder}
            autoComplete={auto_complete}
            required={required}
            disabled={!authorized}
            value={value ? value / INTERVAL_TIME_UNITS[time_unit] : ''}
            max={9999}
            onChange={(e) => {
              const value = parseInt(e.target.value);
              setTouched(true);
              if (value > 0) {
                setValue(parseInt(e.target.value) * INTERVAL_TIME_UNITS[time_unit]);
              } else {
                setValue('');
              }
            }}
          />
        </StyledField>
        <SelectInput
          m={0}
          name={'time_unit'}
          onChange={(unit) => {
            if (value) {
              setValue((value / INTERVAL_TIME_UNITS[time_unit]) * INTERVAL_TIME_UNITS[unit]);
            }
            setTimeUnit(unit);
          }}
          options={Object.keys(INTERVAL_TIME_UNITS)
            .filter(
              (unit) =>
                INTERVAL_TIME_UNITS[unit] >= (min_interval ? INTERVAL_TIME_UNITS[min_interval] : 0),
            )
            .map((unit) => ({
              label: unit,
              value: unit,
            }))}
        />
      </Div>
      {help && (
        <Text m={{ t: 1, b: 0 }} size="s" as="p" muted>
          {help}
        </Text>
      )}
      {(touched || submitCount > 0) && error && (
        <Text m={{ top: 1, bottom: 0 }} as="p" danger>
          {error}
        </Text>
      )}
    </Div>
  );
};

export default IntervalInput;
