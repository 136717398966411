import { Formik } from 'formik';
import { useCallback, useContext } from 'react';
import { useHistory } from 'react-router-dom';

import { CardElement } from '@stripe/react-stripe-js';

import { StyledCard } from '../../../../../common/base/Card';
import Loading from '../../../../../common/base/Loading';
import Text from '../../../../../common/base/Text';
import { Div } from '../../../../../common/helpers/StyledUtils';
import Modal from '../../../../../common/Modal';
import { useToasts } from '../../../../../common/Toast';
import { GlobalContext } from '../../../../../contexts/GlobalContext';
import WrapWithStripeElement, { useStripeElementOptions } from '../WrapWithStripeElement';
import Sentry from '../../../../../../client/Sentry';

const PaymentMethodModal: React.FC = () => {
  const { HookdeckAPI } = useContext(GlobalContext);
  const { addToast } = useToasts();
  const history = useHistory();

  const stripe_element_options = useStripeElementOptions();

  const onClose = useCallback(
    () =>
      history.push({
        pathname: '/settings/organization/billing',
        state: { scroll: false },
      }),
    [],
  );

  const handleSubmit = useCallback(async (elements, stripe, client_secret) => {
    try {
      const cardElement = elements.getElement(CardElement);
      if (cardElement == null) {
        throw new Error('Card element unavailable.');
      }
      const setup = await stripe.confirmCardSetup(client_secret, {
        payment_method: { card: cardElement },
      });
      const { error, setupIntent } = setup;
      if (error != null || setupIntent?.status !== 'succeeded') {
        throw error ?? new Error('Payment method could not be saved succesfully.');
      }
      await HookdeckAPI.billing.setPaymentMethodAsDefault(setupIntent.payment_method);
      addToast('success', 'Payment method updated.');
      history.push('/settings/organization/billing');
    } catch (err) {
      console.error(err);
      Sentry.captureException(err);
      addToast('error', 'Something went wrong, please try again or contact us.');
    }
  }, []);

  const modal_props = {
    title: 'Update payment method',
    cancel_label: 'Cancel',
    submit_label: 'Confirm',
    onCancel: onClose,
    onClose: onClose,
  };

  return (
    <WrapWithStripeElement
      loading={
        <Modal {...modal_props}>
          <Div flex={{ align: 'center', justify: 'center' }} p={{ t: 8, y: 6 }}>
            <Loading />
          </Div>
        </Modal>
      }>
      {({ stripe, elements, client_secret }) => (
        <Formik
          initialValues={{ cc_complete: false }}
          onSubmit={() => handleSubmit(elements, stripe, client_secret)}
          validate={(values) => {
            const errors: any = {};
            if (!values.cc_complete) {
              errors.cc_complete = 'Credit card is required';
            }
            return errors;
          }}>
          {(props) => (
            <form onSubmit={props.handleSubmit}>
              <Modal {...modal_props} is_submitting={props.isSubmitting}>
                <Div m={{ b: 0 }}>
                  <Text semi size="s" as="p" m={{ b: 1 }}>
                    Credit Card
                  </Text>
                  <StyledCard p={3}>
                    <CardElement
                      onChange={(event) => props.setFieldValue('cc_complete', event.complete)}
                      options={stripe_element_options}
                    />
                  </StyledCard>
                  {props.errors.cc_complete && (
                    <Text m={{ t: 1 }} danger>
                      {props.errors.cc_complete}
                    </Text>
                  )}
                </Div>
              </Modal>
            </form>
          )}
        </Formik>
      )}
    </WrapWithStripeElement>
  );
};

export default PaymentMethodModal;
