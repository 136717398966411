import { format } from 'date-fns';
import { memo, useState } from 'react';
import NumberFormat from 'react-number-format';
import { usePopper } from 'react-popper';
import styled, { css, keyframes } from 'styled-components';

import { EventStatus } from '../../../../../../../../typings/Event.interface';
import { StyledCard, StyledCardSection } from '../../../../common/base/Card';
import Text from '../../../../common/base/Text';
import { Div } from '../../../../common/helpers/StyledUtils';

const placeholder_data = Array.from(Array(7).keys()).map(() => Math.random() * 100);

const StyledChartContainer = styled.div(
  ({ theme }) => css`
    height: 32px;
    display: flex;
    align-items: flex-end;
    width: 100%;
    margin-bottom: ${theme.spacing(0)};
  `,
);

const StyledChartEntry = styled.div<{
  low_opacity?: boolean;
  hovered?: boolean;
  within_range?: boolean;
}>(
  ({ low_opacity, hovered }) => css`
    display: flex;
    flex-direction: column-reverse;
    position: relative;
    z-index: 1;
    padding: 0 2px;
    height: 100%;

    ${low_opacity &&
    !hovered &&
    css`
      ${StyledChartValue} {
        opacity: ${low_opacity ? 0.4 : 1};
        transition: opacity 0.15s ease-in;
      }
    `}
    ${hovered &&
    css`
      z-index: 10;
    `}
  `,
);

const fade = keyframes`
  from {
    opacity: 0.25;
  }
`;

const StyledChartValue = styled.div<{
  status?: EventStatus;
  $loading?: boolean;
  max_value: number;
  value: number;
}>(
  ({ theme, max_value, value, $loading }) => css`
    height: ${((value / max_value) * 100).toFixed(1)}%;
    min-height: 4px;
    background-color: ${value === 0
      ? theme.colors.surface.base.variant_surface
      : theme.colors.surface.base.danger};
    border-radius: 2px;
    width: 8px;
    margin: 1px 0px;
    align-self: end;

    ${$loading &&
    css`
      opacity: 0.6 !important;
      background-color: ${theme.colors.outline.neutral};
      animation: ${fade} 1s infinite alternate;
    `}
  `,
);

const StyledChartTooltipWrapper = styled.div`
  width: fit-content;
  z-index: 2000;
`;

const StyledChartTooltip = styled(StyledCard)(
  ({ theme }) => css`
    box-shadow: ${theme.elevation[2]};
    margin-top: 8px;
  `,
);

const granularity_formating = {
  second: 'MMM d, h:mm:ss a',
  minute: 'MMM d, h:mm a',
  hour: 'MMM d, h:mm a',
  day: 'MMM d, h:mm a',
};

const getTooltipTimeLabel = (date: Date, resolution: keyof typeof granularity_formating) => {
  const format_string = granularity_formating[resolution];
  const start_label = format(date, format_string);

  return start_label;
};

const IssueChart: React.FC<{
  values: [Date, number][] | undefined;
  resolution: keyof typeof granularity_formating;
}> = ({ values, resolution }) => {
  const [referenceElement, setReferenceElement] = useState<Element | null>(null);
  const [popperElement, setPopperElement] = useState<HTMLElement | null>(null);
  const element = document.querySelector('#main');
  const { styles, attributes } = usePopper(referenceElement, popperElement, {
    placement: 'bottom',
    modifiers: [
      {
        name: 'preventOverflow',
        options: {
          boundary: element as any,
          mainAxis: true,
          altAxis: false,
          padding: 8,
        },
      },
    ],
  });

  const [hovered_date, setHoveredDate] = useState<Date | null>(null);

  const max_value = values && Math.max(...values.map(([, value]) => value));
  return (
    <StyledChartContainer>
      {!values &&
        placeholder_data.map((v, i) => (
          <StyledChartEntry key={i}>
            <StyledChartValue $loading max_value={100} value={v} />
          </StyledChartEntry>
        ))}
      {values?.map(([date, count]) => {
        const hovered = hovered_date && hovered_date.getTime() === date.getTime();
        return (
          <StyledChartEntry
            key={date.toISOString()}
            low_opacity={hovered_date !== null && !hovered}
            hovered={!!hovered}
            onMouseEnter={() => setHoveredDate(date)}
            onMouseLeave={() => setHoveredDate(null)}>
            {hovered && <div ref={setReferenceElement} />}
            {count === 0 && (
              <StyledChartValue status="FAILED" max_value={max_value || 0} value={0} />
            )}
            {count > 0 && (
              <StyledChartValue status="FAILED" max_value={max_value || 0} value={count} />
            )}
          </StyledChartEntry>
        );
      })}
      {hovered_date && (
        <StyledChartTooltipWrapper
          ref={setPopperElement}
          style={styles.popper}
          {...attributes.popper}>
          <StyledChartTooltip overflow_hidden>
            <StyledCardSection p={{ x: 3, y: 2 }}>
              <Text text_wrap={false} bold as="span">
                {getTooltipTimeLabel(hovered_date, resolution)}
              </Text>
            </StyledCardSection>
            <StyledCardSection p={{ x: 3, y: 2 }}>
              <Div m={{ b: 1 }} flex={{ justify: 'space-between' }}>
                <Text semi muted>
                  <NumberFormat
                    renderText={(v) => v}
                    displayType="text"
                    value={
                      values!.find(
                        ([date]) => date.toISOString() === hovered_date.toISOString(),
                      )?.[1]
                    }
                    thousandSeparator={','}
                  />
                </Text>
              </Div>
            </StyledCardSection>
          </StyledChartTooltip>
        </StyledChartTooltipWrapper>
      )}
    </StyledChartContainer>
  );
};

export default memo(IssueChart);
