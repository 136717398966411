import { Form, Formik } from 'formik';
import { Div } from '../../../../common/helpers/StyledUtils';
import { GlobalContext } from '../../../../contexts/GlobalContext';
import { useContext, useState } from 'react';
import Button from '../../../../common/base/Button';
import rule_form_props from '../../Connections/Forms/rules';
import { APIWebhook } from '../../../../../../../../typings/Webhook.interface';
import { APIDestination } from '../../../../../../../../typings/Destination.interface';
import { APISource } from '../../../../../../../../typings/Source.interface';
import { useToasts } from '../../../../common/Toast';
import { cleanseFormErrorObject } from '../../../../../utils/form';
import ApiModal from '../ApiModal';

const AddRules: React.FC<{
  context: { webhook: APIWebhook | null; source: APISource; destination: APIDestination };
  mutateContext: (any) => void;
  nextStep: () => void;
}> = ({ context: { webhook, source, destination }, mutateContext, nextStep }) => {
  const { HookdeckAPI } = useContext(GlobalContext);
  const { addToast } = useToasts();
  const [show_api_modal, showAPIModal] = useState(false);
  return (
    <Formik
      initialValues={{
        rules: rule_form_props.getInitialValues(webhook ? webhook.rules : undefined),
      }}
      validate={async (v) =>
        cleanseFormErrorObject({
          rules: await rule_form_props.validate(v.rules, HookdeckAPI),
        })
      }
      onSubmit={(v, { resetForm }) => {
        const values = {
          rules: rule_form_props.postprocessValues(v.rules),
          source_id: source.id,
          destination_id: destination.id,
        };
        const promise = webhook
          ? HookdeckAPI.webhooks.update(webhook.id, values)
          : HookdeckAPI.webhooks.create(values);
        return promise
          .then((new_webhook) => {
            mutateContext({ webhook: new_webhook });
            resetForm({
              values: { rules: rule_form_props.getInitialValues(new_webhook.rules) },
            });
            if (webhook) {
              addToast('success', `Rules updated`);
            }
            nextStep();
          })
          .catch((e) => {
            addToast(
              'error',
              `An error occurred while saving the rules${
                e.response?.data[0] ? `: ${e.response.data[0]}` : ''
              }`,
            );
          });
      }}>
      {({ isValid, isSubmitting, handleSubmit, dirty, values }) => (
        <Form onSubmit={handleSubmit}>
          <rule_form_props.Fields source_id={source.id} display="full" prefix="rules" />
          <Div flex={{ gap: 3 }} m={{ t: 8 }}>
            <Button
              icon={isSubmitting ? 'loading' : webhook ? 'save' : 'add_circle'}
              submit
              disabled={!isValid || isSubmitting || (!!webhook && !dirty)}
              primary={!webhook}
              neutral={!!webhook}>
              {webhook ? 'Update Rules' : 'Confirm Rules'}
            </Button>
            <Button invisible icon="code" onClick={() => showAPIModal(true)} disabled={!isValid}>
              Use API
            </Button>
            {show_api_modal && (
              <ApiModal
                action="create-connection"
                onClose={() => showAPIModal(false)}
                values={{
                  source_id: source.id,
                  destination_id: destination.id,
                  rules: rule_form_props.postprocessValues(values.rules),
                }}
              />
            )}
          </Div>
        </Form>
      )}
    </Formik>
  );
};

export default AddRules;
