import styled, { css } from 'styled-components';

import { OrderByDirection } from '../../../../../../../../typings/API.interface';
import { Event } from '../../../../../../../../typings/Event.interface';
import { EventAttempt } from '../../../../../../../../typings/EventAttempt.interface';
import { APIWebhook } from '../../../../../../../../typings/Webhook.interface';
import { OrderBy } from '../../../../../typings/EventList.interface';
import { numberWithCommas } from '../../../../../utils';
import { ATTEMPT_TRIGGER_LABELS } from '../../../../../utils/attempt';
import { getCurrentTimezoneAbreviation } from '../../../../../utils/date';
import Badge from '../../../../common/base/Badge';
import Button from '../../../../common/base/Button';
import LabelButton from '../../../../common/base/LabelButton';
import Text from '../../../../common/base/Text';
import DisplayDate from '../../../../common/DisplayDate';
import { Div } from '../../../../common/helpers/StyledUtils';
import { Status } from '../../../../common/Status';

const StyledSelect = styled.select(
  ({ theme }) => css`
    margin: 0;
    width: 100%;
    border: none;
    font-size: ${theme.pxToRem(12)};
    font-weight: ${theme.font_weigths.medium};
    color: ${theme.colors.on.neutral.tertiary_neutral};
    text-align: left;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    text-align: left;
    padding: 0;
    max-width: 100%;
    outline: none;
    cursor: pointer;
    background: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' height='12px' viewBox='0 -960 960 960' width='16px' fill='%23828282'%3E%3Cpath d='M480-80 240-320l57-57 183 183 183-183 57 57L480-80ZM298-584l-58-56 240-240 240 240-58 56-182-182-182 182Z'/%3E%3C/svg%3E%0A")
      right center no-repeat;
    &:focus {
      outline: none;
    }
  `,
);

interface EventListLayout {
  [k: string]: (
    order_by: OrderBy,
    dir: OrderByDirection,
    actions: {
      updateOrder: (order_by: OrderBy, dir: OrderByDirection) => void;
    },
  ) => {
    widths: number[] | { min?: number; max?: number }[];
    headers: (string | JSX.Element)[];
    getEventRowFields: (
      event: Event,
      outdated: boolean,
      actions: {
        retry: (event: Event) => void;
        mute: (event: Event) => void;
        bookmark: (event: Event) => void;
      },
      webhook?: APIWebhook,
      cli_client?: any,
    ) => (string | JSX.Element | object)[];
    getAttemptRowFields: (
      attempt: EventAttempt,
      outdated: boolean,
      actions: {
        retry: (event: Event) => void;
        mute: (event: Event) => void;
      },
    ) => (string | JSX.Element | object)[];
  };
}

const event_list_layouts: EventListLayout = {
  cli: (order_by, dir, actions) => ({
    widths: [
      { min: 240, max: 240 },
      { min: 140, max: 140 },
      { min: 100 },
      { min: 128, max: 128 },
      { min: 172, max: 172 },
    ],
    headers: [
      <div key="date">
        <StyledSelect
          value={order_by}
          onChange={(e) => actions.updateOrder(e.target.value as OrderBy, dir)}>
          <option value="created_at">Event Date ({getCurrentTimezoneAbreviation()})</option>
          <option value="last_attempt_at">
            Last Attempt At ({getCurrentTimezoneAbreviation()})
          </option>
        </StyledSelect>
      </div>,
      'Status',
      'Connection',
      'Attempts',
      'Device Name',
    ],
    getEventRowFields: (event, outdated, actions, webhook, client) => [
      event[order_by] && <DisplayDate date={event[order_by]} />,
      <Div key={`${event.id}-status`} flex={{ gap: 2, justify: 'space-between', align: 'center' }}>
        <Status small key={event.id} {...event} />
        {event.status !== 'QUEUED' && webhook && (
          <Button.Permission
            role="member"
            small
            invisible
            icon="retry"
            muted
            m={{ y: -1.5 }}
            onClick={(e) => {
              e.stopPropagation();
              actions.retry(event);
            }}
          />
        )}
      </Div>,
      webhook ? (
        <LabelButton
          to={`/connections/${webhook.id}`}
          mono
          neutral
          label={webhook.full_name}
          small
        />
      ) : (
        <Text muted size="s">
          Connection deleted
        </Text>
      ),
      `${event.attempts || 0}`,
      client?.device_name,
    ],
    getAttemptRowFields: (attempt) => [
      <DisplayDate key={`${attempt.id}-date`} date={attempt.created_at} />,
      <Status small key={`${attempt.id}-status`} {...attempt} />,
      <Badge key={`${attempt.id}-trigger`} neutral>
        {ATTEMPT_TRIGGER_LABELS[attempt.trigger]}
      </Badge>,
      <Text key={`${attempt.id}-delivery`} size="s" mono>
        {!attempt.delivery_latency ? '-' : `${numberWithCommas(attempt.delivery_latency)}ms`}
      </Text>,
      <Text key={`${attempt.id}-response`} size="s" mono>
        {!attempt.response_latency ? '-' : `${numberWithCommas(attempt.response_latency)}ms`}
      </Text>,
    ],
  }),
  http: (order_by, dir, actions) => ({
    widths: [
      { min: 240, max: 240 },
      { min: 140, max: 140 },
      { min: 100 },
      { min: 128, max: 128 },
      { min: 174, max: 174 },
    ],
    headers: [
      <div key="date">
        <StyledSelect
          value={order_by}
          onChange={(e) => actions.updateOrder(e.target.value as OrderBy, dir)}>
          <option value="created_at">Event Date ({getCurrentTimezoneAbreviation()})</option>
          <option value="last_attempt_at">
            Last Attempt At ({getCurrentTimezoneAbreviation()})
          </option>
        </StyledSelect>
      </div>,
      'Status',
      'Connection',
      'Attempts',
      'Next Attempt At',
    ],
    getEventRowFields: (event, outdated, actions, webhook) => [
      event[order_by] && <DisplayDate date={event[order_by]} />,
      <Div key={`${event.id}-status`} flex={{ gap: 2, justify: 'space-between', align: 'center' }}>
        <Status small key={event.id} {...event} />
        {event.status !== 'QUEUED' && webhook && (
          <Button.Permission
            role="member"
            small
            invisible
            icon="retry"
            muted
            m={{ y: -1.5 }}
            onClick={(e) => {
              e.stopPropagation();
              actions.retry(event);
            }}
          />
        )}
      </Div>,
      webhook ? (
        <LabelButton
          to={`/connections/${webhook.id}`}
          mono
          neutral
          label={webhook.full_name}
          small
          p={{ x: 1 }}
        />
      ) : (
        <Text muted size="s">
          Connection deleted
        </Text>
      ),
      `${event.attempts || 0}`,
      event.next_attempt_at && (
        <Div
          key={`${event.id}-next-attempt`}
          flex={{ gap: 2, justify: 'space-between', align: 'center' }}>
          <DisplayDate date={event.next_attempt_at} />
          <Button.Permission
            role="member"
            small
            invisible
            icon="cancel"
            muted
            m={{ y: -1.5 }}
            onClick={(e) => {
              e.stopPropagation();
              actions.mute(event);
            }}
          />
        </Div>
      ),
    ],
    getAttemptRowFields: (attempt) => [
      <DisplayDate key={`${attempt.id}-date`} date={attempt.created_at} />,
      <Status small key={`${attempt.id}-status`} {...attempt} />,
      <Badge key={`${attempt.id}-trigger`} neutral>
        {ATTEMPT_TRIGGER_LABELS[attempt.trigger]}
      </Badge>,
      <Text key={`${attempt.id}-delivery`} muted size="s" mono>
        {!attempt.delivery_latency ? '-' : `${numberWithCommas(attempt.delivery_latency)}ms`}
      </Text>,
      <Text key={`${attempt.id}-response`} muted size="s" mono>
        {!attempt.response_latency ? '-' : `${numberWithCommas(attempt.response_latency)}ms`}
      </Text>,
    ],
  }),
};

export default event_list_layouts;
