import { useMemo, useState } from 'react';
import { Grid, GridUnit } from '@hookdeck/theme/src/Grid';

import { StandardizedInvoice } from '../../../../../../typings/Orb.interface';
import Badge from '../../../../../common/base/Badge';
import Button from '../../../../../common/base/Button';
import { StyledCard, StyledCardSection } from '../../../../../common/base/Card';
import Divider from '../../../../../common/base/Divider';
import Link from '../../../../../common/base/Link';
import Loading from '../../../../../common/base/Loading';
import Text from '../../../../../common/base/Text';
import { Div } from '../../../../../common/helpers/StyledUtils';

const usePagination = <T,>(items: T[], page_size: number) => {
  const [page, setPage] = useState(1);
  const start = (page - 1) * page_size;
  const end = start + page_size;
  const page_items = useMemo(() => items.slice(start, end), [items, start, end]);

  const nextPage = () => {
    if (page < Math.ceil(items.length / page_size)) {
      setPage(page + 1);
    }
  };

  const previousPage = () => {
    if (page > 1) {
      setPage(page - 1);
    }
  };

  return {
    page,
    nextPage,
    hasNextPage: page < Math.ceil(items.length / page_size),
    previousPage,
    hasPreviousPage: page > 1,
    items: page_items,
  };
};

export const InvoicesTable = ({ invoices }: { invoices: StandardizedInvoice[] | undefined }) => {
  const filtered_invoices = useMemo(
    () =>
      invoices
        ?.filter((invoice) => invoice.amount_due !== 0)
        .sort((a, b) =>
          new Date(a.end_time).getTime() > new Date(b.end_time).getTime() ? -1 : 1,
        ) || [],
    [invoices],
  );

  const { nextPage, previousPage, hasNextPage, hasPreviousPage, items } = usePagination(
    filtered_invoices,
    6,
  );

  return (
    <StyledCard m={{ b: 4 }}>
      <StyledCardSection muted>
        <Grid>
          <GridUnit size={2 / 6}>
            <Div flex={{ justify: 'space-between' }}>
              <Text muted semi size="xs" p={{ x: 4, y: 1.5 }}>
                Issue date
              </Text>
            </Div>
          </GridUnit>
          <GridUnit size={1 / 6}>
            <Div flex={{ justify: 'space-between' }}>
              <Text muted semi size="xs" p={{ x: 4, y: 1.5 }}>
                Amount
              </Text>
            </Div>
          </GridUnit>
          <GridUnit size={1 / 6}>
            <Text muted semi size="xs" p={{ x: 4, y: 1.5 }}>
              Status
            </Text>
          </GridUnit>
          <GridUnit size={1 / 6}>
            <Text muted semi size="xs" p={{ x: 4, y: 1.5 }}>
              Invoice #
            </Text>
          </GridUnit>
        </Grid>
      </StyledCardSection>
      <StyledCardSection h={{ px: 269 }}>
        {!invoices ? (
          <Div w={100} h={100} flex={{ align: 'center', justify: 'center' }}>
            <Loading />
          </Div>
        ) : invoices === null || !filtered_invoices?.length ? (
          <Div w={100} h={100} flex={{ align: 'center', justify: 'center' }}>
            <Text muted>No invoices</Text>
          </Div>
        ) : (
          <>
            {items &&
              items.map((invoice) => (
                <StyledCardSection key={invoice.id}>
                  <Grid>
                    <GridUnit size={2 / 6}>
                      <Div p={{ x: 4, y: 3 }}>
                        <Text base size="s">
                          {new Date(invoice.issue_date).toLocaleDateString('en-US', {
                            weekday: undefined,
                            year: 'numeric',
                            month: 'short',
                            day: 'numeric',
                          })}
                        </Text>
                      </Div>
                    </GridUnit>
                    <GridUnit size={1 / 6}>
                      <Div p={{ x: 4, y: 3 }}>
                        <Text base size="s">
                          ${(invoice.amount_due / 100).toFixed(2)}
                        </Text>
                      </Div>
                    </GridUnit>
                    <GridUnit size={1 / 6}>
                      <Div w={100} h={100} p={{ x: 4 }} flex={{ align: 'center' }}>
                        <Badge success subtle>
                          {invoice.is_paid ? 'Paid' : 'Open'}
                        </Badge>
                      </Div>
                    </GridUnit>
                    <GridUnit size={1 / 6}>
                      <Div p={{ x: 4, y: 3 }}>
                        <Text base size="s">
                          {invoice.id}
                        </Text>
                      </Div>
                    </GridUnit>
                    <GridUnit size={1 / 6}>
                      <Div p={{ x: 4, y: 3 }} flex={{ justify: 'flex-end' }}>
                        <Link href={invoice.pdf_url} target="_blank">
                          <Text base size="s">
                            View invoice
                          </Text>
                        </Link>
                      </Div>
                    </GridUnit>
                  </Grid>
                </StyledCardSection>
              ))}
            {items.length < 6 && <Divider />}
          </>
        )}
      </StyledCardSection>
      <StyledCardSection>
        <Div p={{ x: 4, y: 3 }} flex={{ justify: 'space-between' }}>
          <Div flex={{ align: 'center' }}>
            <Text muted semi size="s">
              {filtered_invoices.length}
            </Text>
            <Text muted size="s" m={{ l: 1 }}>
              invoices
            </Text>
          </Div>
          <Div flex={{ align: 'center', gap: 2 }}>
            <Button
              onClick={previousPage}
              neutral
              small
              icon="chevron_left"
              disabled={!hasPreviousPage}
            />
            <Button onClick={nextPage} neutral small icon="chevron_right" disabled={!hasNextPage} />
          </Div>
        </Div>
      </StyledCardSection>
    </StyledCard>
  );
};
