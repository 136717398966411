import { add, setMilliseconds, startOfHour, sub } from 'date-fns';
import { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import NumberFormat from 'react-number-format';
import { useHistory } from 'react-router-dom';
import { useTheme } from 'styled-components';
import useSWR from 'swr';

import { EventStatus } from '../../../../../../../typings/Event.interface';
import { EventView } from '../../../../../../../typings/View.interface';
import APIMethodKeys from '../../../../client/APIMethodKeys';
import LINKS from '../../../../configs/links';
import {
  EventListFiltersProps,
  EventListQueryParams,
} from '../../../../typings/EventList.interface';
import { extractFromArray, toArray } from '../../../../utils';
import { relative_dates, RelativeDate } from '../../../../utils/date';
import formatCubeQuery from '../../../../utils/formatCubeQuery';
import Badge from '../../../common/base/Badge';
import Button, { ButtonGroup } from '../../../common/base/Button';
import Divider from '../../../common/base/Divider';
import Skeleton from '../../../common/base/Skeleton';
import Tooltip from '../../../common/base/Tooltip';
import BulkRetryDropdown from '../../../common/BulkRetryDropdown';
import EmptyState from '../../../common/EmptyState';
import {
  event_list_filters,
  search_filter_component,
} from '../../../common/Filters/FilterComponents';
import Filters from '../../../common/Filters/Filters';
import ErrorBoundary from '../../../common/helpers/ErrorBoundary';
import { Div } from '../../../common/helpers/StyledUtils';
import { GlobalContext } from '../../../contexts/GlobalContext';
import { useCubeQueryLocalRawData } from '../../../hooks/useCubeQueryLocalRawData';
import useEventAction from '../../../hooks/useEventActions';
import useEventList from '../../../hooks/useEventList';
import useSearchQuery from '../../../hooks/useSearchQuery';
import {
  StyledViewActions,
  StyledViewContent,
  StyledViewLayout,
  StyledViewNav,
  StyledViewNavHeader,
  StyledViewNavSecondary,
  ViewTitle,
} from '../../../layouts/ViewLayout';
import { DashboardContext } from '../DashboardContext';
import { ViewsContext } from '../ViewsContext';
import EventsList from './EventList/EventList';
import EventPreview from './EventPreview';
import Histogram from './Histogram';

export const extractFiltersFromQuery = (
  parsed_query: EventListQueryParams,
): EventListFiltersProps => {
  let date = extractFromArray(parsed_query.date);
  if (typeof date === 'string') {
    const { min, max } = relative_dates[date].convert(new Date());
    date = {
      relative: date,
      min: min?.toISOString(),
      max: max?.toISOString(),
    };
  } else {
    date = {
      max: extractFromArray(parsed_query.date?.max) as string,
      min: extractFromArray(parsed_query.date?.min) as string,
    };
  }
  return {
    search_term: extractFromArray(parsed_query.search_term) as string,
    webhook_id: toArray(parsed_query.webhook_id || []) as string[],
    cli_user_id:
      parsed_query.cli_user_id === 'any'
        ? 'any'
        : (toArray(parsed_query.cli_user_id ?? []) as string[]),
    source_id: toArray(parsed_query.source_id ?? []) as string[],
    destination_id: toArray(parsed_query.destination_id ?? []) as string[],
    issue_id: extractFromArray(parsed_query.issue_id) as string,
    bulk_retry_id: extractFromArray(parsed_query.bulk_retry_id) as string,
    date,
    request: {
      headers: extractFromArray(parsed_query.request?.headers) as string,
      body: extractFromArray(parsed_query.request?.body) as string,
      path: extractFromArray(parsed_query.request?.path) as string,
      parsed_query: extractFromArray(parsed_query.request?.parsed_query) as string,
    },
    status: toArray(parsed_query.status ?? []) as string[],
    response_status: {
      max: extractFromArray(parsed_query.response?.response_status?.max) as string,
      min: extractFromArray(parsed_query.response?.response_status?.min) as string,
    },
    error_code: toArray(parsed_query.response?.error_code ?? []) as string[],
    attempts: {
      max: extractFromArray(parsed_query.attempts?.max) as string,
      min: extractFromArray(parsed_query.attempts?.min) as string,
    },
    next: extractFromArray(parsed_query.next) as string,
    prev: extractFromArray(parsed_query.prev) as string,
  };
};

export const extractFiltersFromEventView = (current_view: EventView): EventListFiltersProps => {
  const filters = current_view.filters;

  return {
    ...filters,
    date: {
      relative: extractFromArray(filters.date?.relative) as RelativeDate,
      max: extractFromArray(filters.date?.max) as string,
      min: extractFromArray(filters.date?.min) as string,
    },
    response_status: {
      max: extractFromArray(filters.response_status?.max?.toString()) as string,
      min: extractFromArray(filters.response_status?.min?.toString()) as string,
    },
    attempts: {
      min: extractFromArray(filters.attempts?.min?.toString()) as string,
      max: extractFromArray(filters.attempts?.max?.toString()) as string,
    },
  };
};

const extractFiltersForList = (filters: EventListFiltersProps): EventListFiltersProps => {
  if (filters.date?.relative) {
    const { min, max } = relative_dates[filters.date?.relative].convert(new Date());
    return {
      ...filters,
      date: {
        min: min.toISOString(),
        max: max.toISOString(),
      },
    };
  }
  return filters;
};

export const filters_initial_state = extractFiltersFromQuery({});

const EventsView: React.FC<{ current_view?: EventView }> = ({ current_view }) => {
  const { query, updateSearchQuery } = useSearchQuery<EventListQueryParams>();
  const theme = useTheme();
  const { HookdeckAPI } = useContext(GlobalContext);
  const { subscription, organization, has_connection, has_created_connections } =
    useContext(DashboardContext);

  const [event_list_render_key, setRenderKey] = useState(Date.now());
  const [ignore_current_view, setIgnoreCurrentView] = useState(false);
  const history = useHistory();

  const { views, createView, updateView, deleteView, renameView, duplicateView } =
    useContext(ViewsContext);

  const is_cli_view = current_view?.id === 'cli';
  const selected_event_id = extractFromArray(query.selected_event_id) as string;
  const selected_attempt_id = extractFromArray(query.selected_attempt_id) as string;
  const order_by =
    (extractFromArray(query.order_by) as 'created_at' | 'last_attempt_at') || 'created_at';
  const dir = (extractFromArray(query.dir) as 'asc' | 'desc') || 'desc';

  const enable_payload_search =
    organization!.feature_flags?.clickhouse_payload_search || process.env.CLICKHOUSE_PAYLOAD_SEARCH;

  const filter_components = enable_payload_search
    ? [search_filter_component, ...event_list_filters]
    : event_list_filters;

  // Query base redirect, ?event_request_id has been removed
  useEffect(() => {
    if ((query as any).event_request_id) {
      history.replace(`/requests/${(query as any).event_request_id}`);
    }
  }, []);

  const filters = useMemo(() => {
    let new_filters = extractFiltersFromQuery(query);
    if (current_view && !ignore_current_view) {
      const should_use_query = Object.values(filter_components).some(
        (v) => query[v.filter_key] !== undefined,
      );
      if (!should_use_query) {
        const event_view_filters = extractFiltersFromEventView(current_view);
        new_filters = {
          ...filters_initial_state,
          ...event_view_filters,
          next: new_filters.next,
          prev: new_filters.prev,
        };
      }
    }
    return new_filters;
  }, [query, current_view, ignore_current_view]);

  const filters_active_count = useMemo(
    () =>
      filter_components.reduce((count, f) => {
        if (f.isActive(f.formatForForm(filters[f.filter_key], filters))) {
          if (f.filter_key === 'cli_user_id') {
            return count;
          }
          count++;
        }
        return count;
      }, 0),
    [filters],
  );
  const event_list_data = useEventList(extractFiltersForList(filters), order_by, dir);

  const latest_event = event_list_data.events.length > 0 && event_list_data.events[0];

  const [stats_start_date, stats_end_date] = useMemo(() => {
    const now = new Date();
    if (filters.date?.relative) {
      const { min, max } = relative_dates[filters.date?.relative].convert(new Date());
      filters.date.min = min.toISOString();
      filters.date.max = max.toISOString();
    }
    const end_date = setMilliseconds(
      filters.date?.max ? new Date(filters.date?.max) : add(startOfHour(now), { hours: 1 }),
      0,
    );
    const start_date = setMilliseconds(
      filters.date?.min
        ? new Date(filters.date?.min)
        : sub(add(startOfHour(now), { hours: 1 }), { days: 1 }),
      0,
    );
    return [start_date, end_date];
  }, [
    JSON.stringify(filters),
    subscription!.retention_days,
    event_list_render_key,
    latest_event && latest_event.id,
  ]);

  const cube_query = useMemo(
    () =>
      formatCubeQuery('Events', {
        filters: {
          ...filters,
          date: { min: stats_start_date.toISOString(), max: stats_end_date.toISOString() },
        },
      }),
    [filters, filters.date?.min, filters.date?.max],
  );

  const { raw_data, is_loading, refetch } = useCubeQueryLocalRawData<Record<string, any>[]>(
    cube_query,
    {
      resetResultSetOnChange: false,
    },
  );

  const has_request_filter =
    Object.values(filters.request ?? {}).some((v) => !!v) || filters.search_term;
  const total_count = raw_data && raw_data.length > 0 ? raw_data[0]['Events.count'] : null;

  const onEventSelected = useCallback(
    (selected_event_id: string | null) =>
      updateSearchQuery({ selected_event_id }, { remove_keys: ['selected_attempt_id'] }),
    [updateSearchQuery],
  );
  const onAttemptSelected = useCallback(
    (selected_attempt_id: string) => updateSearchQuery({ selected_attempt_id }),
    [updateSearchQuery],
  );

  const onFilterChanged = (new_filters: EventListFiltersProps) => {
    HookdeckAPI.track.event('Filtered Event List', new_filters);
    if (current_view) {
      setIgnoreCurrentView(true);
    }
    updateSearchQuery(new_filters, {
      remove_keys: ['selected_attempt_id', 'selected_event_id', 'next', 'prev'],
      replace: true,
    });
  };

  useEffect(() => {
    setIgnoreCurrentView(false);
  }, [current_view]);

  const onPaginationChanged = useCallback(
    (new_filters) => {
      const pagination = {
        next: new_filters.next || undefined,
        prev: new_filters.prev || undefined,
      };
      updateSearchQuery(pagination, {
        remove_keys: ['selected_attempt_id', 'selected_event_id'],
      });
    },
    [updateSearchQuery],
  );

  const onOrderUpdated = useCallback(
    (order_by: 'created_at' | 'last_attempt_at', dir: 'asc' | 'desc') =>
      updateSearchQuery({ order_by, dir, next: undefined, prev: undefined }),
    [updateSearchQuery],
  );

  const setDate = (date) => {
    if (!date.relative) {
      delete date.relative;
    }
    updateSearchQuery({
      ...filters,
      date: date.relative ? date.relative : date,
    });
  };

  const handleCreateView = (values) => {
    createView(
      filter_components.reduce((object, { form_name, formatForDB, isActive }) => {
        if (!isActive(values[form_name])) return object;
        return {
          ...object,
          ...formatForDB(values[form_name]),
        };
      }, {}),
    );
  };

  const handleUpdateView = (values) => {
    updateView(
      current_view!.id,
      filter_components.reduce((object, { form_name, formatForDB, isActive }) => {
        if (!isActive(values[form_name])) return object;
        return {
          ...object,
          ...formatForDB(values[form_name]),
        };
      }, {}),
    );
  };

  const event =
    event_list_data.events &&
    event_list_data.events.find((event) => event.id === selected_event_id);

  const { data: event_attempts, mutate: mutateAttempts } = useSWR(
    event && APIMethodKeys.attempts.list({ event_id: event.id }),
    () => HookdeckAPI.attempts.list({ event_id: event!.id }),
    {
      refreshInterval: 5000,
      revalidateOnFocus: true,
    },
  );

  const latest_attempt = event_attempts && event_attempts.models[0];

  useEffect(() => {
    if (
      event &&
      latest_attempt &&
      new Date(event.updated_at).getTime() < new Date(latest_attempt.created_at).getTime() &&
      latest_attempt.event_id === event.id &&
      (latest_attempt.status !== event.status ||
        latest_attempt.created_at !== event.last_attempt_at ||
        latest_attempt.attempt_number !== event.attempts ||
        latest_attempt.response_status !== event.response_status ||
        latest_attempt.error_code !== event.error_code)
    ) {
      event_list_data.refresh({
        ...event,
        status: latest_attempt.status as EventStatus,
        last_attempt_at: latest_attempt.created_at,
        attempts: latest_attempt.attempt_number,
        response_status: latest_attempt.response_status,
        error_code: latest_attempt.error_code,
      });
    }
  }, [latest_attempt]);

  const event_actions = useEventAction((action, event) => {
    switch (action) {
      case 'mute':
        mutateAttempts();
        event_list_data.refresh(event);
        break;
      case 'retry':
        if (event.id === selected_event_id) {
          mutateAttempts();
        }
        event_list_data.refresh(event);
        break;
    }
  });

  return (
    <StyledViewLayout>
      <Filters.Provider
        filters={filters}
        components={filter_components}
        route="events"
        onFilterChanged={onFilterChanged}>
        <StyledViewContent>
          <StyledViewNav>
            <StyledViewNavHeader>
              <Div flex={{ align: 'center', justify: 'space-between', gap: 2 }}>
                <ViewTitle
                  title="Events"
                  tooltip="Events are outgoing requests to destinations you’ve configured. Each event is associated with a connection."
                  link={LINKS.product_docs.events}
                />
                {!total_count && !has_request_filter ? (
                  <Skeleton variant={'square'} h={{ px: 20 }} />
                ) : (
                  <Badge neutral>
                    {has_request_filter ? (
                      'Ø'
                    ) : (
                      <NumberFormat
                        renderText={(v) => v}
                        displayType={'text'}
                        value={has_request_filter ? 'Ø' : total_count}
                        thousandSeparator={','}
                      />
                    )}
                  </Badge>
                )}
              </Div>
              <StyledViewActions>
                <Button
                  icon="reload"
                  neutral
                  onClick={() => {
                    updateSearchQuery(
                      {},
                      {
                        remove_keys: ['prev', 'next', 'selected_event_id', 'selected_attempt_id'],
                      },
                    );
                    event_list_data.refresh();
                    setRenderKey(Date.now());
                    if (!is_loading) {
                      refetch();
                    }
                  }}>
                  Refresh
                </Button>
                <BulkRetryDropdown
                  outline
                  model="events"
                  filters={filters}
                  order_by={order_by}
                  dir={dir}
                />
                {current_view?.id === 'cli' ? (
                  <Tooltip align="right" tooltip="CLI views are not editable">
                    <Button disabled neutral icon={'save'}>
                      Save View
                    </Button>
                  </Tooltip>
                ) : !current_view ? (
                  views && views?.count > 249 ? (
                    <Tooltip align="right" tooltip="Views limit reached">
                      <Button disabled neutral icon={'save'}>
                        Save View
                      </Button>
                    </Tooltip>
                  ) : (
                    <Button
                      neutral
                      disabled={filters_active_count === 0}
                      onClick={() => {
                        handleCreateView(filters);
                      }}
                      icon={'save'}>
                      Save View
                    </Button>
                  )
                ) : (
                  <ButtonGroup
                    more_options={[
                      {
                        label: 'Save as New View',
                        disabled: views && views.count > 249,
                        tooltip: views && views.count > 249 ? 'Views limit reached' : undefined,
                        onClick: () => {
                          handleCreateView(filters);
                        },
                      },
                      {
                        label: 'Rename View',
                        onClick: () => renameView(current_view.id),
                      },
                      {
                        label: 'Duplicate View',
                        onClick: () => duplicateView(current_view.id),
                      },
                      {
                        label: 'Delete View',
                        danger: true,
                        onClick: () => {
                          deleteView(current_view.id);
                        },
                      },
                    ]}>
                    <Button
                      neutral
                      onClick={() => {
                        handleUpdateView(filters);
                      }}
                      disabled={filters_active_count === 0}
                      icon={'save'}>
                      Save View
                    </Button>
                  </ButtonGroup>
                )}
              </StyledViewActions>
            </StyledViewNavHeader>
            <StyledViewNavSecondary style={{ border: 'none', paddingLeft: 0, paddingRight: 0 }}>
              <Div w={100}>
                <Filters.Buttons />
                <Divider />
              </Div>
            </StyledViewNavSecondary>
          </StyledViewNav>
          <Filters.Form />
          {!has_connection ? (
            <EmptyState
              title="Inspect outbound events"
              description="Events are outgoing requests to destinations you’ve configured. Hookdeck queues events based on requests, inbound events from corresponding sources. In order to explore and monitor events, create a connection."
              asset={`/images/empty/events-${theme.mode}.svg`}
              cta={{
                label: 'Create connection',
                icon: 'add_circle',
                to: has_created_connections ? '/connections/new' : '/create-first-connection',
              }}
            />
          ) : (
            <>
              <ErrorBoundary>
                <Histogram
                  dimention={'status'}
                  model="Events"
                  key={event_list_render_key}
                  start_date={stats_start_date}
                  end_date={stats_end_date}
                  filters={filters}
                  setDate={setDate}
                  refresh_key={latest_event?.[order_by]}
                />
                <Divider />
              </ErrorBoundary>
              <EventsList
                {...event_list_data}
                refresh={() => {
                  event_list_data.refresh();
                  setRenderKey(Date.now());
                  if (!is_loading) {
                    refetch();
                  }
                }}
                layout={is_cli_view ? 'cli' : 'http'}
                filters={filters}
                order_by={order_by}
                dir={dir}
                onEventSelected={onEventSelected}
                onAttemptSelected={onAttemptSelected}
                onOrderUpdated={onOrderUpdated}
                selected_attempt_id={selected_attempt_id}
                selected_event_id={selected_event_id}
                onPaginationChanged={onPaginationChanged}
                total_count={total_count}
                event_actions={event_actions}
                event_attempts={event_attempts}
              />
            </>
          )}
        </StyledViewContent>
      </Filters.Provider>
      {selected_event_id && (
        <EventPreview
          event_id={selected_event_id}
          attempt_id={selected_attempt_id}
          event_actions={event_actions}
          list_event={event}
          list_attempt={
            latest_attempt && latest_attempt.id === selected_attempt_id ? latest_attempt : undefined
          }
          onClose={() =>
            updateSearchQuery({}, { remove_keys: ['selected_event_id', 'selected_attempt_id'] })
          }
        />
      )}
    </StyledViewLayout>
  );
};

export default EventsView;
