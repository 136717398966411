import { add, differenceInSeconds, format, sub } from 'date-fns';
import { Form, Formik } from 'formik';
import { memo, useCallback, useContext, useEffect, useMemo, useState } from 'react';

import { IgnoredEventCause } from '../../../../../../../../typings/IgnoredEvent.interface';
import { EventListFiltersProps } from '../../../../../typings/EventList.interface';
import { RequestListFiltersProps } from '../../../../../typings/RequestList.interface';
import {
  chart_resolutions,
  getBestResolution,
  getTime,
  Resolution,
  startOfTime,
  timebar_resolutions,
} from '../../../../../utils/chart';
import { relative_dates, RelativeDate } from '../../../../../utils/date';
import { CubeModel } from '../../../../../utils/formatCubeQuery';
import { hasSeconds, roundUpHour } from '../../../../../utils/time';
import Button, { ButtonGroup } from '../../../../common/base/Button';
import { StyledCardSection } from '../../../../common/base/Card';
import Dropdown from '../../../../common/Dropdown';
import DatePickerInput, {
  formatDateFilterValues,
} from '../../../../common/Form/Fields/DatePickerInput';
import { Div } from '../../../../common/helpers/StyledUtils';
import { DashboardContext } from '../../DashboardContext';
import HistogramRangePickerWrapper from './HistogramRangePickerWrapper';

type Filters =
  | EventListFiltersProps
  | RequestListFiltersProps
  // Typings for IgnoredEvents cube Model
  | {
      date?: {
        relative?: RelativeDate;
        max?: string;
        min?: string;
      };
      cause: IgnoredEventCause[];
    };

export const getTimerangeLabel = (
  date: {
    relative?: RelativeDate;
    min?: string;
    max?: string;
  },
  retention_days: number,
) => {
  if (date?.relative) {
    return relative_dates[date.relative].label;
  }
  if (date?.max || date?.min) {
    const format_start_string =
      date.min && hasSeconds(date.min) ? 'MMM d yy, h:mm:ss a' : 'MMM d yy, h:mm a';
    const format_end_string =
      date.max && hasSeconds(date.max) ? 'MMM d yy, h:mm:ss a' : 'MMM d yy, h:mm a';

    const start_date = new Date(date.min!);
    const end_date = date.max && new Date(date.max);

    return `${
      date?.min
        ? format(start_date, format_start_string)
        : `${format(start_date, format_start_string)} (Retention window)`
    } -> ${date?.max && end_date ? format(end_date, format_end_string) : 'Now'}`;
  }
  return `Last 24 hours`;
};

export const zoom = (direction: 'in' | 'out', date: Date, resolution: Resolution) => {
  if (direction === 'in') {
    return sub(date, {
      [`${resolution.granularity}s`]: getTime[resolution.granularity](date) + resolution.unit,
    });
  }
  if (direction === 'out') {
    return add(date, {
      [`${resolution.granularity}s`]: getTime[resolution.granularity](date) + resolution.unit,
    });
  }
  return date;
};

export const getHistogramConfigs = (diff: number, start_date: Date, end_date: Date) => {
  let chart_resolution = getBestResolution(diff, 60, chart_resolutions).best;
  let axis_resolution = getBestResolution(diff, 10, timebar_resolutions).best;

  // if the granularity is hour, always round up so the time is greater then the time inputted
  if (chart_resolution.granularity === 'hour') {
    start_date = roundUpHour(start_date);
    end_date = roundUpHour(end_date);
    const diff = differenceInSeconds(end_date, start_date);
    chart_resolution = getBestResolution(diff, 60, chart_resolutions).best;
    axis_resolution = getBestResolution(diff, 10, timebar_resolutions).best;
  }

  if (chart_resolution.granularity !== 'second') {
    end_date = add(startOfTime[chart_resolution.granularity](end_date), {
      [`${chart_resolution.granularity}s`]:
        (getTime[chart_resolution.granularity](end_date) * chart_resolution.unit) /
        chart_resolution.unit,
    });
    start_date = sub(end_date, {
      [`${chart_resolution.granularity}s`]: chart_resolution.steps * chart_resolution.unit,
    });
  }

  return { start_date, end_date, chart_resolution, axis_resolution };
};

const Histogram: React.FC<{
  model: CubeModel;
  dimention: string;
  filters: Filters;
  start_date: Date;
  end_date: Date;
  setDate: (date: { relative?: RelativeDate; min?: string; max?: string } | undefined) => void;
  refresh_key?: string;
  allow_reset?: boolean;
}> = ({ model, dimention, filters, start_date, end_date, setDate, refresh_key, allow_reset }) => {
  const { subscription } = useContext(DashboardContext);

  const [timerange_history, setTimerangeHistory] = useState([
    { min: start_date.toISOString(), max: end_date.toISOString() },
  ]);
  const [current_timerange_index, setTimerangeHistoryIndex] = useState(0);
  const diff = differenceInSeconds(end_date, start_date);

  const {
    start_date: new_start_date,
    end_date: new_end_date,
    chart_resolution,
    axis_resolution,
  } = useMemo(() => getHistogramConfigs(diff, start_date, end_date), [diff]);
  start_date = new_start_date;
  end_date = new_end_date;

  const date_label = filters.date
    ? getTimerangeLabel(filters.date, subscription!.retention_days)
    : '';

  useEffect(() => {
    if (filters.date?.relative || !filters.date?.min || !filters.date?.max) {
      setTimerangeHistory([{ min: start_date.toISOString(), max: end_date.toISOString() }]);
      setTimerangeHistoryIndex(0);
    }
  }, [filters]);

  const setDateAndTimerange = useCallback(
    (date: { relative?: RelativeDate; min: string; max: string }) => {
      setTimerangeHistory([{ min: date.min, max: date.max }, ...timerange_history]);
      setTimerangeHistoryIndex(0);
      setDate(date);
    },
    [timerange_history],
  );

  const goToPreviousTimerange = useCallback(() => {
    const previous_timerange = timerange_history[current_timerange_index + 1];
    if (!previous_timerange) {
      return;
    }
    setTimerangeHistoryIndex(current_timerange_index + 1);
    setDate({
      relative: undefined,
      ...previous_timerange,
    });
  }, [current_timerange_index, timerange_history]);

  const goToNextTimerange = useCallback(() => {
    const next_timerange = timerange_history[current_timerange_index - 1];
    if (!next_timerange) {
      return;
    }
    setTimerangeHistoryIndex(current_timerange_index - 1);
    setDate({
      relative: undefined,
      ...next_timerange,
    });
  }, [current_timerange_index, timerange_history]);

  const form_initial_values = useMemo(
    () => ({
      date: {
        ...filters.date,
        min: filters.date?.min || start_date.toISOString(),
        max: filters.date?.max || end_date.toISOString(),
      } as { relative?: RelativeDate; min?: string; max?: string },
      start_time: filters.date?.min
        ? format(new Date(filters.date.min), 'HH:mm:ss')
        : format(start_date, 'HH:mm:ss'),
      end_time: filters.date?.max
        ? format(new Date(filters.date.max), 'HH:mm:ss')
        : format(end_date, 'HH:mm:ss'),
    }),
    [filters.date],
  );

  const actions_element = (
    <>
      {timerange_history.length > 1 && (
        <Div m={{ r: 2 }}>
          <ButtonGroup>
            <Button
              neutral
              icon="chevron_left"
              disabled={!timerange_history[current_timerange_index + 1]}
              onClick={goToPreviousTimerange}
            />
            <Button
              neutral
              icon="chevron_right"
              disabled={!timerange_history[current_timerange_index - 1]}
              onClick={goToNextTimerange}
            />
          </ButtonGroup>
        </Div>
      )}
      {allow_reset !== false && filters.date && Object.values(filters.date).some((v) => !!v) && (
        <Button neutral icon="close" onClick={() => setDate({ min: undefined, max: undefined })} />
      )}
    </>
  );

  return (
    <Div p={{ x: 8, t: 4, b: 4 }}>
      <Div m={{ b: 2 }} flex={{ align: 'center', justify: 'space-between' }}>
        <Div flex={{ align: 'center' }}>
          <Dropdown
            m={{ b: 2 }}
            renderToggle={(opened, toggle) => (
              <Button
                m={{ r: 2 }}
                neutral
                icon="date"
                dropdown
                expand_icon="chevron_down"
                onClick={() => toggle(!opened)}>
                {date_label}
              </Button>
            )}>
            {(toggle) => (
              <Formik
                initialValues={form_initial_values}
                onSubmit={(v) => {
                  const { start_date, end_date } = formatDateFilterValues({
                    start_date: v.date.min,
                    start_time: v.start_time,
                    end_date: v.date.max,
                    end_time: v.end_time,
                  });
                  setDate({
                    ...v.date,
                    min: start_date,
                    max: end_date,
                  });
                  toggle(false);
                }}>
                {(props) => (
                  <Form>
                    <StyledCardSection>
                      <DatePickerInput display_reset={false} time_format={'hh:mm:ss'} />
                    </StyledCardSection>
                    <StyledCardSection flex={{ justify: 'space-between' }} p={{ x: 4, y: 3 }}>
                      <Button
                        invisible
                        small
                        icon="cancel"
                        onClick={() => {
                          props.setValues({
                            date: {
                              relative: undefined,
                              min: undefined,
                              max: undefined,
                            },
                            start_time: '',
                            end_time: '',
                          });
                        }}>
                        Reset Dates
                      </Button>
                      <Div flex={{ gap: 3 }}>
                        <Button neutral small onClick={() => toggle(false)}>
                          Cancel
                        </Button>
                        <Button primary small submit>
                          Apply
                        </Button>
                      </Div>
                    </StyledCardSection>
                  </Form>
                )}
              </Formik>
            )}
          </Dropdown>
          <Div flex>{actions_element}</Div>
        </Div>
      </Div>
      <Formik
        key={start_date.toISOString() + end_date.toISOString()}
        initialValues={{
          timebar_start: 0,
          timebar_end: chart_resolution.steps - 1,
        }}
        onSubmit={(values) => {
          setDateAndTimerange({
            relative: undefined,
            min: add(start_date, {
              seconds:
                values.timebar_start * chart_resolution.unit * chart_resolution.factor_to_seconds,
            }).toISOString(),
            max: add(start_date, {
              seconds:
                (values.timebar_end + 1) *
                chart_resolution.unit *
                chart_resolution.factor_to_seconds,
            }).toISOString(),
          });
        }}>
        <Form>
          <HistogramRangePickerWrapper
            model={model}
            dimention={dimention}
            filters={filters}
            start_date={start_date}
            end_date={end_date}
            chart_resolution={chart_resolution}
            axis_resolution={axis_resolution}
            start_name={'timebar_start'}
            end_name={'timebar_end'}
            refresh_key={refresh_key}
          />
        </Form>
      </Formik>
    </Div>
  );
};

export default memo(Histogram);
