import { Field, useFormikContext } from 'formik';
import { editor } from 'monaco-editor';
import { useState } from 'react';
import styled, { css } from 'styled-components';

import editor_jsdocs from '../../../../utils/editor-jsdocs';
import useOnClickOutside from '../../../hooks/useOnClickOutside';
import Text from '../../base/Text';
import Editor from '../../Editor';
import { Div, StyledUtilsProps } from '../../helpers/StyledUtils';
import { Variant } from './Input/Input';
import { useAuthorized } from '../../../contexts/TeamPermissionContext';
import { TeamRole } from '../../../../../../../typings/TeamMember.interface';
import { withStaticProperties } from '../../../../utils/withStaticProperties';

interface Props extends StyledUtilsProps {
  label?: string;
  variant?: Variant;
  name: string;
  height?: string;
  help?: string;
  display?: 'minimal' | 'full';
  language?: 'json' | 'javascript' | 'text';
  jsdocs?: keyof typeof editor_jsdocs;
  format_on_blur?: boolean;
  disabled?: boolean;
  cmdEnter?: () => void;
  onBlur?: () => void;
  onFocus?: () => void;
  setEditor?: (editor: editor.IStandaloneCodeEditor) => void;
}

const StyledField = styled(Div)<{ variant?: Variant }>(
  ({ theme, variant }) => css`
    ${variant === 'card' &&
    css`
      label {
        margin: 0;
        border-bottom: ${theme.border};
        border-radius: ${theme.radius.normal_inset} ${theme.radius.normal_inset} 0 0;
        padding: ${theme.spacing(1)} ${theme.spacing(3)};
        background-color: ${theme.colors.surface.base.variant_surface_2};
        width: 100%;
      }

      input {
        border-radius: 0 0 ${theme.radius.normal_inset} ${theme.radius.normal_inset};
      }
    `}
  `,
);

const EditorInput: React.FC<Props> = (props) => {
  const {
    label,
    name,
    help,
    height,
    display,
    language,
    variant,
    format_on_blur,
    disabled,
    onBlur,
    onFocus,
    ...other_props
  } = props;

  const [is_focused, setFocused] = useState(false);
  const input_ref = useOnClickOutside(() => {
    if (is_focused) {
      onBlur && onBlur();
      setFocused(false);
    }
  });

  const { submitCount } = useFormikContext();

  return (
    <Field name={name} height={height}>
      {({
        field: { value },
        form: { setFieldValue, setFieldTouched },
        meta: { error, touched },
      }) => (
        <StyledField {...other_props} variant={variant} h={100}>
          {label && (
            <Text
              as="label"
              m={{ b: 2 }}
              htmlFor={name}
              semi
              size={variant === 'card' ? 'xs' : 's'}>
              {label}
            </Text>
          )}
          <Div
            ref={input_ref}
            onMouseDown={() => {
              onFocus && onFocus();
              setFocused(true);
            }}
            h={100}>
            <Editor
              key={language}
              format_on_blur={format_on_blur}
              disabled={disabled}
              language={language || 'json'}
              display={display}
              height={height}
              onChange={(v) => {
                setFieldTouched(name);
                setFieldValue(name, v);
              }}
              value={value}
              error={(touched || submitCount > 0) && error}
              cmdEnter={props.cmdEnter}
              jsdocs={props.jsdocs}
            />
          </Div>
          {help && (
            <Text m={{ top: 1 }} as="span" muted>
              {help}
            </Text>
          )}
        </StyledField>
      )}
    </Field>
  );
};

const EditorInputPermission: React.FC<
  React.ComponentPropsWithRef<typeof EditorInput> & { role?: TeamRole }
> = ({ role, ...editorInputProps }) => {
  const authorized = useAuthorized(role);
  if (authorized) return <EditorInput {...editorInputProps} />;

  return <EditorInput {...editorInputProps} disabled={editorInputProps.disabled || !authorized} />;
};

export default withStaticProperties(EditorInput, { Permission: EditorInputPermission });
