import { useField } from 'formik';
import { useContext, useMemo } from 'react';

import { Grid, GridUnit } from '@hookdeck/theme';

import {
  DestinationTypeFeatures,
  isSourceTypeFeatureUnmanaged,
  SourceType,
  SourceTypeFeatures,
  SourceTypeFeatureTypesUnmanaged,
  TypeFeatureFields,
} from '../../../../../../../../typings/Integration.interface';
import LINKS from '../../../../../configs/links';
import { fieldName } from '../../../../../utils';
import Alert from '../../../../common/base/Alert';
import { StyledCard, StyledCardSection } from '../../../../common/base/Card';
import Icon from '../../../../common/base/Icon';
import Link from '../../../../common/base/Link';
import Text from '../../../../common/base/Text';
import Tooltip from '../../../../common/base/Tooltip';
import CopyableField from '../../../../common/CopyableField';
import DropdownMenu from '../../../../common/DropdownMenu';
import CheckboxInput from '../../../../common/Form/Fields/CheckboxInput';
import EditorInput from '../../../../common/Form/Fields/EditorInput';
import SelectInput from '../../../../common/Form/Fields/SelectInput';
import SwitchInput from '../../../../common/Form/Fields/SwitchInput';
import TextAreaInput from '../../../../common/Form/Fields/TextAreaInput';
import TextInput from '../../../../common/Form/Fields/TextInput';
import { Div } from '../../../../common/helpers/StyledUtils';
import { DashboardContext } from '../../DashboardContext';

export function useFeatures(features: SourceTypeFeatures | DestinationTypeFeatures, prefix = '') {
  return useMemo(() => {
    const default_features = {
      required_features: [] as (DestinationFeatureType | SourceFeatureType)[],
      advanced_features: [] as (DestinationFeatureType | SourceFeatureType)[],
    };

    return Object.entries(features).reduce((acc, [type, config]) => {
      if (config.managed) return acc;

      config.fields?.forEach((field) => {
        field.name = fieldName(field.name, prefix);
      });

      const feature = {
        ...config,
        type: type as keyof SourceTypeFeatures | DestinationTypeFeatures,
      };
      if (config.required || config.advanced === false) {
        acc.required_features.push(feature);
      } else {
        acc.advanced_features.push(feature);
      }

      return acc;
    }, default_features);
  }, [features, prefix]);
}

function calculateMargin(features: TypeFeatureFields, index: number) {
  return features.length === 1
    ? { t: 0 }
    : {
        r: index % 2 === 0 ? 2 : 0,
        l: index % 2 !== 0 ? 2 : 0,
        t: features.length === 2 ? 0 : 2,
      };
}

function VerificationFields({
  verification_features,
  prefix,
  disabled,
}: {
  verification_features: TypeFeatureFields;
  prefix: string;
  disabled?: boolean;
}) {
  return !!verification_features && verification_features.length > 0 ? (
    <Div flex={{ wrap: true }} m={{ t: 2 }} style={{ rowGap: '8px' }}>
      {verification_features.map((field, i) => {
        switch (field.type) {
          case 'select':
            return (
              <GridUnit size={verification_features.length === 1 ? 1 : 1 / 2} key={field.name}>
                <SelectInput
                  disabled={disabled}
                  m={calculateMargin(verification_features, i)}
                  block
                  label={field.label}
                  name={fieldName(field.name, prefix)}
                  options={field.options ?? []}
                  required={field.required === false ? false : true}
                />
              </GridUnit>
            );
          case 'textarea':
            return (
              <GridUnit size={1} key={field.name}>
                <TextAreaInput
                  disabled={disabled}
                  m={calculateMargin(verification_features, i)}
                  mono={true}
                  label={field.label}
                  placeholder={field.placeholder}
                  default_value={''}
                  name={fieldName(field.name, prefix)}
                  required={field.required === false ? false : true}
                />
              </GridUnit>
            );
          default:
            return (
              <GridUnit size={verification_features.length === 1 ? 1 : 1 / 2} key={field.name}>
                <TextInput
                  disabled={disabled}
                  m={calculateMargin(verification_features, i)}
                  label={field.label}
                  type={field.type}
                  placeholder={field.placeholder}
                  default_value={''}
                  name={fieldName(field.name, prefix)}
                  required={field.required === false ? false : field.allow_empty ? false : true}
                />
              </GridUnit>
            );
        }
      })}
    </Div>
  ) : null;
}

function VerificationFeature({ feature, prefix }: { feature: SourceFeatureType; prefix: string }) {
  const { organization, source_types } = useContext(DashboardContext);
  const [{ value: type }, _, { setValue: setType }] = useField<SourceType>(
    fieldName('type', prefix),
  );
  const [{ value: auth_type }] = useField<SourceType>(fieldName('config.auth_type', prefix));
  const [{ value: enable_auth }] = useField<boolean>(fieldName('enable_auth', prefix));

  const [custom_auth, is_legacy_auth] = useMemo(() => {
    const custom_auth = isSourceTypeFeatureUnmanaged(feature) && feature?.auth;

    if (!custom_auth) return [undefined, false];

    const is_legacy_auth =
      enable_auth && type === 'WEBHOOK' && auth_type && !custom_auth[auth_type];

    if (!is_legacy_auth) {
      return [custom_auth, false];
    }

    return [
      {
        ...custom_auth,
        [auth_type]: {
          label: source_types?.[auth_type || type]?.label,
          features: source_types?.[auth_type || type].features,
          fields: (
            source_types?.[auth_type || type].features
              .VERIFICATION as SourceTypeFeatureTypesUnmanaged
          ).fields,
        },
      },
      true,
    ];
  }, [feature, type, auth_type, enable_auth, source_types]);

  const field_path = 'config.auth';
  const verification_features =
    type && !!custom_auth
      ? custom_auth[auth_type]?.fields
      : isSourceTypeFeatureUnmanaged(feature) && feature?.fields
        ? feature.fields
        : undefined;

  if (custom_auth) {
    return (
      <Div m={{ y: 4 }}>
        <StyledCard overflow_hidden dashed={!enable_auth}>
          <StyledCardSection p={2}>
            <Div flex={{ justify: 'flex-start', align: 'center', gap: 4 }} p={1}>
              <SwitchInput name={fieldName('enable_auth', prefix)} />
              <Text size="s" semi muted={!enable_auth} flex={{ align: 'center' }}>
                Authenticate
                <Tooltip
                  tooltip="Enable authentication to verify the authenticity of the request made to Hookdeck. A request that fails verification will be logged, but not delivered."
                  cta={{
                    label: 'Learn more',
                    to: LINKS.product_docs.sources_add_auth,
                  }}>
                  <Icon muted right small pointer icon="info" />
                </Tooltip>
              </Text>
            </Div>
          </StyledCardSection>

          <StyledCardSection borderless={!enable_auth}>
            {is_legacy_auth && (
              <Div m={{ x: 4, t: 4, b: 2 }}>
                <Alert inline info>
                  <Text>
                    {source_types?.[auth_type]?.label} authentication is now supported through it's
                    dedicated source type. Change your source to {source_types?.[auth_type]?.label}{' '}
                    to update your authentication configuration.
                  </Text>
                  <Div m={{ t: 2 }}>
                    <Link
                      primary
                      onClick={() => {
                        setType(auth_type);
                      }}>
                      Migrate to {source_types?.[auth_type]?.label} source type
                    </Link>
                  </Div>
                </Alert>
              </Div>
            )}
            {enable_auth && (
              <StyledCard overflow_hidden borderless>
                <StyledCardSection p={3}>
                  {custom_auth && (
                    <SelectInput
                      m={0}
                      block
                      required
                      label="Authentication Method"
                      name={fieldName('config.auth_type', prefix)}
                      options={Object.entries(custom_auth)
                        .filter(([key]) => {
                          if (
                            !organization?.feature_flags?.oauth2_authorization_code &&
                            key === 'OAUTH2_AUTHORIZATION_CODE'
                          ) {
                            return false;
                          }
                          return true;
                        })
                        .map(([key, config]) => ({
                          value: key,
                          label: config.label,
                        }))}
                    />
                  )}
                  <VerificationFields
                    disabled={is_legacy_auth}
                    verification_features={verification_features}
                    prefix={fieldName(field_path, prefix)}
                  />
                </StyledCardSection>
              </StyledCard>
            )}
          </StyledCardSection>
        </StyledCard>
      </Div>
    );
  }
  if (isSourceTypeFeatureUnmanaged(feature) && feature?.required) {
    return (
      <VerificationFields
        verification_features={verification_features}
        prefix={fieldName(field_path, prefix)}
      />
    );
  }

  return (
    <Div m={{ y: 4 }}>
      <StyledCard overflow_hidden dashed={!enable_auth}>
        <StyledCardSection p={2}>
          <Div flex={{ justify: 'flex-start', align: 'center', gap: 4 }} p={1}>
            <SwitchInput name={fieldName('enable_auth', prefix)} />
            <Text size="s" semi muted={!enable_auth} flex={{ align: 'center' }}>
              Authenticate
              <Tooltip
                tooltip="Enable authentication to verify the authenticity of the request made to Hookdeck. A request that fails verification will be logged, but not delivered."
                cta={{
                  label: 'Learn more',
                  to: LINKS.product_docs.sources_add_auth,
                }}>
                <Icon muted right small pointer icon="info" />
              </Tooltip>
            </Text>
          </Div>
        </StyledCardSection>
        <StyledCardSection
          borderless={!enable_auth || !verification_features || verification_features.length === 0}>
          {enable_auth && verification_features && verification_features.length > 0 && (
            <StyledCard overflow_hidden borderless>
              <StyledCardSection p={{ x: 3, b: 3, t: 1 }}>
                <VerificationFields
                  verification_features={verification_features}
                  prefix={fieldName(field_path, prefix)}
                />
              </StyledCardSection>
            </StyledCard>
          )}
        </StyledCardSection>
      </StyledCard>
    </Div>
  );
}

function CustomHTTPFeature({ feature, prefix }: { feature: SourceFeatureType; prefix: string }) {
  const [_, { error }] = useField<boolean>(fieldName('config.allowed_http_methods', prefix));
  return (
    <Div m={{ y: 4 }}>
      <StyledCard overflow_hidden>
        <StyledCardSection p={2}>
          <Div flex={{ justify: 'space-between', align: 'center' }}>
            <Text size="s" semi flex={{ align: 'center' }}>
              HTTP Methods
              <Tooltip
                tooltip="Allow only specific HTTP methods to be accepted by Hookdeck. Requests that don't match the allowed HTTP will be logged."
                cta={{
                  label: 'Learn more',
                  to: LINKS.product_docs.sources_custom_methods,
                }}>
                <Icon muted right pointer icon="info" />
              </Tooltip>
            </Text>
            <Div flex={{ gap: 2 }}>
              {['GET', 'POST', 'PUT', 'PATCH', 'DELETE'].map((method) => (
                <StyledCard p={{ x: 2, y: 1 }} key={method}>
                  <CheckboxInput
                    m={0}
                    label={method}
                    name={fieldName(`config.allowed_http_methods.${method}`, prefix)}
                  />
                </StyledCard>
              ))}
            </Div>
          </Div>
        </StyledCardSection>
      </StyledCard>
      {error && (
        <Text m={{ t: 1, b: 0 }} size="s" as="p" danger>
          <Icon icon="info" left={1} />
          {error}
        </Text>
      )}
    </Div>
  );
}

export type CustomResponseValue = {
  content_type: 'json' | 'text' | 'xml';
  body: string;
};

function CustomResponseFeature({ prefix }: { feature: SourceFeatureType; prefix: string }) {
  const [{ value: enable_custom_response }] = useField<boolean>(
    fieldName('enable_custom_response', prefix),
  );
  const [{ value: custom_response }] = useField<CustomResponseValue>(
    fieldName('config.custom_response', prefix),
  );

  const [{ value: custom_response_content_type }, , { setValue: setCustomResponseContentType }] =
    useField<'text' | 'json' | 'xml'>(fieldName('config.custom_response.content_type', prefix));

  return (
    <Div m={{ y: 4 }}>
      <StyledCard overflow_hidden dashed={!enable_custom_response}>
        <StyledCardSection p={2}>
          <Div flex={{ justify: 'space-between', align: 'center' }}>
            <Div flex={{ gap: 4, align: 'center' }} p={1}>
              <SwitchInput name={fieldName('enable_custom_response', prefix)} />
              <Text size="s" semi muted={!enable_custom_response} flex={{ align: 'center' }}>
                Customize Response
                <Tooltip
                  tooltip="Override the default Hookdeck HTTP response with a custom static response in JSON, XML or TXT"
                  cta={{
                    label: 'Learn more',
                    to: LINKS.product_docs.sources_customize_response,
                  }}>
                  <Icon muted right small pointer icon="info" />
                </Tooltip>
              </Text>
            </Div>
            <Div flex={{ gap: 2, align: 'center' }}>
              {enable_custom_response && (
                <DropdownMenu
                  minimal
                  small
                  p={0}
                  label={custom_response_content_type?.toUpperCase()}
                  options={[
                    { label: 'JSON', onClick: () => setCustomResponseContentType('json') },
                    {
                      label: 'TEXT',
                      onClick: () => setCustomResponseContentType('text'),
                    },
                    {
                      label: 'XML',
                      onClick: () => setCustomResponseContentType('xml'),
                    },
                  ]}
                />
              )}
            </Div>
          </Div>
        </StyledCardSection>
        <StyledCardSection borderless={!enable_custom_response}>
          {enable_custom_response && (
            <StyledCard overflow_hidden borderless>
              <EditorInput
                name={fieldName('config.custom_response.body', prefix)}
                height="164px"
                language={custom_response?.content_type === 'json' ? 'json' : 'text'}
              />
            </StyledCard>
          )}
        </StyledCardSection>
      </StyledCard>
    </Div>
  );
}

function ManagedTokenFeature({ prefix }: { feature: SourceFeatureType; prefix: string }) {
  const [{ value: token }] = useField<string>(fieldName('config.auth.token', prefix));
  return (
    <Div m={{ y: 4 }}>
      <Text size="s" semi m={{ b: 1 }}>
        Source Token
      </Text>
      <CopyableField value={token} secret />
    </Div>
  );
}

function CustomUrlFeature({ prefix }: { feature: DestinationFeatureType; prefix: string }) {
  return (
    <Div m={{ y: 4 }}>
      <TextInput
        name={fieldName('config.url', prefix)}
        label="Destination URL"
        placeholder={`https://example.com`}
        help="The endpoint Hookdeck will route event to as HTTP requests"
        required
        mono
        m={0}
        w={100}
      />
    </Div>
  );
}

function CustomCliPathFeature({ prefix }: { feature: DestinationFeatureType; prefix: string }) {
  return (
    <Div m={{ y: 4 }}>
      <TextInput
        name={fieldName('config.path', prefix)}
        label="CLI Path"
        placeholder={`/webhooks`}
        help="The HTTP endpoint path Hookdeck will append to your localhost."
        required
        mono
        m={0}
        w={100}
      />
    </Div>
  );
}

function CustomPathForwardingFeature({
  feature,
  prefix,
}: {
  feature: DestinationFeatureType;
  prefix: string;
}) {
  const [{ value: path_forwarding }] = useField<boolean>(
    fieldName('config.path_forwarding', prefix),
  );

  return (
    <Div m={{ y: 4 }}>
      <StyledCard overflow_hidden dashed={!path_forwarding}>
        <StyledCardSection p={2}>
          <Div flex={{ justify: 'space-between', align: 'center' }} p={1}>
            <Div flex={{ gap: 4, align: 'center' }}>
              <SwitchInput name={fieldName('config.path_forwarding', prefix)} />
              <Text size="s" semi muted={!path_forwarding} flex={{ align: 'center' }}>
                Path forwarding
                <Tooltip
                  tooltip="Carry over the request path and append it to your destination path."
                  cta={{
                    label: 'Learn more',
                    to: LINKS.product_docs.destinations_advanced_edit,
                  }}>
                  <Icon muted right small pointer icon="info" />
                </Tooltip>
              </Text>
            </Div>
          </Div>
        </StyledCardSection>
      </StyledCard>
    </Div>
  );
}

function CustomDestinationHTTPMethodFeature({
  feature,
  prefix,
}: {
  feature: DestinationFeatureType;
  prefix: string;
}) {
  const [{ value: enable_custom_http_method }] = useField<boolean>(
    fieldName('enable_custom_http_method', prefix),
  );
  return (
    <Div m={{ y: 4 }}>
      <StyledCard overflow_hidden dashed={!enable_custom_http_method}>
        <StyledCardSection p={2}>
          <Div flex={{ justify: 'space-between', align: 'center' }} p={1}>
            <Div flex={{ gap: 4, align: 'center' }}>
              <SwitchInput name={fieldName('enable_custom_http_method', prefix)} />
              <Text size="s" semi muted={!enable_custom_http_method} flex={{ align: 'center' }}>
                Customize HTTP Method
                <Tooltip
                  tooltip="Force the requests to your destination to use a specific HTTP method. By default the request will be made with the same method as the original request."
                  cta={{
                    label: 'Learn more',
                    to: LINKS.product_docs.destinations_advanced_edit,
                  }}>
                  <Icon muted right small pointer icon="info" />
                </Tooltip>
              </Text>
            </Div>
          </Div>
        </StyledCardSection>
        <StyledCardSection borderless={!enable_custom_http_method}>
          {enable_custom_http_method && (
            <StyledCard overflow_hidden borderless>
              <StyledCardSection p={3}>
                <SelectInput
                  block
                  m={0}
                  name={fieldName('config.http_method', prefix)}
                  label="HTTP Method"
                  required
                  options={[
                    { value: 'GET', label: 'GET' },
                    { value: 'POST', label: 'POST' },
                    { value: 'PUT', label: 'PUT' },
                    { value: 'PATCH', label: 'PATCH' },
                    { value: 'DELETE', label: 'DELETE' },
                  ]}
                />
              </StyledCardSection>
            </StyledCard>
          )}
        </StyledCardSection>
      </StyledCard>
    </Div>
  );
}

function MaxThroughputRateFeature({
  feature,
  prefix,
}: {
  feature: DestinationFeatureType;
  prefix: string;
}) {
  const [{ value: enabled_rate_limit }] = useField<boolean>(fieldName('enable_rate_limit', prefix));
  const { team } = useContext(DashboardContext);

  return (
    <Div m={{ y: 4 }}>
      <StyledCard overflow_hidden dashed={!enabled_rate_limit}>
        <StyledCardSection p={2}>
          <Div flex={{ justify: 'flex-start', align: 'center', gap: 4 }} p={1}>
            <SwitchInput name={fieldName('enable_rate_limit', prefix)} />
            <Text size="s" semi muted={!enabled_rate_limit} flex={{ align: 'center' }}>
              Max delivery rate
              <Tooltip
                tooltip="Set a maximum throughput rate for events delivered to your destination."
                cta={{
                  label: 'Learn more',
                  to: LINKS.product_docs.destinations_max_delivery_rate,
                }}>
                <Icon muted right small pointer icon="info" />
              </Tooltip>
            </Text>
          </Div>
        </StyledCardSection>
        <StyledCardSection borderless={!enabled_rate_limit}>
          {enabled_rate_limit && (
            <StyledCard overflow_hidden borderless>
              <StyledCardSection p={3}>
                <Grid>
                  <GridUnit size={1 / 2}>
                    <TextInput
                      m={{ r: 2, b: 0 }}
                      name={fieldName('config.rate_limit', prefix)}
                      label="Rate"
                      default_value={
                        team?.max_events_per_second ? team?.max_events_per_second.toString() : '5'
                      }
                      type="number"
                      min={1}
                      required
                    />
                  </GridUnit>
                  <GridUnit size={1 / 2}>
                    <SelectInput
                      m={{ l: 2, b: 0 }}
                      block
                      name={fieldName('config.rate_limit_period', prefix)}
                      label="Time Interval"
                      options={[
                        {
                          value: 'second',
                          label: 'per second',
                        },
                        {
                          value: 'minute',
                          label: 'per minute',
                        },
                        {
                          value: 'hour',
                          label: 'per hour',
                        },
                        {
                          value: 'concurrent',
                          label: 'concurrent',
                        },
                      ]}
                      required
                    />
                  </GridUnit>
                </Grid>
              </StyledCardSection>
            </StyledCard>
          )}
        </StyledCardSection>
      </StyledCard>
    </Div>
  );
}

function DestinationVerificationFeature({
  feature,
  prefix,
}: {
  feature: DestinationFeatureType;
  prefix: string;
}) {
  const { organization } = useContext(DashboardContext);
  const [{ value: auth_type }] = useField<SourceType>(fieldName('config.auth_type', prefix));

  const custom_auth = !feature.managed && 'auth' in feature ? feature.auth : undefined;

  const field_path = 'config.auth';
  const verification_features = custom_auth ? custom_auth[auth_type]?.fields : undefined;

  if (custom_auth) {
    return (
      <Div m={{ y: 4 }}>
        <StyledCard overflow_hidden>
          <StyledCardSection p={{ x: 3, y: 3 }}>
            <Text size="s" semi flex={{ align: 'center' }}>
              Authentication Method
              <Tooltip
                tooltip="Enable authentication to verify the authenticity of the request made to Hookdeck. A request that fails verification will be logged, but not delivered."
                cta={{
                  label: 'Learn more',
                  to: LINKS.product_docs.sources_add_auth,
                }}>
                <Icon muted right small pointer icon="info" />
              </Tooltip>
            </Text>
          </StyledCardSection>

          <StyledCardSection p={3}>
            {custom_auth && (
              <SelectInput
                m={0}
                block
                name={fieldName('config.auth_type', prefix)}
                options={Object.entries(custom_auth)
                  .filter(([key]) => {
                    if (
                      !organization?.feature_flags?.oauth2_authorization_code &&
                      key === 'OAUTH2_AUTHORIZATION_CODE'
                    ) {
                      return false;
                    }
                    return true;
                  })
                  .map(([key, config]) => ({
                    value: key,
                    label: config.label,
                  }))}
              />
            )}
            <VerificationFields
              verification_features={verification_features}
              prefix={fieldName(field_path, prefix)}
            />
          </StyledCardSection>
        </StyledCard>
      </Div>
    );
  }
  if (!feature.managed && 'required' in feature && feature.required) {
    return (
      <VerificationFields
        verification_features={verification_features}
        prefix={fieldName(field_path, prefix)}
      />
    );
  }

  return (
    <Div m={{ y: 4 }}>
      <StyledCard overflow_hidden>
        <StyledCardSection p={2}>
          <Div flex={{ justify: 'flex-start', align: 'center', gap: 4 }} p={1}>
            <SwitchInput name={fieldName('enable_auth', prefix)} />
            <Text size="s" semi flex={{ align: 'center' }}>
              Authenticate
              <Tooltip
                tooltip="Enable authentication to verify the authenticity of the request made to Hookdeck. A request that fails verification will be logged, but not delivered."
                cta={{
                  label: 'Learn more',
                  to: LINKS.product_docs.sources_add_auth,
                }}>
                <Icon muted right small pointer icon="info" />
              </Tooltip>
            </Text>
          </Div>
        </StyledCardSection>

        {verification_features && verification_features.length > 0 && (
          <StyledCardSection p={{ x: 3, b: 3, t: 1 }}>
            <VerificationFields
              verification_features={verification_features}
              prefix={fieldName(field_path, prefix)}
            />
          </StyledCardSection>
        )}
      </StyledCard>
    </Div>
  );
}

export type SourceFeatureType = SourceTypeFeatures['VERIFICATION'] & {
  type: keyof SourceTypeFeatures | keyof DestinationTypeFeatures;
};

export function SourceFeature({ feature, prefix }: { feature: SourceFeatureType; prefix: string }) {
  switch (feature.type) {
    case 'VERIFICATION':
      return <VerificationFeature feature={feature} prefix={prefix} />;
    case 'CUSTOM_HTTP_METHOD':
      return <CustomHTTPFeature feature={feature} prefix={prefix} />;
    case 'CUSTOM_RESPONSE':
      return <CustomResponseFeature feature={feature} prefix={prefix} />;
    case 'MANAGED_TOKEN':
      return <ManagedTokenFeature feature={feature} prefix={prefix} />;
    default:
      return null;
  }
}

export type DestinationFeatureType = DestinationTypeFeatures['VERIFICATION'] & {
  type: keyof DestinationTypeFeatures;
};

export function DestinationFeature({
  feature,
  prefix,
}: {
  feature: DestinationFeatureType;
  prefix: string;
}) {
  switch (feature.type) {
    case 'VERIFICATION':
      return <DestinationVerificationFeature feature={feature} prefix={prefix} />;
    case 'CUSTOM_URL':
      return <CustomUrlFeature feature={feature} prefix={prefix} />;
    case 'CUSTOM_CLI_PATH':
      return <CustomCliPathFeature feature={feature} prefix={prefix} />;
    case 'PATH_FORWARDING':
      return <CustomPathForwardingFeature feature={feature} prefix={prefix} />;
    case 'CUSTOM_DESTINATION_HTTP_METHOD':
      return <CustomDestinationHTTPMethodFeature feature={feature} prefix={prefix} />;
    case 'MAX_DELIVERY_RATE':
      return <MaxThroughputRateFeature feature={feature} prefix={prefix} />;
    default:
      return null;
  }
}
