import { format } from 'date-fns';
import { Form, Formik } from 'formik';
import { useContext, useMemo } from 'react';

import { relative_dates, RelativeDate } from '../../../../utils/date';
import Button from '../../../common/base/Button';
import { StyledCardSection } from '../../../common/base/Card';
import Dropdown from '../../../common/Dropdown';
import DatePickerInput, {
  formatDateFilterValues,
} from '../../../common/Form/Fields/DatePickerInput';
import { Div } from '../../../common/helpers/StyledUtils';
import useSearchQuery from '../../../hooks/useSearchQuery';
import { DashboardContext } from '../DashboardContext';
import { getTimerangeLabel } from '../Events/Histogram';

export type DateRange = { min?: string; max?: string; relative?: RelativeDate };

interface Props {
  date_range: DateRange;
  onDateRangeChanged: (date_range: DateRange) => void;
}

export const useDateRange = (
  default_relative = 'yesterday' as keyof typeof relative_dates,
): [DateRange, (date_range: DateRange) => void] => {
  const { query, updateSearchQuery } = useSearchQuery<{ date: DateRange }>();

  let date_range: DateRange = query.date;

  if (!query.date) {
    date_range = {
      relative: default_relative,
    };
  }

  if (date_range.relative) {
    const { min, max } = relative_dates[date_range.relative].convert(new Date());
    date_range = {
      ...date_range,
      min: min.toISOString(),
      max: max.toISOString(),
    };
  }

  const onDateRangeChanged = (date_range: DateRange) => {
    if (date_range.relative) {
      date_range.min = undefined;
      date_range.max = undefined;
    }
    updateSearchQuery({ date: date_range });
  };

  return [date_range, onDateRangeChanged];
};

const MetricDatePicker: React.FC<Props> = ({ date_range, onDateRangeChanged }) => {
  const form_initial_values = useMemo(
    () => ({
      date_range,
      start_time: format(new Date(date_range!.min as string), 'HH:mm:ss'),
      end_time: format(new Date(date_range!.max as string), 'HH:mm:ss'),
    }),
    [date_range],
  );
  const { subscription } = useContext(DashboardContext);
  return (
    <Dropdown
      outline
      expand_icon="chevron_down"
      label={getTimerangeLabel(date_range, subscription!.retention_days)}
      icon="date">
      {(toggle) => (
        <Formik
          initialValues={form_initial_values}
          onSubmit={(values) => {
            const { start_date, end_date } = formatDateFilterValues({
              start_date: values.date_range.min,
              start_time: values.start_time,
              end_date: values.date_range.max,
              end_time: values.end_time,
            });
            onDateRangeChanged({
              relative: values.date_range.relative || undefined,
              min: start_date,
              max: end_date,
            });
            toggle(false);
          }}>
          {({ handleSubmit }) => (
            <Form>
              <StyledCardSection>
                <DatePickerInput name="date_range" display_reset={false} time_format={'hh:mm:ss'} />
              </StyledCardSection>
              <StyledCardSection flex={{ justify: 'flex-end' }} p={3}>
                <Div flex={{ gap: 3 }}>
                  <Button neutral onClick={() => toggle(false)}>
                    Cancel
                  </Button>
                  <Button primary onClick={() => handleSubmit()}>
                    Apply
                  </Button>
                </Div>
              </StyledCardSection>
            </Form>
          )}
        </Formik>
      )}
    </Dropdown>
  );
};

export default MetricDatePicker;
