import { Grid, GridUnit } from '@hookdeck/theme';

import { StyledCard, StyledCardSection } from '../../../../../common/base/Card';
import Text from '../../../../../common/base/Text';
import { Div } from '../../../../../common/helpers/StyledUtils';
import React, { useContext } from 'react';
import { DashboardContext } from '../../../DashboardContext';
import APIMethodKeys from '../../../../../../client/APIMethodKeys';
import useSWR from 'swr';
import { GlobalContext } from '../../../../../contexts/GlobalContext';
import { OrbSubscription } from '../../../../../../typings/Orb.interface';
import Button from '../../../../../common/base/Button';

const ProjectThroughputs: React.FC<{ subscription_details: OrbSubscription }> = ({
  subscription_details,
}) => {
  const { HookdeckAPI } = useContext(GlobalContext);
  const { organization } = useContext(DashboardContext);

  const { data: teams } = useSWR(APIMethodKeys.teams.listAll(), () => HookdeckAPI.teams.listAll());

  const teams_for_current_organization = teams?.filter(
    (team) => team.organization_id === organization!.id,
  );

  const price_per_team = teams_for_current_organization?.reduce(
    (obj, team) => {
      const interval = subscription_details.price_intervals?.find(
        (pi) => team.billing_meta.price_interval_id === pi.id,
      );
      obj[team.id] = interval?.price.unit_config?.unit_amount || '0';
      return obj;
    },
    {} as Record<string, string>,
  );

  return (
    <>
      <Div flex={{ justify: 'space-between', align: 'center' }} m={{ b: 4 }}>
        <Div>
          <Text bold size="l" as="h2" m={0}>
            Projects throughput
          </Text>
          <Text muted m={0}>
            Throughput is managed on a per-project basis, and can be modified under a project’s
            Quotas tab.
          </Text>
        </Div>
        <Button neutral icon="rate_limit" to="/settings/project/quotas">
          Quotas
        </Button>
      </Div>
      <StyledCard>
        <StyledCardSection muted>
          <Grid>
            <GridUnit size={2 / 4}>
              <Text muted semi size="xs" p={{ x: 4, y: 1 }} style={{ lineHeight: '20px' }}>
                Project
              </Text>
            </GridUnit>
            <GridUnit size={1 / 4}>
              <Text muted semi size="xs" p={{ x: 4, y: 1 }} style={{ lineHeight: '20px' }}>
                Rate
              </Text>
            </GridUnit>
            <GridUnit size={1 / 4}>
              <Div flex={{ justify: 'flex-end' }}>
                <Text muted semi size="xs" p={{ x: 4, y: 1 }} style={{ lineHeight: '20px' }}>
                  Price
                </Text>
              </Div>
            </GridUnit>
          </Grid>
        </StyledCardSection>
        {price_per_team &&
          teams_for_current_organization?.map((team) => (
            <StyledCardSection key={team.id}>
              <Grid>
                <GridUnit size={2 / 4}>
                  <Div flex={{ align: 'center' }}>
                    <Text size="s" ellipsis p={{ x: 4, y: 3 }}>
                      {team.name}
                    </Text>
                  </Div>
                </GridUnit>
                <GridUnit size={1 / 4}>
                  <Text size="s" p={{ x: 4, y: 3 }}>
                    {team.max_events_per_second} events per second
                  </Text>
                </GridUnit>
                <GridUnit size={1 / 4}>
                  <Div flex={{ justify: 'flex-end' }} p={{ x: 4, y: 3 }}>
                    <Text size="s">${price_per_team[team.id]}</Text>
                  </Div>
                </GridUnit>
              </Grid>
            </StyledCardSection>
          ))}
        {price_per_team && (
          <StyledCardSection flex={{ justify: 'space-between' }} p={{ x: 4, y: 3 }}>
            <Text semi size="s">
              Total throughput cost
            </Text>
            <Text semi size="s">
              $
              {Object.values(price_per_team)
                .reduce((total: number, v: string) => (total + Number(v)) as any, 0)
                .toFixed(2)}
            </Text>
          </StyledCardSection>
        )}
      </StyledCard>
    </>
  );
};

export default ProjectThroughputs;
