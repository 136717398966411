import { Formik, useField } from 'formik';
import { useContext } from 'react';
import styled, { css } from 'styled-components';
import useSWR from 'swr';

import { Grid, GridUnit } from '@hookdeck/theme';

import APIMethodKeys from '../../../../../client/APIMethodKeys';
import { showChat } from '../../../../../utils/liveChat';
import Badge from '../../../../common/base/Badge';
import { StyledCard, StyledCardSection } from '../../../../common/base/Card';
import Divider from '../../../../common/base/Divider';
import Icon from '../../../../common/base/Icon';
import Link from '../../../../common/base/Link';
import Text from '../../../../common/base/Text';
import { StyledRadio } from '../../../../common/Form/Fields/RadioInput';
import { Div } from '../../../../common/helpers/StyledUtils';
import Modal from '../../../../common/Modal';
import { useToasts } from '../../../../common/Toast';
import { GlobalContext } from '../../../../contexts/GlobalContext';
import { TeamPermission } from '../../../../contexts/TeamPermissionContext';
import useSearchQuery from '../../../../hooks/useSearchQuery';
import { DashboardContext } from '../../DashboardContext';

const StyleRadioRow = styled(StyledRadio)(
  ({ theme, checked }) => css`
    ${checked &&
    css`
      background-color: ${theme.colors.surface.base.background};
    `}
  `,
);

const RadioTableInput: React.FC<{
  current_value: number;
  tiers: { [key: number]: { compare_at_price: number; price: number; legacy: boolean } };
  name: string;
}> = ({ tiers, name, current_value }) => {
  const [field] = useField(name);

  const { team_role } = useContext(DashboardContext);

  const tiers_array = Object.entries(tiers);

  const disabled = team_role !== 'admin';

  return (
    <>
      <div role="group">
        {tiers_array.map(([tier, config]) => {
          return (
            <StyledCardSection key={tier}>
              <StyleRadioRow
                key={tier}
                as="label"
                inline={true}
                highlight={true}
                disabled={disabled}
                m={0}
                p={3}
                checked={field.value === tier}>
                <Grid>
                  <GridUnit size={1 / 2}>
                    <Div flex={{ align: 'center' }}>
                      <input
                        {...field}
                        onChange={() => field.onChange({ target: { value: tier, name } })}
                        checked={field.value === tier}
                        disabled={disabled}
                        type="radio"
                      />
                      <Text semi m={{ l: 2 }}>
                        {tier} events per second
                      </Text>
                      {current_value === Number(tier) && (
                        <Badge m={{ l: 2 }} primary subtle>
                          Current {config.legacy && '(Legacy)'}
                        </Badge>
                      )}
                    </Div>
                  </GridUnit>
                  <GridUnit size={1 / 2}>
                    <Div flex={{ justify: 'flex-end', gap: 2 }}>
                      {config.compare_at_price ? (
                        <Text muted semi strike>
                          ${(config.compare_at_price / 100).toFixed(2)}
                        </Text>
                      ) : null}
                      <Text semi>${(config.price / 100).toFixed(2)}</Text>
                    </Div>
                  </GridUnit>
                </Grid>
              </StyleRadioRow>
            </StyledCardSection>
          );
        })}
        <StyledCardSection>
          <StyleRadioRow
            as="label"
            inline={true}
            highlight={true}
            disabled={disabled}
            m={0}
            p={3}
            checked={field.value === 'custom'}>
            <Grid>
              <GridUnit size={1 / 2}>
                <Div flex={{ align: 'center' }}>
                  <input
                    {...field}
                    onChange={() => field.onChange({ target: { value: 'custom', name } })}
                    checked={field.value === 'custom'}
                    disabled={disabled}
                    type="radio"
                  />
                  <Text m={{ l: 2 }} semi>
                    {'>'} {Object.keys(tiers)[Object.keys(tiers).length - 1]} events per second
                  </Text>
                </Div>
              </GridUnit>
              <GridUnit size={1 / 2}>
                <Div flex={{ justify: 'flex-end', gap: 2 }}>
                  <Link onClick={showChat} neutral>
                    Contact Us
                  </Link>
                </Div>
              </GridUnit>
            </Grid>
          </StyleRadioRow>
        </StyledCardSection>
      </div>
    </>
  );
};

const ChangeThrouhgputModal: React.FC = () => {
  const { HookdeckAPI } = useContext(GlobalContext);
  const { team, team_role, subscription, mutateTeam } = useContext(DashboardContext);
  const { addToast } = useToasts();
  const { updateSearchQuery } = useSearchQuery<{ view: 'throughput' }>();

  const { data: tiers } = useSWR(APIMethodKeys.billing.getThroughputTiers(), () =>
    HookdeckAPI.billing.getThroughputTiers(),
  );

  const onClose = () => {
    updateSearchQuery({ view: undefined });
  };

  const onSubmit = async (values) => {
    if (values.throughput_tier === team!.max_events_per_second) {
      return;
    }
    return HookdeckAPI.billing
      .updateThroughput(Number(values.throughput_tier))
      .then((team) => {
        mutateTeam(team);
        addToast('success', 'Project throughput successfully updated.');
        onClose();
      })
      .catch(() => {
        addToast('error', 'Failed to update project throughput.');
      });
  };

  return (
    <Formik
      initialValues={{ throughput_tier: String(team!.max_events_per_second) }}
      onSubmit={onSubmit}>
      {(props) => (
        <form onSubmit={props.handleSubmit}>
          <TeamPermission role="admin">
            <Modal
              size="large"
              title="Change project throughput"
              submit_label={props.values.sku === 'custom' ? 'Contact us' : 'Update throughput'}
              cancel_label="Cancel"
              submit_disabled={
                team_role === 'admin' &&
                Number(props.values.throughput_tier) === team!.max_events_per_second
              }
              onSubmit={props.handleSubmit}
              onCancel={onClose}
              onClose={onClose}
              is_submitting={props.isSubmitting}>
              <Text as="p">
                The throughput rate determines the events per second that Hookdeck will process and
                deliver for this project. Upon changing throughput, you will be charged the
                corresponding fee. You can change your throughput at any time. Reducing your
                throughput will grant a pro-rated credit.
              </Text>
              {team!.billing_meta.included !== null &&
              team!.billing_meta.included > 0 &&
              subscription?.included_throughput &&
              subscription?.included_throughput > 0 ? (
                <Div flex m={{ b: 4 }}>
                  <Icon icon="info" left muted m={{ t: 0.5 }} />
                  <Text muted>
                    This project throughput is discounted for {subscription!.included_throughput}{' '}
                    events/s because of a grandfathered plan.
                  </Text>
                </Div>
              ) : null}
              <StyledCard overflow_hidden>
                <StyledCardSection muted p={{ y: 1, x: 3 }}>
                  <Grid>
                    <GridUnit size={1 / 2}>
                      <Text semi muted size="s">
                        Throughput rate
                      </Text>
                    </GridUnit>
                    <GridUnit size={1 / 2}>
                      <Div flex={{ justify: 'flex-end' }}>
                        <Text semi muted size="s">
                          Price per month
                        </Text>
                      </Div>
                    </GridUnit>
                  </Grid>
                </StyledCardSection>
                <Divider />
                {tiers && (
                  <RadioTableInput
                    current_value={team!.max_events_per_second}
                    tiers={tiers}
                    name="throughput_tier"
                  />
                )}
              </StyledCard>
            </Modal>
          </TeamPermission>
        </form>
      )}
    </Formik>
  );
};

export default ChangeThrouhgputModal;
