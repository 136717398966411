import { useContext } from 'react';
import useSWR from 'swr';

import APIMethodKeys from '../../../../client/APIMethodKeys';
import LINKS from '../../../../configs/links';
import { stringifyQuery } from '../../../../utils';
import { useCopyToClipboard } from '../../../../utils/copy';
import Button from '../../../common/base/Button';
import { StyledCard, StyledCardSection } from '../../../common/base/Card';
import Divider from '../../../common/base/Divider';
import Link from '../../../common/base/Link';
import Text from '../../../common/base/Text';
import CopyableField from '../../../common/CopyableField';
import Editor from '../../../common/Editor';
import { Div } from '../../../common/helpers/StyledUtils';
import Modal from '../../../common/Modal';
import { GlobalContext } from '../../../contexts/GlobalContext';

const action_configs = {
  'create-source': {
    description: 'Use our API to create or update a source.',
    method: {
      api: {
        link: LINKS.api_ref.sources,
        method: 'PUT',
        path: '/sources',
        body: (values) => {
          return {
            ...values,
            name: values.name,
            description: values.description || undefined,
            allowed_http_methods: values.allowed_http_methods || undefined,
            verification: values.verification || undefined,
            custom_response: values.custom_response || undefined,
          };
        },
      },
    },
  },
  'create-destination': {
    description: 'Use our API to create or update a destination.',
    method: {
      api: {
        link: LINKS.api_ref.destinations,
        method: 'PUT',
        path: '/destinations',
        body: (values) => values,
      },
    },
  },
  'create-connection': {
    description: 'Use our API to create or update a connection.',
    method: {
      api: {
        link: LINKS.api_ref.destinations,
        method: 'POST',
        path: '/connections',
        body: (values) => values,
      },
    },
  },
  'list-requests': {
    description: 'Use our API to list requests.',
    method: {
      api: {
        link: LINKS.api_ref.requests,
        method: 'GET',
        path: '/requests',
        body: (values) => values,
      },
    },
  },
  'list-events': {
    description: 'Use our API to list events.',
    method: {
      api: {
        link: LINKS.api_ref.events,
        method: 'GET',
        path: '/events',
        body: (values) => values,
      },
    },
  },
};

type Action = keyof typeof action_configs;

const ApiModal: React.FC<{
  action: Action;
  onClose: () => void;
  values: any;
}> = ({ action, onClose, values }) => {
  const { HookdeckAPI } = useContext(GlobalContext);
  const formatted_values = action_configs[action].method.api.body(values);
  const is_get = action_configs[action].method.api.method === 'GET';
  const values_to_string = is_get
    ? '?' + stringifyQuery(formatted_values)
    : JSON.stringify(formatted_values, null, 2);
  const copyToClipboard = useCopyToClipboard();

  const { data: api_key } = useSWR(APIMethodKeys.session.getCurrentApiKey(), () =>
    HookdeckAPI.session.getCurrentApiKey(),
  );

  return (
    <Modal title="API Inputs" onClose={onClose}>
      <Div>
        <Div m={{ b: 4 }} flex={{ justify: 'space-between', align: 'center' }}>
          <Text>{action_configs[action].description}</Text>
          <Div flex={{ gap: 2 }}>
            <Button
              as="a"
              invisible
              icon="link"
              href={action_configs[action].method.api.link}
              target="_blank">
              API Ref
            </Button>
            <Button
              invisible
              icon="copy"
              onClick={() => {
                if (is_get) {
                  copyToClipboard(
                    `curl -g '${process.env.API_URL}/2024-03-01${action_configs[action].method.api.path}${values_to_string}' \\
-H "Authorization: Bearer ${api_key?.key || ''}"`,
                  );
                } else {
                  copyToClipboard(
                    `curl -g -X ${action_configs[action].method.api.method} '${process.env.API_URL}/2024-03-01${action_configs[action].method.api.path}' \\
-H "Authorization: Bearer ${api_key?.key || ''}" \\
-H "Content-Type: application/json" \\
-d '${JSON.stringify(formatted_values)}'`,
                  );
                }
              }}>
              cURL
            </Button>
          </Div>
        </Div>
        <Div flex={{ gap: 4 }}>
          <Div w={50}>
            <Text semi size="s" m={{ b: 1 }}>
              Method
            </Text>
            <CopyableField value={action_configs[action].method.api.method} mono />
          </Div>
          <Div w={50}>
            <Text semi size="s" m={{ b: 1 }}>
              Endpoint URL
            </Text>
            <CopyableField
              value={`${process.env.API_URL}/2024-03-01${action_configs[action].method.api.path}`}
              mono
            />
          </Div>
        </Div>
        <Div flex={{ gap: 4 }} m={{ t: 4 }}>
          <Div w={50}>
            <Text semi size="s" m={{ b: 1 }}>
              Authorization Header Key
            </Text>
            <CopyableField value={'Authorization'} mono />
          </Div>
          <Div w={50}>
            <Text semi size="s" m={{ b: 1 }}>
              Authorization Header Value
            </Text>
            <CopyableField value={`Bearer ${api_key?.key || ''}`} mono secret />
          </Div>
        </Div>
        <StyledCard m={{ t: 6 }} overflow_hidden>
          <StyledCardSection
            flex={{ justify: 'space-between', align: 'center' }}
            p={{ l: 4, r: 1, y: 0.5 }}
            muted>
            <Text size="s" semi>
              {is_get ? 'Request URL Query' : 'Request JSON Body'}
            </Text>
            <Button
              invisible
              on="background"
              icon="copy"
              onClick={() => copyToClipboard(values_to_string)}
            />
          </StyledCardSection>
          <StyledCardSection muted>
            <Editor
              prevent_theme_change
              language={is_get ? 'text' : 'json'}
              value={values_to_string}
              height={`${values_to_string.split('\n').length * 20 + 20}px`}
            />
          </StyledCardSection>
        </StyledCard>
      </Div>
      <Div m={{ x: -4 }}>
        <Divider m={{ t: 4 }} w={100} />
      </Div>
      <Div flex={{ gap: 4 }} m={{ t: 4 }}>
        <Text muted>Or integrate with...</Text>
        <Link icon="link" neutral m={{ l: 'auto' }} href={LINKS.terraform_provider} target="_blank">
          Terraform
        </Link>
        <Link icon="link" neutral href={LINKS.go_sdk} target="_blank">
          Go SDK
        </Link>
        <Link icon="link" neutral href={LINKS.typescript_sdk} target="_blank">
          TypeScript SDK
        </Link>
      </Div>
    </Modal>
  );
};

export default ApiModal;
