import { FormEvent, useState } from 'react';
import styled, { css, useTheme } from 'styled-components';

import { StyledCard } from '../../base/Card';
import Text from '../../base/Text';
import EditorInput from '../../Form/Fields/EditorInput';
import TextInput from '../../Form/Fields/TextInput';
import { Div } from '../../helpers/StyledUtils';

const StyledInput = styled(TextInput)(
  ({ theme }) => css`
    ${Text} {
      font-size: ${theme.pxToRem(theme.font_sizes.xs.font_size)};
      line-height: ${theme.pxToRem(theme.font_sizes.xs.line_height)};
    }
  `,
);
const StyledContainer = styled(Div)`
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(100px, 1fr));
  column-gap: 12px;
  max-width: 100%;
  min-width: 100%;

  ${StyledInput} {
    width: 100%;
  }
`;

const FocusableEditorInput: React.FC<{ label: string; name: string; onBlur?: () => void }> = ({
  label,
  name,
  onBlur,
}) => {
  const theme = useTheme();
  const [focused, setFocused] = useState<boolean>(false);
  const focused_styles = {
    position: 'absolute',
    width: '100%',
    minWidth: '500px',
    height: '500px',
    zIndex: theme.zindex.fixed,
    boxShadow: theme.elevation[3],
    border: theme.border,
  };

  return (
    <Div style={{ position: 'relative', minHeight: '100%' }}>
      {label && (
        <Text muted as="label" m={{ b: 1 }} semi size="xs">
          {label}
        </Text>
      )}
      <StyledCard overflow_hidden style={focused ? (focused_styles as object) : {}}>
        <EditorInput
          onBlur={() => {
            setFocused(false);
            onBlur && onBlur();
          }}
          display="minimal"
          onFocus={() => setFocused(true)}
          height={focused ? '500px' : '100px'}
          name={name}
          format_on_blur
        />
      </StyledCard>
    </Div>
  );
};

const JSONFilter: React.FC<{
  delete_button?: JSX.Element;
  onSubmit: (e?: FormEvent<HTMLFormElement>) => void;
}> = ({ delete_button, onSubmit }) => {
  return (
    <StyledContainer>
      <FocusableEditorInput label={'Request Headers'} name="request.headers" onBlur={onSubmit} />
      <FocusableEditorInput label={'Request Body'} name="request.body" onBlur={onSubmit} />
      <FocusableEditorInput label={'Request Query'} name="request.parsed_query" onBlur={onSubmit} />
      <Div flex>
        <StyledInput
          m={0}
          name="request.path"
          onBlur={onSubmit}
          label={'Request Path'}
          placeholder="Path..."
        />
        <Div m={{ t: 6 }}>{delete_button && delete_button}</Div>
      </Div>
    </StyledContainer>
  );
};

export default JSONFilter;
