import { useCallback, useContext, useState } from 'react';

import { APIBatchOperation } from '../../../../../../typings/BatchOperation.interface';
import { GlobalContext } from '../../contexts/GlobalContext';
import Button from '../base/Button';
import ProgressBar from '../base/ProgressBar';
import Text from '../base/Text';
import { Div } from '../helpers/StyledUtils';
import { useToasts } from '../Toast';
import { bulk_retry_models_label, BulkRetryModels } from './';

const BulkRetryProgress: React.FC<{
  model: BulkRetryModels;
  bulk_retry: APIBatchOperation;
  onCancel?: (bulk_retry: APIBatchOperation) => void;
}> = ({ model, bulk_retry, onCancel }) => {
  const { HookdeckAPI } = useContext(GlobalContext);
  const { addToast } = useToasts();
  const [pending, setPending] = useState(false);

  const model_view_entries = {
    events: {
      pathname: `/events`,
      search: `?bulk_retry_id=${bulk_retry.id}`,
    },
    requests: {
      pathname: `/requests`,
      search: `?bulk_retry_id=${bulk_retry.id}`,
    },
  };
  const handleCancel = useCallback(async () => {
    setPending(true);
    if (pending) {
      return;
    }
    try {
      const cancelled_bulk_retry = await HookdeckAPI.bulk[model].cancel(bulk_retry.id);
      onCancel && onCancel(cancelled_bulk_retry);
      addToast('success', `Cancelled bulk retry #${bulk_retry.number}`);
    } catch (e) {
      addToast('error', `Failed to cancel bulk retry #${bulk_retry.number}`);
    }
    setPending(false);
  }, [bulk_retry, pending, model]);

  return (
    <>
      <Div flex={{ justify: 'space-between' }}>
        <Text semi size="s">
          {bulk_retry.cancelled_at ? 'Cancelled' : 'Retry progress'}{' '}
        </Text>
        <Text size="s">{Math.floor(bulk_retry.progress * 100)}%</Text>
      </Div>
      <ProgressBar m={{ t: 2 }} value={bulk_retry.progress} cancelled={!!bulk_retry.cancelled_at} />
      <Div flex={{ justify: 'space-between', align: 'center' }} m={{ t: 5 }}>
        <Text bold>
          {bulk_retry?.estimated_count} {bulk_retry_models_label[model]}
        </Text>
        <Div flex={{ justify: 'flex-end' }}>
          {bulk_retry.in_progress && (
            <Button disabled={pending} onClick={handleCancel} neutral icon="close">
              Cancel
            </Button>
          )}
          {model_view_entries[model] && (
            <Button neutral m={{ l: 2 }} to={model_view_entries[model]} icon="link">
              View {bulk_retry_models_label[model]}
            </Button>
          )}
        </Div>
      </Div>
    </>
  );
};

export default BulkRetryProgress;
