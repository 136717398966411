import {
  add,
  differenceInSeconds,
  format,
  setMilliseconds,
  startOfDay,
  startOfHour,
  sub,
} from 'date-fns';
import { Formik, useField } from 'formik';
import { FormEvent, useContext, useMemo } from 'react';

import { EventListFiltersProps } from '../../../../typings/EventList.interface';
import { RequestListFiltersProps } from '../../../../typings/RequestList.interface';
import {
  chart_resolutions,
  getBestResolution,
  getTime,
  startOfTime,
  timebar_resolutions,
} from '../../../../utils/chart';
import { relative_dates, RelativeDate } from '../../../../utils/date';
import { DashboardContext } from '../../../scenes/DashboardScene/DashboardContext';
import { getTimerangeLabel } from '../../../scenes/DashboardScene/Events/Histogram';
import Button from '../../base/Button';
import { StyledCardSection } from '../../base/Card';
import Dropdown from '../../Dropdown';
import DatePickerInput, { formatDateFilterValues } from '../../Form/Fields/DatePickerInput';
import { Div } from '../../helpers/StyledUtils';

const DateFilter: React.FC<{
  filters: EventListFiltersProps | RequestListFiltersProps;
  delete_button?: JSX.Element;
  onSubmit: (e?: FormEvent<HTMLFormElement>) => void;
}> = ({ delete_button, onSubmit }) => {
  const { subscription } = useContext(DashboardContext);
  const [, { value }, { setValue, setTouched }] = useField<{
    relative?: RelativeDate;
    min?: string;
    max?: string;
  }>('date');

  let [start_date, end_date] = useMemo<[Date, Date]>(() => {
    const new_date = { ...value };
    if (new_date?.relative) {
      const { min, max } = relative_dates[new_date?.relative].convert(new Date());
      new_date.min = min.toISOString();
      new_date.max = max.toISOString();
    }
    const now = new Date();
    const end_date = setMilliseconds(
      new_date?.max ? new Date(new_date?.max) : add(startOfHour(now), { hours: 1 }),
      0,
    );
    const start_date = setMilliseconds(
      new_date?.min
        ? new Date(new_date?.min)
        : startOfDay(sub(now, { seconds: subscription!.retention_days * 24 * 60 * 60 })),
      0,
    );
    return [start_date, end_date];
  }, [value, subscription?.retention_days]);

  const diff = differenceInSeconds(end_date, start_date);
  const { chart_resolution } = useMemo(
    () => ({
      chart_resolution: getBestResolution(diff, 60, chart_resolutions).best,
      axis_resolution: getBestResolution(diff, 10, timebar_resolutions).best,
    }),
    [diff],
  );

  if (chart_resolution.granularity !== 'second') {
    end_date = add(startOfTime[chart_resolution.granularity](end_date), {
      [`${chart_resolution.granularity}s`]:
        Math.floor((getTime[chart_resolution.granularity](end_date) + 1) / chart_resolution.unit) *
        chart_resolution.unit,
    });
    start_date = sub(end_date, { seconds: diff });
  }

  const date_label = getTimerangeLabel(value, subscription!.retention_days);

  const form_initial_values = useMemo(
    () => ({
      date: {
        ...value,
        min: value?.min || start_date.toISOString(),
        max: value?.max || end_date.toISOString(),
      } as {
        relative?: RelativeDate;
        min?: string;
        max?: string;
      },
      start_time: value?.min
        ? format(new Date(value?.min), 'HH:mm:ss')
        : format(start_date, 'HH:mm:ss'),
      end_time: value?.max
        ? format(new Date(value?.max), 'HH:mm:ss')
        : format(end_date, 'HH:mm:ss'),
    }),
    [value],
  );

  return (
    <Div flex={{ align: 'center' }} style={{ width: 'fit-content' }}>
      <Dropdown outline label={date_label}>
        {(toggle) => (
          <Formik
            initialValues={form_initial_values}
            onSubmit={(v) => {
              const { start_date, end_date } = formatDateFilterValues({
                start_date: v.date.min,
                start_time: v.start_time,
                end_date: v.date.max,
                end_time: v.end_time,
              });
              setTouched(true);
              setValue({
                ...v.date,
                min: start_date,
                max: end_date,
              });
              toggle(false);
              onSubmit();
            }}>
            {(props) => (
              <Div>
                <StyledCardSection>
                  <DatePickerInput display_reset={false} name={'date'} time_format={'hh:mm:ss'} />
                </StyledCardSection>
                <StyledCardSection flex={{ justify: 'space-between' }} p={{ x: 4, y: 3 }}>
                  <Button
                    neutral
                    icon="close"
                    onClick={() => {
                      props.setValues({
                        date: {
                          relative: undefined,
                          min: undefined,
                          max: undefined,
                        },
                        start_time: '',
                        end_time: '',
                      });
                    }}>
                    Reset dates
                  </Button>
                  <Div flex={{ gap: 3 }}>
                    <Button neutral onClick={() => toggle(false)}>
                      Cancel
                    </Button>
                    <Button primary onClick={() => props.handleSubmit()}>
                      Apply
                    </Button>
                  </Div>
                </StyledCardSection>
              </Div>
            )}
          </Formik>
        )}
      </Dropdown>
      {delete_button && delete_button}
    </Div>
  );
};

export default DateFilter;
