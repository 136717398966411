import { showChat } from '../../../utils/liveChat';
import Container from '../base/Container';
import Link from '../base/Link';
import Text from '../base/Text';

const MetricError = () => {
  return (
    <Container>
      <Text p={{ t: 1 }} muted center>
        We weren’t able to find any data due to an error, please try again later or{' '}
        <Link as="button" primary onClick={showChat}>
          contact us.
        </Link>
      </Text>
    </Container>
  );
};

export default MetricError;
