import TextSwitch from '../base/TextSwitch';
import { ChartTimeOptions } from '../Chart/Chart';
import { Div, StyledUtilsProps } from '../helpers/StyledUtils';

interface Props extends StyledUtilsProps {
  id?: string;
  title: string;
  description?: string;
  children?: React.ReactNode;
  time_options?: ChartTimeOptions;
}

const MetricWrapper = ({ id, children, time_options, ...other_props }: Props) => {
  const { time_unit, time_units, onTimeUnitSelected } = time_options || {};

  return (
    <Div id={id} {...other_props}>
      <Div flex={{ align: 'center', gap: 2 }}>
        {onTimeUnitSelected && time_units && time_units.length > 1 && (
          <TextSwitch
            options={time_units}
            active={time_unit || time_units[0].key}
            onSelect={(key) => onTimeUnitSelected(key as string)}
          />
        )}
      </Div>
      {children}
    </Div>
  );
};

export default MetricWrapper;
