import { Form, FormikProvider, useFormik } from 'formik';
import { createContext, useContext, useEffect, useMemo, useState } from 'react';
import styled from 'styled-components';

import { EventListFiltersProps } from '../../../typings/EventList.interface';
import { RequestListFiltersProps } from '../../../typings/RequestList.interface';
import useLocalStorage from '../../hooks/useLocalStorage';
import { DashboardContext } from '../../scenes/DashboardScene/DashboardContext';
import Button from '../base/Button';
import Divider from '../base/Divider';
import LabelButton from '../base/LabelButton';
import Text from '../base/Text';
import { Div } from '../helpers/StyledUtils';
import { FilterComponent, FilterFormValues } from './FilterComponents';
import { IRecentFilters } from './RecentFilters';

const StyledForm = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${({ theme }) => theme.spacing(4)};
  margin-bottom: ${({ theme }) => theme.spacing(5)};
  width: 100%;
  margin: 0;
  > ${Text} {
    max-height: 42px;
    min-height: 42px;
    display: flex;
    justify-self: end;
  }
`;

const StyledFilterButtonsWrapper = styled(Div)`
  display: flex;
  flex-direction: row;
  gap: ${({ theme }) => theme.spacing(2)};
  overflow-x: auto;
  margin-bottom: ${({ theme }) => theme.spacing(4)};
  padding-left: ${({ theme }) => theme.spacing(8)};
  padding-right: ${({ theme }) => theme.spacing(8)};

  > * {
    flex-shrink: 0;
  }
`;

interface FiltersProps {
  route: string;
  filters: EventListFiltersProps | RequestListFiltersProps;
  unstructured_component?: FilterComponent;
  components: FilterComponent[];
  onFilterChanged: (filters: object) => void;
}

export const FiltersContext = createContext<{
  filters: EventListFiltersProps | RequestListFiltersProps;
  components: FilterComponent[];
  active_filters: FilterComponent[];
  filter_buttons: FilterComponent[];
  route: string;
  unstructured_component?: FilterComponent;
  values: FilterFormValues;
  formik: ReturnType<typeof useFormik<FilterFormValues>>;
  handleToggleActiveFilter: (key: string) => void;
  getDeleteButton: (filter: FilterComponent) => JSX.Element;
  active_filter_without_unstructured: FilterComponent[];
}>({
  filters: {},
  components: [],
  active_filters: [],
  filter_buttons: [],
  route: '',
  values: {} as FilterFormValues,
  formik: {} as ReturnType<typeof useFormik<FilterFormValues>>,
  handleToggleActiveFilter: () => {
    return;
  },
  getDeleteButton: () => {
    return <></>;
  },
  active_filter_without_unstructured: [],
});

const FiltersProvider: React.FC<FiltersProps & { children: React.ReactNode }> = ({
  filters,
  components,
  route,
  onFilterChanged,
  unstructured_component,
  children,
}) => {
  if (unstructured_component) {
    components = [...components, unstructured_component];
  }
  const { team } = useContext(DashboardContext);
  const [active_filters, setActiveFilters] = useState<FilterComponent[]>([]);
  const [recent_filters, setRecentFilters] = useLocalStorage<IRecentFilters>(
    `recent_filters:${route}:${team!.id}`,
    {},
  );
  const [save_error, setSaveError] = useState<string | undefined>(undefined);

  useEffect(() => {
    const new_active_filters: FilterComponent[] = [...active_filters];

    const active_filters_by_key = active_filters.reduce(
      (object, f) => ({ ...object, [f.filter_key]: f }),
      {},
    );

    const new_values = {};
    components.forEach((f) => {
      const formattedValue = f.formatForForm(filters[f.filter_key], filters);
      new_values[f.form_name] = formattedValue;
      const active = f.isActive(formattedValue);
      if (active && !active_filters_by_key[f.filter_key]) {
        new_active_filters.push(f);
        active_filters_by_key[f.filter_key] = f;
        return;
      } else if (!active) {
        delete active_filters_by_key[f.filter_key];
        const index = new_active_filters.findIndex((a) => a.filter_key === f.filter_key);
        if (index !== -1) {
          new_active_filters.splice(index, 1);
        }
      }
    });

    setActiveFilters(new_active_filters);
    setValues(new_values);
  }, [filters]);

  useEffect(() => {
    const new_active_filters = active_filters.filter(
      (f) => !!components.find((filter) => f.filter_key === filter.filter_key),
    );

    if (new_active_filters.length !== active_filters.length) {
      const new_values = { ...values };
      const removed_keys = Object.keys(new_values).filter(
        (key) => !new_active_filters.find((f) => f.filter_key === key),
      );
      removed_keys.forEach((key) => delete new_values[key]);
      setActiveFilters(new_active_filters);
      setValues(new_values);
    }
  }, [components]);

  const formik = useFormik<FilterFormValues>({
    initialValues: components.reduce((obj, f) => {
      obj[f.filter_key] = f.formatForForm(filters[f.filter_key], filters);
      return obj;
    }, {}) as FilterFormValues,
    validate: (values) => {
      return active_filters
        .filter((f) => !!f.validate)
        .reduce((errors, filter) => {
          const filter_errors = filter.validate?.(values[filter.filter_key]);
          if (filter_errors && Object.keys(filter_errors).length > 0) {
            errors[filter.filter_key] = filter_errors;
          }
          return errors;
        }, {});
    },
    onSubmit: (values) => {
      let should_update = false;

      components.forEach((f) => {
        const formated_filter = f.formatForForm(filters[f.filter_key], filters);
        if (!f.isActive(values[f.form_name]) && !f.isActive(formated_filter)) {
          return;
        }
        if (JSON.stringify(values[f.filter_key]) !== JSON.stringify(formated_filter)) {
          should_update = true;
        }
      });

      if (!should_update) {
        return;
      }

      const new_filters = components.reduce((query, { filter_key, form_name, formatForQuery }) => {
        return {
          ...query,
          [filter_key]: formatForQuery(values[form_name]),
        };
      }, {});

      onFilterChanged(new_filters);

      handleAddRecentFilter();

      if (save_error) {
        setSaveError(undefined);
      }
    },
  });

  const { handleSubmit, setValues, values, setFieldTouched } = formik;

  const filters_by_key: { [key: string]: FilterComponent } = useMemo(() => {
    return components.reduce((object, f) => ({ ...object, [f.filter_key]: f }), {});
  }, [components]);

  const handleToggleActiveFilter = (key: string) => {
    if (active_filters.some((a) => a.filter_key === key)) {
      const new_active_filters = [...active_filters];
      new_active_filters.splice(
        active_filters.findIndex((f) => f.filter_key === key),
        1,
      );
      setActiveFilters(new_active_filters);
      const new_filters_by_key = { ...filters_by_key };
      new_active_filters.forEach((f) => delete new_filters_by_key[f.filter_key]);
      handleSubmit();
    } else {
      setActiveFilters((prev) => [...prev, filters_by_key[key]]);
    }
  };

  const handleAddRecentFilter = () => {
    let should_add_filters = false;
    let new_recent_filters = {};
    components.forEach((f) => {
      if (f.isActive(values[f.form_name])) {
        if (f.filter_key === 'date' && values[f.form_name].relative) {
          delete values[f.form_name].min;
          delete values[f.form_name].max;
        }
        should_add_filters = true;
        new_recent_filters = {
          ...new_recent_filters,
          [f.filter_key]: f.formatForMemory
            ? f.formatForMemory(values[f.form_name])
            : values[f.form_name],
        };
      }
    });
    if (should_add_filters) {
      const current_recent_filters = Object.entries(recent_filters);
      const new_recent_filters_string = JSON.stringify(new_recent_filters);
      if (recent_filters[new_recent_filters_string] !== undefined) return;
      if (current_recent_filters.length > 19) {
        current_recent_filters.pop();
        setRecentFilters(
          current_recent_filters.reduce(
            (object, [key, v]) => {
              return { ...object, [key]: v };
            },
            { [new_recent_filters_string]: new_recent_filters },
          ),
        );
        return;
      }
      setRecentFilters((prev) => {
        return {
          [new_recent_filters_string]: new_recent_filters,
          ...prev,
        };
      });
    }
  };

  const getDeleteButton = (filter: FilterComponent) => {
    return (
      <Button
        invisible
        icon="close"
        onClick={() => {
          handleToggleActiveFilter(filter.filter_key);
          setValues((prev) => ({
            ...prev,
            [filter.form_name]: filter.formatForForm(null, {}),
          }));
          setFieldTouched(filter.form_name);
        }}
        margin={{ l: 2 }}
      />
    );
  };

  const filter_buttons = components.filter((f) => !f.hidden);

  const active_filter_without_unstructured = active_filters.filter((a) =>
    unstructured_component ? a.filter_key !== unstructured_component?.filter_key : true,
  );

  const contextValue = {
    filters,
    components,
    active_filters,
    filter_buttons,
    route,
    unstructured_component,
    values,
    formik,
    handleToggleActiveFilter,
    getDeleteButton,
    active_filter_without_unstructured,
  };

  return <FiltersContext.Provider value={contextValue}>{children}</FiltersContext.Provider>;
};

const FilterButtons: React.FC = () => {
  const { filter_buttons, active_filters, handleToggleActiveFilter } = useContext(FiltersContext);

  return (
    <>
      <StyledFilterButtonsWrapper>
        {filter_buttons.map((f) => (
          <LabelButton
            key={f.filter_key}
            label={f.label}
            label_icon={f.icon}
            small
            icon={active_filters.some((a) => a.filter_key === f.filter_key) ? 'close' : 'add'}
            neutral={!active_filters.some((a) => a.filter_key === f.filter_key)}
            onClick={() => {
              handleToggleActiveFilter(f.filter_key);
            }}
          />
        ))}
      </StyledFilterButtonsWrapper>
    </>
  );
};

const FilterForm: React.FC = () => {
  const {
    formik,
    active_filter_without_unstructured,
    unstructured_component,
    active_filters,
    getDeleteButton,
    filters,
  } = useContext(FiltersContext);

  return (
    <FormikProvider value={formik}>
      <Form>
        {active_filter_without_unstructured.length > 0 && (
          <Div p={{ x: 8, t: 4 }}>
            <StyledForm>
              {active_filters
                .filter((a) =>
                  unstructured_component
                    ? a.filter_key !== unstructured_component?.filter_key
                    : true,
                )
                .map((f, i) => (
                  <Div
                    key={`filters-${i}`}
                    flex={{ direction: 'column', justify: 'space-between', gap: 1 }}>
                    {!f.hide_label && (
                      <Text muted semi size="xs">
                        {f.label}
                      </Text>
                    )}
                    <Div flex={{ align: 'center' }}>
                      <f.Component
                        key={f.filter_key}
                        label={f.label}
                        name={f.form_name}
                        filters={filters}
                        {...(f.component_props as any)}
                        delete_button={getDeleteButton(f)}
                        onSubmit={formik.handleSubmit}
                      />
                    </Div>
                  </Div>
                ))}
            </StyledForm>
          </Div>
        )}
        {/*Without this button, clicking enter to submit will not work*/}
        <button hidden type="submit" />
      </Form>
    </FormikProvider>
  );
};

// Create the main Filters component and add subcomponents
const Filters = Object.assign(
  (props: FiltersProps) => {
    return (
      <FiltersProvider {...props}>
        <Div w={100}>
          <FilterButtons />
          <Divider />
          <FilterForm />
        </Div>
      </FiltersProvider>
    );
  },
  {
    Provider: FiltersProvider,
    Buttons: FilterButtons,
    Form: FilterForm,
  },
);

export default Filters;
