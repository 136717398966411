import { useContext } from 'react';
import useSWR from 'swr';

import { IgnoredEvent } from '../../../../../../../typings/IgnoredEvent.interface';
import APIMethodKeys from '../../../../client/APIMethodKeys';
import { cause_filter_breakdown } from '../../../../utils/rejection-causes';
import Badge from '../../../common/base/Badge';
import { StyledCard, StyledCardSection } from '../../../common/base/Card';
import LabelButton from '../../../common/base/LabelButton';
import Link from '../../../common/base/Link';
import Skeleton from '../../../common/base/Skeleton';
import Text from '../../../common/base/Text';
import { GlobalContext } from '../../../contexts/GlobalContext';

type Props = {
  ignored_event?: IgnoredEvent;
};

const IgnoredEventSidebarSection: React.FC<Props> = ({ ignored_event }) => {
  const { HookdeckAPI } = useContext(GlobalContext);

  const { data: webhook, error: webhook_error } = useSWR(
    ignored_event && APIMethodKeys.webhooks.get(ignored_event.webhook_id),
    () => HookdeckAPI.webhooks.get(ignored_event!.webhook_id),
  );

  const transformation_id =
    ignored_event &&
    typeof ignored_event?.meta === 'object' &&
    ignored_event?.meta !== null &&
    'transformation_id' in ignored_event.meta &&
    ignored_event.meta?.transformation_id;

  const { data: transformation } = useSWR(
    transformation_id && APIMethodKeys.transformations.get(transformation_id),
    () => HookdeckAPI.transformations.get(transformation_id as string),
  );

  if (
    !ignored_event ||
    (!webhook && !webhook_error) ||
    (transformation_id && transformation === undefined)
  ) {
    return (
      <>
        <StyledCard m={{ b: 4 }}>
          <StyledCardSection p={{ x: 4, y: 3 }}>
            <Skeleton h={{ px: 20 }} w={{ px: 40 }} loading m={{ b: 1 }} />
            <Skeleton h={{ px: 20 }} w={{ px: 58 }} loading />
          </StyledCardSection>
          <StyledCardSection p={{ x: 4, y: 3 }}>
            <Skeleton h={{ px: 20 }} w={{ px: 40 }} loading m={{ b: 1 }} />
            <Skeleton h={{ px: 20 }} w={{ px: 128 }} loading />
          </StyledCardSection>
          <StyledCardSection p={{ x: 4, y: 3 }}>
            <Skeleton h={{ px: 20 }} w={{ px: 40 }} loading m={{ b: 1 }} />
            <Skeleton h={{ px: 20 }} w={{ px: 128 }} loading />
          </StyledCardSection>
        </StyledCard>
      </>
    );
  }

  const { label, text } = cause_filter_breakdown[ignored_event!.cause](ignored_event!);

  return (
    <StyledCard m={{ b: 4 }}>
      <StyledCardSection p={{ x: 4, y: 3 }}>
        <Text size="s" semi m={{ b: 1 }}>
          Status
        </Text>
        <Badge icon="block" neutral>
          {label}
        </Badge>
      </StyledCardSection>
      <StyledCardSection p={{ x: 4, y: 3 }}>
        <Text size="s" semi m={{ b: 1 }}>
          Description
        </Text>
        <Text>{text}</Text>
      </StyledCardSection>
      <StyledCardSection p={{ x: 4, y: 3 }}>
        <Text size="s" semi m={{ b: 1 }}>
          Connection
        </Text>
        {webhook_error?.response?.status === 410 && <Text muted>Connection Deleted</Text>}
        {!webhook_error && webhook && (
          <LabelButton
            neutral
            mono
            to={webhook && `/connections/${webhook.id}`}
            label={webhook?.full_name || ''}
          />
        )}
      </StyledCardSection>
      {transformation && (
        <StyledCardSection p={{ x: 4, y: 3 }}>
          <Text size="s" semi m={{ b: 1 }}>
            Transformation
          </Text>
          {webhook_error?.response?.status === 410 && <Text muted>Connection Deleted</Text>}
          {!webhook_error && webhook && (
            <>
              <LabelButton
                neutral
                mono
                to={transformation && `/transformations/${transformation.id}`}
                label={transformation?.name || ''}
              />
              <Link to={`/transformations/${transformation.id}/executions?log_level[0]=fatal`}>
                View Executions
              </Link>
            </>
          )}
        </StyledCardSection>
      )}
    </StyledCard>
  );
};

export default IgnoredEventSidebarSection;
