import Button, { ClickableArea } from '../../../common/base/Button';
import Text from '../../../common/base/Text';
import { Div } from '../../../common/helpers/StyledUtils';
import EventSidebarSection from './EventSidebarSection';
import IgnoredEventSidebarSection from './IgnoredEventSidebarSection';
import {
  StyledViewScrollable,
  StyledViewSidebar,
  StyledViewSidebarFooter,
  StyledViewSidebarNav,
  StyledViewSidebarSection,
} from '../StyledView';
import Icon from '../../../common/base/Icon';
import { useCopyToClipboard } from '../../../../utils/copy';
import APIMethodKeys from '../../../../client/APIMethodKeys';
import useSWR from 'swr';
import { GlobalContext } from '../../../contexts/GlobalContext';
import { useContext } from 'react';

const EntrySidebar: React.FC<{
  request_id: string;
  entry_id: string;
  onClose: () => void;
  retry: (request_id: string, webhook_ids: string[]) => void;
}> = ({ request_id, entry_id, onClose, retry }) => {
  const { HookdeckAPI } = useContext(GlobalContext);
  const copyToClipboard = useCopyToClipboard();
  const is_event = entry_id.startsWith('evt_');

  const { data: ignored_events } = useSWR(
    !is_event && APIMethodKeys.requests.listIgnoredEvents(request_id, { id: entry_id, limit: 1 }),
    () => HookdeckAPI.requests.listIgnoredEvents(request_id, { id: entry_id, limit: 1 }),
  );

  const ignored_event = ignored_events?.models[0];

  return (
    <StyledViewSidebar>
      <StyledViewSidebarNav background_color="surface">
        <Text semi text_wrap={false}>
          {is_event ? 'Event' : 'Ignored Event'}
        </Text>
        <Div flex={{ align: 'center', gap: 2 }}>
          {!is_event && (
            <Button.Permission
              role="member"
              invisible
              icon="retry"
              onClick={() => ignored_event && retry(request_id, [ignored_event.webhook_id])}>
              Retry
            </Button.Permission>
          )}
          {is_event && <Button invisible icon="link" to={`/events/${entry_id}`} />}
          <Button invisible icon="close" onClick={onClose} />
        </Div>
      </StyledViewSidebarNav>
      <StyledViewScrollable>
        <StyledViewSidebarSection>
          {is_event ? (
            <EventSidebarSection entry_id={entry_id} />
          ) : (
            <IgnoredEventSidebarSection ignored_event={ignored_event} />
          )}
        </StyledViewSidebarSection>
      </StyledViewScrollable>
      <StyledViewSidebarFooter>
        <ClickableArea
          rounded
          block={false}
          p={{ x: 2, y: 1 }}
          flex={{ align: 'center' }}
          onClick={() => copyToClipboard(entry_id)}>
          <Text muted mono>
            {entry_id}
          </Text>
          <Icon right icon="copy" muted />
        </ClickableArea>
      </StyledViewSidebarFooter>
    </StyledViewSidebar>
  );
};

export default EntrySidebar;
