import { Formik, useField, useFormikContext } from 'formik';
import { useCallback, useContext, useEffect, useState } from 'react';
import { Prompt } from 'react-router-dom';
import styled, { css } from 'styled-components';
import useSWR from 'swr';

import { Grid, GridUnit } from '@hookdeck/theme';

import { ConsoleLine } from '../../../../../../../../../typings/TransformationExecution.interface';
import APIMethodKeys from '../../../../../../client/APIMethodKeys';
import field_formats from '../../../../../../utils/field-formatters';
import Button from '../../../../../common/base/Button';
import Divider from '../../../../../common/base/Divider';
import Icon from '../../../../../common/base/Icon';
import Keybinds from '../../../../../common/base/Keybinds';
import Text from '../../../../../common/base/Text';
import { useDialog } from '../../../../../common/Dialog';
import Dropdown from '../../../../../common/Dropdown';
import EditorInput from '../../../../../common/Form/Fields/EditorInput';
import TextInput from '../../../../../common/Form/Fields/TextInput';
import { Div } from '../../../../../common/helpers/StyledUtils';
import { ScreenModal } from '../../../../../common/Modal';
import { useToasts } from '../../../../../common/Toast';
import { GlobalContext } from '../../../../../contexts/GlobalContext';
import useSearchQuery from '../../../../../hooks/useSearchQuery';
import TransformationExecutionsView from '../../../Transformation/TransformationExecutionsView';
import Console from './Console';
import IOPanel from './IOPanel';
import Variables from './Variables';

const StyledGridUnit = styled(GridUnit)(
  ({ theme }) => css`
    position: relative;
    height: calc(100vh - 56px);
    overflow: hidden;
    border-left: ${theme.border};
  `,
);

const StyledInputWrapper = styled(Div)<{ height_offset?: number }>(
  ({ height_offset = 118 }) => css`
    position: relative;
    height: calc(100vh - ${height_offset}px);
    overflow: hidden;
  `,
);

const StyleConsole = styled(Console)`
  position: absolute;
  bottom: 0;
  left: 0;
`;

let last_checked_name = {
  name: null,
  available: true,
};

const TransformRuleFormWrapper: React.FC<{
  onSave: (new_value) => Promise<any>;
  onClose: () => void;
  transformation_id?: string;
  transformation?: {
    code: string;
    env: [string, string][];
    name: string;
  };
  webhook_id?: string;
  source_id?: string;
}> = ({ onSave, onClose, transformation, webhook_id, source_id, transformation_id }) => {
  const [isNaming, setIsNaming] = useState(false);

  return (
    <ScreenModal>
      <Formik
        key={transformation_id}
        initialValues={{
          transformation: transformation || {},
          input: JSON.stringify(
            {
              headers: {
                'content-type': 'application/json',
              },
              body: {
                hello: 'world',
              },
              query: '?',
              parsed_query: {},
            },
            null,
            2,
          ),
        }}
        validate={({ input }) => {
          if (!input || typeof input !== 'string') {
            return {
              input: 'Input must be a valid JSON object that represent a input request',
            };
          }
          let parsed_input;
          try {
            parsed_input = JSON.parse(input);
          } catch {
            return {
              input: 'Input is not valid JSON',
            };
          }

          const missing_inputs = ['body', 'headers'].filter(
            (key) => parsed_input[key] === undefined,
          );
          if (missing_inputs.length > 0) {
            return {
              input: `Missing input ${missing_inputs.join(', ')} to run code.`,
            };
          }
          return {};
        }}
        onSubmit={(v, { resetForm }) => {
          return onSave(v).then(() => {
            resetForm({ values: v });
            onClose();
          });
        }}>
        {({ values, handleSubmit, isSubmitting }) => (
          <form onSubmit={handleSubmit}>
            <Prompt
              when={
                !isSubmitting &&
                !isNaming &&
                JSON.stringify(values.transformation) !== JSON.stringify(transformation)
              }
              message={(location) => {
                return location.search.includes('transform_tab')
                  ? true
                  : 'Are you sure you want to quit without saving your work?';
              }}
            />
            <TransformRuleForm
              onClose={onClose}
              handleSubmit={handleSubmit}
              webhook_id={webhook_id}
              source_id={source_id}
              transformation_id={transformation_id}
              setIsNaming={setIsNaming}
            />
          </form>
        )}
      </Formik>
    </ScreenModal>
  );
};

const TransformRuleForm: React.FC<{
  transformation_id?: string;
  webhook_id?: string;
  source_id?: string;
  onClose: () => void;
  handleSubmit: () => void;
  setIsNaming: (isNaming: boolean) => void;
}> = ({ webhook_id, source_id, onClose, handleSubmit, setIsNaming, transformation_id }) => {
  const [console_lines, setConsoleLines] = useState<ConsoleLine[]>([]);
  const [is_running, setIsRunning] = useState<boolean>(false);
  const { query, updateSearchQuery } = useSearchQuery<{
    input_execution_id?: string;
    executions?: boolean;
  }>();
  const input_execution_id = query.input_execution_id;
  const { isValid, isSubmitting, validateForm } = useFormikContext();
  const [{ value: transformation }, , { setValue: setTransformation }] = useField('transformation');
  const [{ value: input }, , { setValue: setInput }] = useField('input');
  const [, , { setValue: setOutput }] = useField('output');
  const { HookdeckAPI } = useContext(GlobalContext);
  const { addToast } = useToasts();
  const showDialog = useDialog();
  const [console_collapsed, setConsoleCollapsed] = useState(false);

  const { data: execution } = useSWR(
    query.input_execution_id &&
      transformation_id &&
      APIMethodKeys.transformations.getExecution(transformation_id, input_execution_id),
    () => HookdeckAPI.transformations.getExecution(transformation_id!, input_execution_id!),
  );

  useEffect(() => {
    if (execution && execution.original_event_data) {
      setInput(JSON.stringify(execution.original_event_data, null, 2));
      setOutput(null);
      setConsoleLines([]);
    }
  }, [execution]);

  const showNameDialog = (submit = false) => {
    setIsNaming(true);
    showDialog(
      (values: { name: string }) => {
        setTransformation({ ...transformation, name: values.name });
        if (submit) {
          setTimeout(handleSubmit, 0);
        }
      },
      () => setIsNaming(false),
      {
        title: 'Name your transformation',
        submit_icon: 'success',
        submit_label: 'Confirm',
        cancel_label: 'Cancel',
        form_props: {
          initial_values: { name: transformation.name || '' },
          validate: async ({ name }: { name: string }) => {
            if (!name || name.length < 0) return { name: 'Required' };
            else if (name !== transformation.name) {
              const used = await HookdeckAPI.transformations.nameIsUsed(name);
              if (used) {
                return { name: 'Name is already used.' };
              }
            }
            return {};
          },
          Fields: () => (
            <TextInput
              format={field_formats.slugify}
              name="name"
              placeholder="Name"
              m={0}
              help="Provide a unqiue name that represents this transformation."
              mono
              required
              validate={async (name) => {
                if (!name) {
                  return 'Required';
                } else {
                  const name_changed = name !== last_checked_name.name;
                  if (name_changed) {
                    const transformations = await HookdeckAPI.transformations.list({
                      name: name,
                      limit: 1,
                    });
                    if (
                      transformations.count > 0 &&
                      (!transformation_id || transformations.models[0]?.id !== transformation_id)
                    ) {
                      last_checked_name = {
                        name: transformation.name,
                        available: false,
                      };
                      return 'Name is already used.';
                    }
                    last_checked_name = {
                      name: transformation.name,
                      available: true,
                    };
                  } else if (!last_checked_name.available) {
                    return 'Name is already used.';
                  }
                }
              }}
            />
          ),
        },
      },
    );
  };

  const onRun = useCallback(async () => {
    if (is_running) {
      return;
    }
    if (!transformation?.code) {
      return addToast('error', 'Code is empty and cannot be run.');
    }

    const errors = await validateForm();
    if ((errors as any).input) {
      return addToast('error', 'Input is invalid and transformation cannot be run.');
    }
    setIsRunning(true);
    try {
      const response = await HookdeckAPI.transformations.run({
        webhook_id: webhook_id || execution?.webhook_id || undefined,
        request: JSON.parse(input),
        code: transformation.code,
        env: transformation.env.reduce((obj, [key, value]) => {
          obj[key] = value;
          return obj;
        }, {}),
      });
      const output = response.request && JSON.stringify(response.request, null, 2);

      setOutput(output);
      setConsoleLines(response.console || []);
      setIsRunning(false);

      if (!output || ['fatal', 'error'].includes(response.log_level)) {
        return addToast('error', 'Code executed but threw an error.');
      }
      if (response.log_level === 'warn') {
        return addToast('warning', 'Code executed but logged a warning.');
      }
      return addToast('success', 'Code successfully executed.');
    } catch (e) {
      setIsRunning(false);
      return addToast('error', `Code failed to execute. Error: ${e.message}`);
    }
  }, [input, is_running, transformation]);

  const event = new Event('run');
  useEffect(() => {
    window.addEventListener('run', onRun);
    return () => window.removeEventListener('run', onRun);
  }, [onRun]);

  return transformation_id && query.executions ? (
    <TransformationExecutionsView
      in_modal
      onEdit={(execution) => {
        updateSearchQuery({ input_execution_id: execution?.id, executions: undefined });
      }}
      onClose={() => updateSearchQuery({ executions: undefined })}
      transformation_id={transformation_id}
    />
  ) : (
    <>
      <Div p={3} flex={{ align: 'center', justify: 'space-between' }}>
        <Div flex={{ align: 'center', gap: 4 }}>
          <Button onClick={onClose} neutral icon="close" />
          <Div flex={{ align: 'center' }}>
            <Icon icon="transformation" left />
            <Text bold>{transformation!.name || 'New Transformation'}</Text>
          </Div>
          <Button invisible icon="edit" onClick={() => showNameDialog(false)} />
        </Div>
        <Div flex={{ align: 'center', gap: 2 }}>
          {transformation_id && (
            <Button neutral onClick={() => updateSearchQuery({ executions: true })} icon="rule">
              View Executions
            </Button>
          )}
          <Button
            primary
            disabled={!isValid || isSubmitting}
            onClick={() => {
              if (!transformation.name) {
                showNameDialog(true);
              } else {
                handleSubmit();
              }
            }}
            icon={isSubmitting ? 'loading' : 'success'}>
            Confirm
          </Button>
        </Div>
      </Div>
      <Divider />
      <Grid>
        <GridUnit size={1 / 3}>
          <Div style={{ height: 'calc(100vh - 56px)' }} flex={{ direction: 'column', grow: true }}>
            <IOPanel source_id={source_id || null} transformation_id={transformation_id || null} />
          </Div>
        </GridUnit>
        <StyledGridUnit size={2 / 3}>
          <Div p={{ x: 3, y: 2 }} flex={{ align: 'center', justify: 'space-between' }}>
            <Button
              disabled={is_running}
              primary
              invisible
              small
              onClick={onRun}
              icon={is_running ? 'loading' : 'play_circle'}>
              Run
              <Div m={{ l: 2 }}>
                <Keybinds keys={['CMD', '⏎']} />
              </Div>
            </Button>
            <Dropdown
              label="Variables"
              icon="settings"
              badge={
                transformation.env.length > 0 ? { label: transformation.env.length } : undefined
              }
              small
              minimal>
              <Variables name="transformation.env" />
            </Dropdown>
          </Div>
          <Divider />
          <StyledInputWrapper height_offset={console_collapsed ? 154 : 439}>
            <EditorInput
              flex={{ direction: 'column', grow: true }}
              language="javascript"
              display="full"
              name="transformation.code"
              jsdocs="transformation"
              cmdEnter={() => window.dispatchEvent(event)}
            />
          </StyledInputWrapper>
          <StyleConsole
            border_top={true}
            collapseable={true}
            lines={console_lines}
            onClear={() => setConsoleLines([])}
            onToggleCollapse={(collapsed) => setConsoleCollapsed(collapsed)}
          />
        </StyledGridUnit>
      </Grid>
    </>
  );
};

export default TransformRuleFormWrapper;
