import { useContext } from 'react';
import { Route, Switch } from 'react-router-dom';
import styled from 'styled-components';

import Text from '../../../common/base/Text';
import { Div } from '../../../common/helpers/StyledUtils';
import { TeamPermission } from '../../../contexts/TeamPermissionContext';
import { DashboardContext } from '../DashboardContext';
import { StyledViewContent, StyledViewWrapper } from '../StyledView';
import AccountProfile from './Account/AccountProfile';
import TeamApiKeys from './Team/TeamApiKeys';
import OrganizationBilling from './Organization/OrganizationBilling';
import TeamMembers from './Organization/OrganizationMembers';
import OrganizationPlans from './Organization/OrganizationPlans';
import TeamQuotas from './Team/TeamQuotas';
import TeamSettings from './Team/TeamSettings';
import TeamAccessControl from './Team/TeamAccessControl';
import TeamIntegrations from './Team/TeamIntegrations';
import OrganizationSettings from './Organization/OrganizationSettings';
import { Redirect } from 'react-router';
import ViewRoute from '../../../common/ViewRoute';
import SettingsNav, { StyledSettingsNavHeader } from '../Nav/SettingsNav';
import { useIsDegradedBanner } from '../../../hooks/useIsDegradedBanner';

const StyledSettingsView = styled.div`
  display: grid;
  grid-template-columns: 240px 1fr;
  position: relative;

  > div:first-child {
    height: fit-content;
    position: sticky;
  }
`;

const Settings: React.FC = () => {
  const { organization } = useContext(DashboardContext);

  const sections = {
    project: [
      {
        key: 'settings',
        path: '/settings/project/general',
        label: 'Project Settings',
        icon: 'settings',
        Component: TeamSettings,
      },
      {
        key: 'secrets',
        path: '/settings/project/secrets',
        label: 'Project Secrets',
        icon: 'key',
        Component: TeamApiKeys,
      },
      {
        key: 'access',
        path: '/settings/project/access',
        label: 'Project Access Control',
        icon: 'group',
        Component: TeamAccessControl,
      },
      {
        key: 'integrations',
        path: '/settings/project/integrations',
        label: 'Project Integrations',
        icon: 'tune',
        Component: TeamIntegrations,
      },
      {
        key: 'quotas',
        path: '/settings/project/quotas',
        label: 'Project Quotas',
        icon: 'rate_limit',
        Component: TeamQuotas,
      },
    ],
    organization: [
      {
        key: 'general',
        path: '/settings/organization/general',
        label: 'Organization',
        icon: 'settings',
        Component: OrganizationSettings,
      },
      {
        key: 'members',
        path: '/settings/organization/members',
        label: 'Organization Members',
        icon: 'team',
        Component: TeamMembers,
      },
      {
        key: 'billing',
        path: '/settings/organization/billing',
        label: 'Organization Billing',
        icon: 'billing',
        Component: OrganizationBilling,
      },
      ...(!organization!.workos_directory_id && !organization!.workos_connection_id
        ? [
            {
              key: 'plans',
              path: '/settings/organization/plans',
              label: 'Hookdeck Plans',
              icon: 'billing',
              Component: OrganizationPlans,
            },
          ]
        : []),
    ],
  };
  const sections_list = [...sections.project, ...sections.organization];
  const current_section = sections_list.find((section) => location.pathname.includes(section.path));
  const is_degraded_banner_active = useIsDegradedBanner();
  return (
    <StyledViewWrapper>
      <StyledViewContent light>
        <StyledSettingsNavHeader
          flex={{ align: 'center', justify: 'space-between' }}
          p={{ x: 8, y: 6 }}
          is_degraded_banner_active={is_degraded_banner_active}>
          <Text semi size="l">
            {current_section?.label || 'Profile'}
          </Text>
        </StyledSettingsNavHeader>
        <StyledSettingsView>
          <SettingsNav />
          <Div flex={{ direction: 'column' }} p={{ y: 10 }}>
            <Div>
              <Switch>
                {!organization!.workos_directory_id && !organization!.workos_connection_id && (
                  <Route path="/settings/organization/plans" component={OrganizationPlans} />
                )}
                <ViewRoute
                  title="Profile"
                  path="/settings/account/profile"
                  render={() => (
                    <Div>
                      <AccountProfile />
                    </Div>
                  )}
                />
                <Route
                  path="/settings/project"
                  render={() => (
                    <TeamPermission role="member">
                      <Div>
                        <Switch>
                          {sections.project.map((section) => (
                            <ViewRoute title={section.label} path={section.path} key={section.key}>
                              <section.Component />
                            </ViewRoute>
                          ))}
                          <Redirect to="/settings/project/general" />
                        </Switch>
                      </Div>
                    </TeamPermission>
                  )}
                />
                <Route
                  path="/settings/organization"
                  render={() => (
                    <TeamPermission role="member">
                      <Switch>
                        {sections.organization.map((section) => (
                          <ViewRoute title={section.label} path={section.path} key={section.key}>
                            <section.Component />
                          </ViewRoute>
                        ))}
                        <Redirect to="/settings/organization/general" />
                      </Switch>
                    </TeamPermission>
                  )}
                />
                <Redirect to="/settings/project/general" />
              </Switch>
            </Div>
          </Div>
        </StyledSettingsView>
      </StyledViewContent>
    </StyledViewWrapper>
  );
};

export default Settings;
