import { useField, useFormikContext } from 'formik';

import { APISource } from '../../../../../../../../typings/Source.interface';
import { fieldName } from '../../../../../utils';
import { ClickableArea } from '../../../../common/base/Button';
import Icon from '../../../../common/base/Icon';
import Text from '../../../../common/base/Text';
import resource_details_form_props from './resource_details';
import { Div } from '../../../../common/helpers/StyledUtils';
import { cleanseFormErrorObject } from '../../../../../utils/form';
import { SelectSourceType } from '../../GuidedConnection/Steps/SelectSourceType';
import styled from 'styled-components';
import Alert from '../../../../common/base/Alert';
import { useContext, useEffect, useState } from 'react';
import {
  IntegrationConfig,
  isSourceTypeFeatureUnmanaged,
  SourceAuthProvider,
  SourceType,
  SourceTypes,
} from '../../../../../../../../typings/Integration.interface';
import { DashboardContext } from '../../DashboardContext';
import field_formats from '../../../../../utils/field-formatters';
import {
  CustomResponseValue,
  SourceFeature,
  SourceFeatureType,
  useFeatures,
} from './form_features';
import Link from '../../../../common/base/Link';

type ErrorOfFormValue<T> = {
  [P in keyof T]?: T[P] extends object ? ErrorOfFormValue<T[P]> : string;
};

type AllowedHTTPMethodConfig = {
  GET: boolean;
  POST: boolean;
  PUT: boolean;
  PATCH: boolean;
  DELETE: boolean;
};

export interface SourceConfigurationFormValues {
  show_advanced: boolean;
  enable_auth: boolean;
  enable_custom_response: boolean;
  type?: SourceType;
  config?: IntegrationConfig & {
    allowed_http_methods?: AllowedHTTPMethodConfig;
    custom_response?: CustomResponseValue;
    auth_type?: SourceAuthProvider;
    auth?: IntegrationConfig;
  };
}

const default_allowed_http_methods = {
  GET: false,
  POST: true,
  PUT: true,
  PATCH: true,
  DELETE: true,
};

const StyledHR = styled.hr`
  height: 1px;
  width: 100%;
  background-color: ${({ theme }) => theme.colors.outline.neutral};
  border: none;
`;

function parseAllowedHTTPMethods(allowed_http_methods?: AllowedHTTPMethodConfig) {
  return Object.entries(allowed_http_methods || {})
    .filter(([, value]) => !!value)
    .map(([key]) => key);
}

const source_configuration_form_props = {
  postprocessValues: (values: SourceConfigurationFormValues) => {
    return {
      type: values.type,
      config: {
        allowed_http_methods: parseAllowedHTTPMethods(values.config?.allowed_http_methods),
        custom_response: values.enable_custom_response ? values.config?.custom_response : null,
        auth_type:
          values.enable_auth && values.type !== values.config?.auth_type
            ? values.config?.auth_type
            : null,
        auth: values.enable_auth ? values.config?.auth || {} : null,
      },
    };
  },
  getInitialValues: (source?: APISource): SourceConfigurationFormValues => {
    const default_custom_response = {
      content_type: 'json',
      body: '',
    } as const;

    return source
      ? {
          type: source.type,
          show_advanced: true,
          enable_custom_response: !!source.config.custom_response,
          enable_auth: source.authenticated,
          config: {
            ...source.config,
            custom_response:
              'custom_response' in source.config
                ? source.config.custom_response || default_custom_response
                : default_custom_response,
            allowed_http_methods:
              'allowed_http_methods' in source.config
                ? {
                    GET: source.config.allowed_http_methods?.includes('GET') ?? false,
                    POST: source.config.allowed_http_methods?.includes('POST') ?? false,
                    PUT: source.config.allowed_http_methods?.includes('PUT') ?? false,
                    PATCH: source.config.allowed_http_methods?.includes('PATCH') ?? false,
                    DELETE: source.config.allowed_http_methods?.includes('DELETE') ?? false,
                  }
                : default_allowed_http_methods,
            auth_type: source.config.auth_type,
            auth: source.config?.auth,
          },
        }
      : {
          enable_custom_response: false,
          enable_auth: false,
          show_advanced: false,
          config: {
            custom_response: default_custom_response,
            allowed_http_methods: default_allowed_http_methods,
          },
        };
  },
  validate: async (values: SourceConfigurationFormValues, source_types: SourceTypes) => {
    const errors: ErrorOfFormValue<SourceConfigurationFormValues> = {
      config: {} as any,
    };

    if (!values.type) {
      errors.type = 'Required';
    }

    if (values.enable_auth) {
      const source_type_fields = [
        ...(source_types[values.type!.toUpperCase()]?.VERIFICATION?.fields || []),
        ...(source_types[values.type!.toUpperCase()]?.VERIFICATION?.fields || []),
      ];
      source_type_fields
        .filter((field) => field.features?.includes('VERIFICATION'))
        .forEach((field) => {
          if (field.required && !values.config?.[field.name]) {
            (errors.config as any)[field.name] = 'Required';
          }
        });
    }

    if (values.config?.allowed_http_methods) {
      if (Object.values(values.config.allowed_http_methods).every((value) => !value)) {
        (errors.config as any).allowed_http_methods = 'At least one HTTP method must be selected';
      }
    }

    if (values.enable_custom_response) {
      if (values.config?.custom_response?.content_type === 'json') {
        try {
          JSON.parse(values.config?.custom_response?.body);
        } catch (e) {
          (errors.config as any).custom_response = {
            body: 'Invalid JSON',
          };
        }
      }
    }

    return cleanseFormErrorObject(errors);
  },
  Fields: ({
    prefix,
    show_all,
    disable_name,
  }: {
    prefix: string;
    show_all?: boolean;
    disable_name?: boolean;
  }) => {
    const formikContext = useFormikContext();
    const { source_types } = useContext(DashboardContext);
    const [using_default_name, setUsingDefaultName] = useState(false);
    const [, , { setValue: setName }] = useField<string>(fieldName('name', prefix));
    const [{ value: show_advanced }, , { setValue: setShowAdvanced }] = useField<boolean>(
      fieldName('show_advanced', prefix),
    );
    const [{ value: type }] = useField<SourceType>(fieldName('type', prefix));
    const [{ value: enable_auth }, , { setValue: setEnableVerification }] = useField<boolean>(
      fieldName('enable_auth', prefix),
    );
    const [, , { setValue: setAuthType }] = useField<SourceType>(
      fieldName('config.auth_type', prefix),
    );
    const [{ value: search_value }] = useField(`search.${fieldName('type', prefix)}`);

    const selected_type = source_types?.[type];
    const configuration_alert = selected_type?.configuration_alert;
    const configuration_link = selected_type?.configuration_link;
    const configuration_link_text = selected_type?.configuration_link_text;
    const { required_features, advanced_features } = useFeatures(selected_type?.features || {});

    const onSelectSourceType = (source_type: SourceType) => {
      const selected_type = source_types?.[source_type];
      if (selected_type?.category === 'platform') {
        setName(field_formats.slugify(selected_type?.label?.toLocaleLowerCase() || ''));
        setUsingDefaultName(true);
      } else {
        if (using_default_name || !!search_value?.length) {
          const name = field_formats.slugify(search_value?.toLocaleLowerCase() || '');
          setName(name);
          setUsingDefaultName(false);
        }
      }
    };

    useEffect(() => {
      const verification = selected_type?.features.VERIFICATION;
      if (verification && isSourceTypeFeatureUnmanaged(verification) && verification.required) {
        setEnableVerification(true);
      }
    }, [selected_type, setEnableVerification]);

    useEffect(() => {
      if (!enable_auth) {
        formikContext.setFieldValue('config.auth', null);
      }
    }, [enable_auth, setAuthType, type]);

    return (
      <>
        <SelectSourceType prefix={prefix} onSelect={onSelectSourceType} />
        {(show_all || !!type) && (
          <>
            {configuration_alert && (
              <Alert inline info m={{ y: 4 }}>
                {configuration_alert}
                {configuration_link && configuration_link_text && (
                  <>
                    {' '}
                    <Link icon={'link'} href={configuration_link}>
                      {configuration_link_text}
                    </Link>
                  </>
                )}
              </Alert>
            )}
            {disable_name !== true && (
              <Div m={{ y: 4 }}>
                <resource_details_form_props.Fields
                  prefix={prefix}
                  key={selected_type?.label}
                  placeholder="shopify-prod"
                  name_prefix="Source"
                  help="Used as a unique identifier of your source in Hookdeck."
                  name_required
                  default_value={
                    selected_type?.category === 'platform'
                      ? selected_type?.label?.toLocaleLowerCase()
                      : undefined
                  }
                />
              </Div>
            )}
            <Div m={{ y: 4 }}>
              {required_features.map((feature) => (
                <SourceFeature
                  feature={feature as SourceFeatureType}
                  prefix={prefix}
                  key={feature.type}
                />
              ))}
            </Div>
            {advanced_features.length > 0 ? (
              <Div m={{ y: 4, t: 6 }}>
                <Div flex={{ justify: 'flex-start', align: 'center', gap: 1 }}>
                  <ClickableArea
                    p={{ y: 0, x: 1 }}
                    m={{ l: -1 }}
                    rounded
                    style={{ width: 'auto' }}
                    flex={{ justify: 'flex-start', align: 'center', gap: 1 }}
                    onClick={() => setShowAdvanced(!show_advanced)}>
                    <Text size="s" semi muted={!show_advanced}>
                      Advanced Configuration
                    </Text>
                    <Icon muted icon={show_advanced ? 'expand_less' : 'expand_more'} />
                  </ClickableArea>
                  {show_advanced && <StyledHR />}
                </Div>
                {show_advanced &&
                  advanced_features.map((feature) => (
                    <SourceFeature
                      feature={feature as SourceFeatureType}
                      prefix={prefix}
                      key={feature.type}
                    />
                  ))}
              </Div>
            ) : null}
          </>
        )}
      </>
    );
  },
};

export default source_configuration_form_props;
