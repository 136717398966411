import { useContext } from 'react';
import { NavLink } from 'react-router-dom';

import Icon from '../../../common/base/Icon';
import Text from '../../../common/base/Text';
import { Div } from '../../../common/helpers/StyledUtils';
import { DashboardContext } from '../DashboardContext';
import { NavSections, StyledNavLinks } from './';
import styled from 'styled-components';
import { useIsDegradedBanner } from '../../../hooks/useIsDegradedBanner';

const StyledSettingsNav = styled(Div)<{ is_degraded_banner_active: boolean }>(({
  theme,
  is_degraded_banner_active,
}) => {
  const base_gap = 73;
  const degraded_banner_gap = 32;
  const top = is_degraded_banner_active ? base_gap + degraded_banner_gap : base_gap;
  return `
  border-right: ${theme.border};
  min-height: calc(100vh - ${top}px);
  max-height: calc(100vh - ${top}px);
  position: sticky;
  top: ${top}px;
  z-index: 10;
`;
});

export const StyledSettingsNavHeader = styled(Div)<{ is_degraded_banner_active: boolean }>(
  ({ theme, is_degraded_banner_active }) => `
  border-bottom: ${theme.border};
  position: sticky;
  top: ${is_degraded_banner_active ? '32px' : '0px'};
  background: ${theme.colors.surface.base.background_elevated};
  z-index: 10;
`,
);

const SettingsNav = () => {
  const { organization } = useContext(DashboardContext);
  const is_degraded_banner_active = useIsDegradedBanner();

  return (
    <StyledSettingsNav
      h={100}
      flex={{ direction: 'column' }}
      is_degraded_banner_active={is_degraded_banner_active}>
      <Div p={{ x: 3, y: 2 }}>
        <NavSections title="Project" collapsable={false}>
          <StyledNavLinks>
            <NavLink to="/settings/project/general">
              <Icon left icon="settings" pointer />
              <Text semi size="m">
                General
              </Text>
            </NavLink>
            <NavLink to="/settings/project/secrets">
              <Icon left icon="key" pointer />
              <Text semi size="m">
                Secrets
              </Text>
            </NavLink>
            <NavLink to="/settings/project/access">
              <Icon left icon="group" pointer />
              <Text semi size="m">
                Access Control
              </Text>
            </NavLink>
            <NavLink to="/settings/project/integrations">
              <Icon left icon="connect" pointer />
              <Text semi size="m">
                Integrations
              </Text>
            </NavLink>
            <NavLink to="/settings/project/quotas">
              <Icon left icon="rate_limit" pointer />
              <Text semi size="m">
                Quotas
              </Text>
            </NavLink>
          </StyledNavLinks>
        </NavSections>
        <NavSections title="Organization" collapsable={false}>
          <StyledNavLinks>
            <NavLink to="/settings/organization/general">
              <Icon left icon="settings" pointer />
              <Text semi size="m">
                General
              </Text>
            </NavLink>
            <NavLink to="/settings/organization/members">
              <Icon left icon="group" pointer />
              <Text semi size="m">
                Members
              </Text>
            </NavLink>
            <NavLink to="/settings/organization/billing">
              <Icon left icon="wallet" pointer />
              <Text semi size="m">
                Billing
              </Text>
            </NavLink>
            {!organization?.workos_directory_id && !organization?.workos_connection_id && (
              <NavLink to="/settings/organization/plans">
                <Icon left icon="checklist" pointer />
                <Text semi size="m">
                  Plans
                </Text>
              </NavLink>
            )}
          </StyledNavLinks>
        </NavSections>
        <NavSections title="Account" collapsable={false}>
          <StyledNavLinks>
            <NavLink to="/settings/account/profile">
              <Icon left icon="account" pointer />
              <Text semi size="m">
                Profile
              </Text>
            </NavLink>
          </StyledNavLinks>
        </NavSections>
      </Div>
    </StyledSettingsNav>
  );
};

export default SettingsNav;
